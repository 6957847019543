import { getSkeletonInitialState, getUiStateByPage, isPageFeaturingSkeleton, resetSkeletonStoreValues } from '@/helpers/skeletonHelper'

export default function ({ store, route }) {
  // const SKELETON_TIMEOUT = process.env.SKELETON_TIMEOUT

  const {
    initialSkeletonValue,
    initialSpacerValue,
    initialTransparentHeaderValue
  } = getSkeletonInitialState(store)

  if (process.server || !isPageFeaturingSkeleton(route.name)) {
    resetSkeletonStoreValues(store)
    return
  }

  // Clear the reset skeleton timeout as long as we go through the middleware
  clearTimeout(store.state.ui.timeoutID)

  displaySkeletonForMatchingRoute(store, route.name, {
    initialSkeletonValue,
    initialSpacerValue,
    initialTransparentHeaderValue
  })
}

function displaySkeletonForMatchingRoute (store, routeName, {
  initialSkeletonValue,
  initialSpacerValue,
  initialTransparentHeaderValue
}) {
// Get ui state infos for the page we're navigating to
  const { skeleton, transparentHeader, spacer } = getUiStateByPage(routeName, {
    skeleton: initialSkeletonValue,
    spacer: initialSpacerValue,
    transparentHeader: initialTransparentHeaderValue
  })

  // Display skeleton
  store.commit('ui/UPDATE_LOADING', true)

  // Cancel skeleton display if no match was found
  if (skeleton === null) {
    store.commit('ui/UPDATE_LOADING', false)
  }

  store.commit('ui/UPDATE_SPACER', spacer)
  store.commit('ui/UPDATE_TRANSPARENT_HEADER', transparentHeader)
  store.commit('ui/UPDATE_SKELETON', skeleton)
}

