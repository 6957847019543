import {
  getWishList as getWishListApi,
  createWish as createWishApi,
  deleteWish as deleteWishApi
} from '../../api/secure/wish'

import Wish from '@/models/Wish'

export const getWishList = ({
  headers,
  instance
} = {}) => {
  return getWishListApi({ headers, instance })
}

export const createWish = ({
  productId,
  headers,
  instance
} = {}) => {
  return createWishApi({ productId, headers, instance })
}

export const deleteWish = ({
  wish,
  headers,
  instance
} = {}) => {
  const wishObject = new Wish(wish)
  return deleteWishApi({
    wishId: wishObject.getWishId(),
    headers,
    instance
  })
}
