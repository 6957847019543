<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9.8 9.2"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <path d="M3.9 9.2L0 5.4 1.4 4l2.2 2.2L8.2 0l1.6 1.2z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
