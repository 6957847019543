<template>
  <div class="c-international-switch">
    <div v-if="!mobile" class="c-international-switch__top">
      <p>{{ $t('components.molecules.header.menuLang.chooseCountry') }}</p>
      <button class="c-international-switch__close" @click="$emit('close', $event)">
        <CloseIcon fat :title="$t('components.molecules.header.userAccount.close')" />
      </button>
    </div>
    <ul class="c-international-switch__list">
      <li class="c-international-switch__item c-international-switch__item--current">
        <div>
          <div>
            <img
              :src="currentInterStore.pictureFlagUrl"
              :alt="currentInterStore.displayName"
            >
            <span>
              {{ currentInterStore.displayName }}
            </span>
          </div>
          <CheckIconLang />
        </div>
      </li>
      <li
        v-for="item in interStoreList"
        :key="item.defaultLocale"
        class="c-international-switch__item"
      >
        <a
          :href="getUrl(item.baseUrl)"
        >
          <img
            :src="item.pictureFlagUrl"
            :alt="item.displayName"
            loading="lazy"
          >
          <span>
            {{ item.displayName }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
import CheckIconLang from '@/components/atoms/icons/CheckIconLang'
import CloseIcon from '@/components/atoms/icons/Close'
export default {
  components: { CloseIcon, CheckIconLang },
  props: {
    mobile: Boolean
  },
  computed: {
    ...mapState({
      currentInterStore: state => state.international && state.international.currentInterStore
    }),
    ...mapGetters({
      interStoreList: 'international/getInterStoreListToDisplay'
    })
  },
  methods: {
    getUrl (baseUrl) {
      return `https://${baseUrl}`
    }
  }
}

</script>

<style lang="scss" scoped>
  .c-international-switch {
    $c: &;

    @include mq($until: tablet) {
      height: calc(100% - 5.4rem);
      overflow-y: auto;
    }

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2.5rem 3.2rem 2.2rem 3rem;
      background-color: $grey-200;
      color: $white;

      p {
        margin: 0;
        font-size: 1.3rem;
        font-weight: 500;
        letter-spacing: 0.13rem;
      }
    }

    &__close {
      width: 1.3rem;
      height: 1.3rem;
      padding: 0;
      border: none;
      background-color: transparent;
      color: $white;

      &::v-deep svg {
        display: block;
        width: 1.3rem;
        height: 1.3rem;
        fill: $white;
      }
    }

    &__list {
      padding: 0 3.4rem;
      border: 0.1rem solid $grey-800;
      border-top: none;

      @include mq($until: tablet) {
        padding: 0;
      }
    }

    &__item {
      > div {
        cursor: default;
      }

      a {
        &:hover {
          span {
            color: $primary-dark;
          }
        }
      }

      a,
      > div div {
        display: flex;
        align-items: center;
        padding: 2.3rem 0;
        text-decoration: none;

        @include mq($until: tablet) {
          padding: 1.9rem 1.7rem;
        }

        img {
          display: block;
          width: 2.6rem;
          height: 1.8rem;
          margin-right: 3rem;

          @include mq($until: tablet) {
            width: 2.2rem;
            height: 1.5rem;
            margin-right: 1.8rem;
          }
        }

        span {
          color: $grey-300;
          font-size: 1.3rem;
          font-weight: 700;
          text-decoration: none;
          transition: color 200ms ease-in-out;
        }
      }

      &:not(:only-child) {
        &:not(:last-child) {
          a,
          > div {
            border-bottom: 0.1rem solid $grey-800;
          }
        }
      }
      &#{$c}__item--current {
        @include mq($until: tablet) {
          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.9rem 1.7rem;
            padding-right: 2.1rem;

            div {
              padding: 0;
            }
          }
        }

        &::v-deep {
          svg {
            display: none;
            width: 1.2rem;
            height: 0.8rem;

            @include mq($until: tablet) {
              display: block;
            }
          }
        }
      }
    }
  }
</style>
