var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-main-heading",class:{
    'c-main-heading--sale': _vm.sale,
    'c-main-heading--404': _vm.error,
    'c-main-heading--small-title': _vm.smallTitle,
  }},[(_vm.subtitle)?_c('p',{staticClass:"c-main-heading__subtitle",class:{'u-align--left': _vm.titleLeft, 'u-align--center@tablet': _vm.titleLeft}},[_vm._v("\n    "+_vm._s(_vm.subtitle)+"\n  ")]):_vm._e(),_vm._v(" "),_c('h2',{staticClass:"c-main-heading__title",class:{
      'u-align--left': _vm.titleLeft,
      'u-align--center@tablet': _vm.titleLeft,
      'c-main-heading__title--noCap': _vm.noCapitalize
    }},[_vm._v("\n    "+_vm._s(_vm.title)+"\n  ")]),_vm._v(" "),(!!_vm.$slots.default)?_c('p',{staticClass:"c-main-heading__txt"},[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }