export const PAGE_TYPE_MAPPING = {
  index: 'HomePage',
  all: 'Listing',
  p: 'Product',
  t: 'CMS',
  tp: 'Template',
  login: 'Account',
  account: 'Account',
  contact: 'Contact',
  artists: 'Artists',
  maintenance: 'Maintenance',
  marques: 'Marques',
  unsubscribe: 'Unsubscribe',
  wishlist: 'Wishlist',
  'checkout-basket': 'Basket',
  checkout: 'CheckOut',
  autoconnect: 'Autoconnect',
  search: 'Search',
  LISTING_CATEGORY: 'Category',
  LISTING_BRAND: 'Brand',
  LISTING_ARTIST: 'Artist',
  LISTING_FLASH_SALE: 'Flash',
  LISTING_NOVELTIES: 'Nouveaute',
  LISTING_OUTLET: 'Outlet',
  LISTING_PROMOTIONS: 'Promotions',
  LISTING_TOP_SALES: 'TopSales'
}
export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY
export const GOOGLE_CONNECT_CLIENT_ID = process.env.GOOGLE_CONNECT_CLIENT_ID
export const GTM_ID_NUXT = process.env.GTM_ID_NUXT
export const GTM_ENV_PREVIEW = process.env.GTM_ENV_PREVIEW
export const GTM_ENV_AUTH = process.env.GTM_ENV_AUTH
export const GTM_JS_FILE_TAGGING = process.env.GTM_JS_FILE_TAGGING
