import { api } from '../instance'

export const getCustomerOrders = (options = {}) => {
  const {
    headers,
    instance
  } = options
  return (instance || api).get(
    '/secure/me/orders',
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getOrder = (options = {}) => {
  const {
    orderId,
    headers,
    instance
  } = options
  return (instance || api).get(
    `/secure/orders/${orderId}`,
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getOrderBill = (options = {}) => {
  const {
    orderId,
    headers,
    instance
  } = options
  return (instance || api).get(
    `/secure/orders/${orderId}/bill`,
    {
      headers,
      responseType: 'blob'
    }
  ).then(res => res)
    .catch((e) => {
      throw e
    })
}

export const getPurchaseOrderPdf = (options = {}) => {
  const {
    orderId,
    headers,
    instance
  } = options
  return (instance || api).get(
    `/secure/orders/${orderId}/purchase-order`,
    {
      headers,
      responseType: 'blob'
    }
  ).then(res => res)
    .catch((e) => {
      throw e
    })
}
