/**
 * A Severity Level (defined by Sentry)
 * @typedef {('fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug')} SeverityLevel
 */

/**
 * Send a message to Sentry
 * @param {string} message
 * @param {SeverityLevel} severityLevel 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'
 * @param extra
 * @returns {boolean}
 */
export function sendSentryMessage (message = '', severityLevel = 'error', extra = {}) {
  if (!this.$sentry) {
    return false
  }

  const user = this?.$auth?.$state?.user

  this.$sentry.captureMessage(message, {
    level: severityLevel,
    tags: {
      userId: user?.id || null,
      userEmail: user?.email || null
    },
    extra
  })
}
