<template>
  <div class="c-skeleton-wrapper">
    <SkeletonBreadcrumb />
    <div class="c-skeleton-product">
      <div class="c-skeleton-product__main">
        <SkeletonProductViewer />
      </div>
      <div class="c-skeleton-product__side">
        <SkeletonProductInfo />
      </div>
    </div>
  </div>
</template>
<script>
import SkeletonBreadcrumb from '@/components/molecules/skeleton/SkeletonBreadcrumb.vue'
import SkeletonProductInfo from '@/components/molecules/skeleton/SkeletonProductInfo.vue'
import SkeletonProductViewer from '@/components/molecules/skeleton/SkeletonProductViewer.vue'
export default {
  components: {
    SkeletonBreadcrumb,
    SkeletonProductInfo,
    SkeletonProductViewer
  }
}
</script>
<style scoped lang="scss">
.c-skeleton-wrapper {
  position: relative;
}

.c-skeleton-product {
  display: flex;
  width: 100%;
  padding: 2.7rem 2.2rem;
  margin: 0 auto;

  &__main {
    flex: 0 1 87.4rem;
  }

  &__side {
    flex: 0 0 44.8rem;
    padding: 0 3.7rem 0 5.7rem;
  }
}

@include mq($until: tablet) {
  .c-skeleton-product {
    flex-wrap: wrap;
    padding: 0;

    &__main,
    &__side {
      flex: 1 1 100%;
    }

    &__side {
      padding: 2.4rem 1.1rem;
    }
  }
}
</style>
