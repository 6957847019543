<template>
  <nav class="c-header-nav">
    <ul class="c-header-nav__list" data-cy="home-page-main-menu" role="menubar" aria-label="menu">
      <MenuItem
        v-for="(item, index) in itemActiveInMenu"
        :id="item.type"
        ref="item"
        :key="item.type"
        :type="item.type"
        :label="item.name"
        :url="item.url && item.url.path"
        :url-object="item.url"
        :menu-button-tab-index="activeItemIndex === index ? 0 : -1"
        :items="item.menuSubSections"
        :templates="item.template && item.template.templateItems"
      />
    </ul>
  </nav>
</template>

<script>
import MenuItem from '@/components/molecules/header/menu-item/MenuItem'

export default {
  components: { MenuItem },
  props: {
    menu: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      activeItemIndex: 0
    }
  },

  computed: {
    itemActiveInMenu () {
      return this.menu.filter((item) => {
        const isActiveMenuItem = item.active ?? true

        return isActiveMenuItem && item.name
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-header-nav {
  $c: &;

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}
</style>
