<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="23px"
    viewBox="0 0 24.2 22.8"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <path d="M0 17.9v-.5l.5-.3A6.7 6.7 0 0 0 5 13.6c.8-1.3.6-1.8-.9-2.3l-1.2-.5c-.7-.4-.5-1.2.2-1.5.4-.2.8-.1 1.2 0 .4.2.9.4 1.3.1l-.1-2.1c0-1.5 0-3 .9-4.3C7.6 1.2 9.4.2 11.5.1c2.4-.2 4.4.5 6 2.4 1.2 1.4 1.4 3.1 1.3 4.9l-.1 2.2 1.1-.2 1-.2c.5 0 .9.2 1 .8.1.5-.2.8-.6 1l-1.1.5c-1.4.5-1.6.9-.9 2.2 1 1.8 2.4 3.1 4.5 3.6.6.1.7.6.3 1l-.8.5-1.7.5c-.5.1-.7.3-.8.8-.2.9-.4 1-1.2.8-1.4-.3-2.7-.2-3.8.7l-1.1.7c-1.7 1-3.4 1-5 0l-1.5-.9c-.4-.2-.8-.5-1.3-.5-.7-.1-1.5 0-2.2.1-.9.1-1.1 0-1.3-.8v-.1c0-.4-.3-.5-.7-.6-.9-.6-1.9-.8-2.6-1.6z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
