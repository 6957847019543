<template>
  <div class="c-skeleton-breadcrumb">
    <div class="thumbnail-skeleton-animation" />
    <ArrowRightIcon />
    <div class="thumbnail-skeleton-animation" />
    <ArrowRightIcon />
    <div class="thumbnail-skeleton-animation" />
  </div>
</template>
<script>
import ArrowRightIcon from '@/components/atoms/icons/ArrowRight'
export default {
  components: {
    ArrowRightIcon
  }
}
</script>
<style lang="scss" scoped>
.c-skeleton-breadcrumb {
  position: relative;

  // bottom: 100%;
  // left: 0;

  display: flex;
  width: 100%;
  align-items: center;
  padding: 1.2rem 2.2rem;
  border-bottom: 0.1rem solid $grey-1100;
  background-color: $white;

  @include mq($until: tablet) {
    display: none;
  }

  > div {
    width: 4rem;
    height: 1rem;
  }

  &::v-deep svg {
    width: 0.6rem;
    height: 0.7rem;
    margin: 0 1rem;
    fill: $grey-600;
    transform: rotate(-90deg) translateX(0);
  }
}
</style>
