import { api } from './instance'

export const getTemplatePageBySlug = (options = {}) => {
  const {
    slug,
    version,
    headers,
    instance
  } = options
  return (instance || api).get(
    `/template-page/${slug}/${version}`,
    {
      headers
    })
    .then(res => res.data)
    .catch((e) => {
      throw e
    })
}
