<template>
  <span
    class="c-sticker"
    :class="{
      'c-sticker--ecoresponsable' : ecoResp,
      'c-sticker--exclusivity': exclu,
      'c-sticker--promo': promo,
      'c-sticker--grey': grey,
      'c-sticker--white': white,
      'c-sticker--large': large
    }"
  >
    <slot />
  </span>
</template>

<script>
/**
 * `sticker` component : display a small sticker with red or grey background
 **/
export default {
  props: {
    /**
     * swith background to grey
     */
    grey: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean,
      default: false
    },
    ecoResp: {
      type: Boolean,
      default: false
    },
    exclu: {
      type: Boolean,
      default: false
    },
    promo: {
      type: Boolean,
      default: false
    },
    /**
     * increase font-size
     */
    large: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-sticker {
  display: inline-block;
  padding: 0.5rem;
  background-color: $primary-dark;
  color: $white;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 0.8rem;
  text-align: center;
  text-transform: uppercase;

  &--grey {
    background-color: $grey-1100;
    color: $grey-300;
  }

  &--white {
    background-color: $white;
    color: $grey-300;
  }

  &--ecoresponsable {
    background-color: $green;
    color: $white;
    text-transform: uppercase;
  }

  &--exclusivity {
    background-color: $grey-400;
    color: $white;
    text-transform: uppercase;
  }

  &--large {
    font-size: $font-size-sm-mobile;
  }

  &--promo {
    font-weight: 700;
    letter-spacing: 0.13rem;
    text-transform: uppercase;
  }
}
</style>
