/* @format */

export const state = () => ({
  availableInterStore: null,
  currentInterStore: null,
})

// getters
export const getters = {
  getInterStoreListToDisplay(state) {
    const defaultCodeTarget = state.currentInterStore.defaultLocale
    return state.availableInterStore.filter(
      (item) => item.defaultLocale !== defaultCodeTarget,
    )
  },
  getAlgoliaProductIndex(state) {
    return state.currentInterStore.algoliaProductIndex
  },
  getAlgoliaSearchIndex(state) {
    return state.currentInterStore.algoliaSearchIndex
  },
  isInternationalDisplay(state) {
    return (
      state.currentInterStore &&
      state.currentInterStore.id !== 'laboutiqueofficielle'
    )
  },
  getPictureFlagUrl(state) {
    return (id) => {
      return state.availableInterStore.find((item) => item.id === id)
        .pictureFlagUrl
    }
  },
  getCountryCode(state) {
    return state.currentInterStore.countryCode
  },
}

export const actions = {
  setAvailableInterStore({ commit }, payload) {
    commit('SET_AVAILABLE_INTER_STORE', payload)
  },
  setCurrentInterStore({ commit }, payload) {
    commit('SET_CURRENT_INTER_STORE', payload)
  },
}

export const mutations = {
  SET_AVAILABLE_INTER_STORE(state, payload) {
    state.availableInterStore = payload
  },
  SET_CURRENT_INTER_STORE(state, payload) {
    state.currentInterStore = {
      algoliaProductIndex:
        process.env.ALGOLIA_PRODUCT_INDEX_PREFIX +
        payload.id +
        process.env.ALGOLIA_INDEX_ENV,
      algoliaSearchIndex:
        process.env.ALGOLIA_SEARCH_INDEX_PREFIX +
        payload.id +
        process.env.ALGOLIA_INDEX_ENV,
      ...payload,
    }
  },
}
