export function setSfmcCookie (data, cookiesInstance) {
  cookiesInstance.set('sfmc_data', data, {
    maxAge: 3600
  })
}
export function clearSfmcCookies (cookiesInstance) {
  cookiesInstance.remove('sfmc_data')
}
export function getSfmcCookies (cookiesInstance) {
  cookiesInstance.get('sfmc_data')
}
export function isRequestSfmc (obj) {
  return Object.values(obj).every(val => val !== null)
}
