/* @format */

import { appleLogin } from '@/api/secure/authentication'
import BaseCustomStrategy from '@/auth/BaseCustomStrategy'

export default class CustomAppleScheme extends BaseCustomStrategy {
  async loginFn() {
    const [data, redirectUri, headers] = arguments
    const { authorization, user } = data

    return await appleLogin({
      headers,
      user,
      auth: authorization,
      redirectUri,
    })
  }
}
