import DataStructure from './DataStructure'
export default class Customer extends DataStructure {
  constructor ({
    id = null,
    civility,
    lastName,
    firstName,
    birthday = null,
    email,
    status = null,
    roles = null,
    newsletter = null,
    password = null,
    facebook = null,
    appleId = null
  }) {
    super()
    this.id = this.isset(id) ? this.convertToInteger(id) : null
    this.civility = this.convertToInteger(civility)
    this.lastName = this.convertToString(lastName)
    this.firstName = this.convertToString(firstName)
    this.birthday = this.isset(birthday) ? this.convertToString(birthday) : null
    this.email = this.convertToString(email)
    this.status = this.isset(status) ? this.convertToNumber(status) : null
    this.roles = this.isset(roles) ? this.getRoles(roles) : null
    this.newsletter = this.isset(newsletter) ? this.convertToBoolean(newsletter) : null
    this.password = this.isset(password) ? this.convertToString(password) : null
    this.facebook = this.isset(facebook) ? this.convertToString(facebook) : null
    this.appleId = this.isset(appleId) ? this.convertToString(appleId) : null
  }

  getRoles (roles) {
    if (!roles || !roles.length) {
      return null
    }
    const rolesArray = roles.map(item => this.convertToString(item))
    return rolesArray
  }
}
