var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-dropdown-menu",class:{
    'c-dropdown-menu--active' : _vm.hasActiveClass && _vm.open || _vm.forceshow,
    'c-dropdown-menu--basket' : _vm.basket,
    'c-dropdown-menu--lang' : _vm.lang,
    'c-dropdown-menu--new-product' : _vm.newProduct,
    'c-dropdown-menu--inter': _vm.isInternationalDisplay
  }},[_c('button',{ref:"button",staticClass:"c-dropdown-menu__button",attrs:{"id":`${_vm.id}__label`,"role":"button","aria-haspopup":"true","aria-controls":`${_vm.id}__region`,"aria-expanded":_vm.open.toString(),"data-cy":"header-tool--account"},on:{"click":_vm.toggle,"mouseenter":_vm.hoverOn,"mouseleave":_vm.hoverOff}},[_vm._t("button")],2),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open || _vm.forceshow),expression:"open || forceshow"}],ref:"content",staticClass:"c-dropdown-menu__panel",attrs:{"id":`${_vm.id}__region`,"role":"menu","aria-labelledby":`${_vm.id}__label`},on:{"mouseenter":_vm.hoverOn,"mouseleave":_vm.hoverOff}},[_vm._t("default",null,{"hide":_vm.navigationHide})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }