const middleware = {}

middleware['gtm'] = require('../middleware/gtm.js')
middleware['gtm'] = middleware['gtm'].default || middleware['gtm']

middleware['hasAddress'] = require('../middleware/hasAddress.js')
middleware['hasAddress'] = middleware['hasAddress'].default || middleware['hasAddress']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['marketingCloudConversion'] = require('../middleware/marketingCloudConversion.js')
middleware['marketingCloudConversion'] = middleware['marketingCloudConversion'].default || middleware['marketingCloudConversion']

middleware['outlet'] = require('../middleware/outlet.js')
middleware['outlet'] = middleware['outlet'].default || middleware['outlet']

middleware['page'] = require('../middleware/page.js')
middleware['page'] = middleware['page'].default || middleware['page']

middleware['product'] = require('../middleware/product.js')
middleware['product'] = middleware['product'].default || middleware['product']

middleware['search'] = require('../middleware/search.js')
middleware['search'] = middleware['search'].default || middleware['search']

middleware['skeleton'] = require('../middleware/skeleton.js')
middleware['skeleton'] = middleware['skeleton'].default || middleware['skeleton']

middleware['templatePage'] = require('../middleware/templatePage.js')
middleware['templatePage'] = middleware['templatePage'].default || middleware['templatePage']

middleware['unsub'] = require('../middleware/unsub.js')
middleware['unsub'] = middleware['unsub'].default || middleware['unsub']

export default middleware
