<template>
  <div
    class="c-footer-social-network"
    :class="{'c-footer-social-network--white': white}"
  >
    <div>
      <div v-if="logoStore" class="c-footer-social-network__lbo">
        <LogoLBO v-if="!isInternationalDisplay" :title="$t('components.organisms.layout.header.logoTitle')" />
        <LogoRYSES v-else :title="currentInterStore.displayName" />
      </div>
      <ul class="c-footer-social-network__list">
        <template v-for="(network, index) in SOCIAL_NETWORKS_LINKS[currentInterStore.id]">
          <li :key="index">
            <template v-if="network.name === 'snapchat'">
              <button
                class="js-snapchat"
                :title="isInternationalDisplay ? $t('components.molecules.footer.socialNetwork.snaplbo',{ name: currentInterStore.name }) : $t('components.molecules.footer.socialNetwork.snaplbo')"
                @mouseenter="showSnapchat()"
                @focusin="showSnapchat()"
                @mouseleave="hideSnapchat()"
                @focusout="hideSnapchat()"
              >
                <component :is="network.icon" />
                <transition name="fade-up">
                  <div
                    v-if="snapchat"
                    class="js-snapchat__tooltip"
                  >
                    <img
                      :src="`https://assets.laboutiqueofficielle.com/assets/footer/snap-${currentInterStore.id}.png`"
                      :alt="$t('components.molecules.footer.socialNetwork.snaplbo')"
                    >
                    <p>{{ isInternationalDisplay ? `Snap ${currentInterStore.name}` : 'Snaplbo' }}</p>
                  </div>
                </transition>
              </button>
            </template>
            <template v-else>
              <a
                :href="network.link"
                target="_blank"
                rel="noopener"
                :aria-label="$t(`components.molecules.footer.socialNetwork.${network.name}`)"
                :title="$t(`components.molecules.footer.socialNetwork.${network.name}`)"
              >
                <component :is="network.icon" />
              </a>
            </template>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import LogoLBO from '@/components/atoms/icons/LogoLBO'
import LogoRYSES from '@/components/atoms/icons/LogoRYSES'
import SnapchatIcon from '@/components/atoms/icons/Snapchat'
import InstagramIcon from '@/components/atoms/icons/Instagram'
import FacebookIcon from '@/components/atoms/icons/Facebook'
import TikTokIcon from '@/components/atoms/icons/TikTok'
import YoutubeIcon from '@/components/atoms/icons/Youtube'
import { SOCIAL_NETWORKS_LINKS } from '@/config/footer/config'

let timeout

export default {
  components: { LogoLBO, LogoRYSES, SnapchatIcon, InstagramIcon, FacebookIcon, TikTokIcon, YoutubeIcon },
  props: {
    logoStore: {
      type: Boolean,
      default: true
    },
    white: {
      type: Boolean
    }
  },
  data () {
    return {
      snapchat: false,
      SOCIAL_NETWORKS_LINKS
    }
  },
  computed: {
    ...mapState({
      currentInterStore: state => state.international && state.international.currentInterStore
    }),
    ...mapGetters({ isInternationalDisplay: 'international/isInternationalDisplay' })
  },
  methods: {
    showSnapchat () {
      clearTimeout(timeout)
      this.snapchat = true
    },
    hideSnapchat () {
      timeout = setTimeout(() => {
        this.snapchat = false
      }, 100)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-footer-social-network {
  $c: &;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12.3rem 0 10.5rem;
  background: $grey-250;

  &--white {
    background-color: $white;

    #{$c}__list {
      svg {
        fill: $grey-500;
      }
    }
  }

  &__lbo svg {
    width: 19rem;
    height: 5.2rem;
    fill: $white;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 2.5rem 0 0;
    list-style: none;

    &:only-child {
      margin-top: 0;
    }

    li {
      margin: 0;
    }

    a {
      display: block;
      padding: 0.8rem;
    }

    svg {
      fill: $grey-500;
    }

    @include mq($from: tablet) {
      a:hover,
      a:focus,
      button:hover,
      button:focus {
        svg {
          fill: $white;
        }
      }
    }
  }

  .js-snapchat {
    position: relative;
    padding: 0.8rem;

    &__tooltip {
      position: absolute;
      top: -5rem;
      right: 100%;
      width: 15rem;
      margin-right: 1rem;

      > img {
        display: block;
        width: 100%;
        height: auto;
      }

      > p {
        margin-top: 0.5rem;
        color: $white;
        font-weight: 700;
        text-align: center;
      }

      @include mq($until: tablet) {
        top: 100%;
        right: auto;
        left: 0;
        margin-top: 1rem;
      }
    }
  }
}
</style>
