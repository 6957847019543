import { api } from './instance'

export const createBasketDetail = (options = {}) => {
  const { basketObject, headers, instance } = options
  return (instance || api).post(
    '/basket/detail',
    { ...basketObject },
    { headers }
  ).then(res => res.data)
    .catch((error) => {
      throw error
    })
}

export const getCarrierFreeShipping = (options = {}) => {
  const { headers, instance } = options
  return (instance || api).get('checkout/carriers/free-shipping', {
    headers
  })
    .then(res => res.data)
    .catch((error) => {
      throw error
    })
}
