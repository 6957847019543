/* @format */

import { facebookLogin } from '@/api/secure/authentication'
import BaseCustomStrategy from '@/auth/BaseCustomStrategy'

export default class CustomFacebookScheme extends BaseCustomStrategy {
  async loginFn() {
    const [accessToken, userID, headers] = arguments
    return await facebookLogin({
      headers,
      accessToken,
      userID,
    })
  }
}
