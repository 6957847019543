<template>
  <div class="c-skeleton-highlight-style">
    <div class="c-skeleton-highlight-style__inner">
      <div class="c-skeleton-highlight-style__list">
        <div class="c-skeleton-highlight-style__item u-1/2 u-1/1@tablet">
          <div class="c-skeleton-highlight-style__full thumbnail-skeleton-animation" />
          <div class="c-skeleton-highlight-style__sub-list">
            <div class="u-1/2 u-1/1@tablet">
              <SkeletonCategory />
            </div>
            <div class="u-1/2 u-1/1@tablet">
              <SkeletonCategory />
            </div>
          </div>
        </div>
        <div class="c-skeleton-highlight-style__item u-1/2 u-1/1@tablet">
          <div class="c-skeleton-highlight-style__full thumbnail-skeleton-animation" />
          <div class="c-skeleton-highlight-style__sub-list">
            <div class="u-1/2 u-1/1@tablet">
              <SkeletonCategory />
            </div>
            <div class="u-1/2 u-1/1@tablet">
              <SkeletonCategory />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SkeletonCategory from '@/components/molecules/skeleton/SkeletonCategory.vue'
export default {
  components: {
    SkeletonCategory
  }
}
</script>
<style lang="scss" scoped>
.c-skeleton-highlight-style {
  $c: &;

  position: relative;
  padding: 0 8.1rem;
  margin-bottom: 9.2rem;

  @include mq($until: tablet) {
    padding: 0 0.5rem;
    margin-bottom: 6.5rem;
  }

  &__inner {
    position: relative;
    padding: 0 5.3rem;

    @include mq($until: tablet) {
      padding: 0;
    }
  }

  &__list {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 -1.1rem;
  }

  &__item {
    padding: 0 1.1rem;

    &:first-child {
      @include mq($until: tablet) {
        margin-bottom: 5.3rem;
      }

      #{$c}__sub-list {
        padding-left: 2.2rem;

        @include mq($until: tablet) {
          padding-left: 0;
        }
      }
    }

    &:last-child {
      #{$c}__sub-list {
        padding-right: 2.2rem;

        @include mq($until: tablet) {
          padding-right: 0;
        }
      }
    }
  }

  &__full {
    width: 100%;
    padding-bottom: 134%;
    margin-bottom: 5rem;

    @include mq($until: tablet) {
      margin-bottom: 0.5rem;
    }
  }

  &__sub-list {
    display: flex;
    margin: 0 -1.1rem;

    @include mq($until: tablet) {
      margin: 0 -0.25rem;
    }

    > div {
      padding: 0 1.1rem;

      @include mq($until: tablet) {
        padding: 0 0.25rem;
      }
    }
  }
}
</style>
