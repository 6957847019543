import DataStructure from './DataStructure'
export default class CarrierDeliveryDate extends DataStructure {
  constructor ({
    id,
    shippingDate,
    deliveryDate
  }) {
    super()
    this.id = this.convertToNumber(id)
    this.shippingDate = this.convertToString(shippingDate)
    this.deliveryDate = this.convertToString(deliveryDate)
  }
}
