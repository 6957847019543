// eslint-disable-next-line
import VuexPersistence from 'vuex-persist'

export default ({ store }) => {
  new VuexPersistence({
    /* your options */
    reducer: (state) => {
      // We can silent linter errors here because we want to remove
      // the freeShippingAmount prop from our filteredBasketState object
      const { freeShippingAmount, ...filteredBasketState } = state.basket
      return {
        basket: { ...filteredBasketState }
      }
    },
    filter: (mutation) => {
      return mutation.type.startsWith('basket') && mutation.type !== 'basket/SYNCHRONIZER_STORAGE'
    }
  }).plugin(store)
}
