import { sendSentryMessage } from '@/helpers/errorHelper'
import { getGtmUserData } from '@/helpers/gtmHelper'
import { isForbiddenQuery } from '@/helpers/pageHelper'

export const state = () => ({
  pagename: null,
  popinOutlet: false,
  homeBanner: null,
  countries: null,
  seo: null,
  breadcrumbTarget: null,
  locale: 'fr', // FIXME: To remove after belgium
  redirectScrollItem: false, // Use for redirect after connection in wishlist connect modal
  comeFromStepOneOfBasket: false,
  globalPageInfo: null,
  showModalConnect: false,
  headerBanner: null,
  outletAccessAllowed: false,
  localStorageWarnAccepted: false
})

export const actions = {

  async nuxtServerInit ({ commit, rootState, dispatch }, context) {
    const { route, $api, error } = context
    if (route.path === '/maintenance') {
      return
    }

    if (isForbiddenQuery(route.query)) {
      error({ statusCode: 503 })
    }

    try {
      const { $axios } = context
      const {
        countries,
        stores,
        menu,
        footer,
        freeShippingAmount,
        headerBanner,
        boSettings
      } = await $axios.$get('/init')

      const storesSanitized = stores.map((store) => {
        return {
          ...store,
          langCode: store.langCode.toLowerCase(),
          countryCode: store.countryCode.toUpperCase()
        }
      })

      const storeCorrespondingToI18nLocale = storesSanitized.find(item => item.langCode === context.$config.locale)
      // TODO: to remove when the manual sentry is monitored
      if (!storeCorrespondingToI18nLocale) {
        sendSentryMessage.bind(context, 'Able to get Stores but non matching the current locale', 'fatal', {
          storeResponse: stores,
          local: context.$config.locale
        })()
      }

      commit('SET_COUNTRIES', countries)
      dispatch('international/setCurrentInterStore', storeCorrespondingToI18nLocale)
      dispatch('international/setAvailableInterStore', storesSanitized)
      dispatch('gtm/setCountryAndLanguage', {
        countryCode: storeCorrespondingToI18nLocale.countryCode,
        langCode: storeCorrespondingToI18nLocale.langCode
      })
      dispatch('gtm/setPageDataIsoCode', storeCorrespondingToI18nLocale.countryCode)
      dispatch('gtm/setPageDataStore', storeCorrespondingToI18nLocale.id)
      dispatch('website/getMenu', menu)
      dispatch('website/getFooter', footer)
      dispatch('website/getBoSettings', boSettings)
      commit('basket/SET_FREE_SHIPPING_AMOUNT', freeShippingAmount.freeShipping)
      if (this.$auth.loggedIn) {
        await dispatch('wishlist/getProducts', { instance: $api })

        const authState = this.$auth.$state
        try {
          const gtmUserData = await getGtmUserData(authState.user, authState.addresses)

          dispatch('gtm/setDefaultClientData', gtmUserData.userDataObject)
          dispatch('gtm/setDefaultClientAddresse', gtmUserData.userAddressObject)
        } catch (err) {
          sendSentryMessage.bind(this.app, 'Call error in gtmUserData', 'error', {
            action: 'nuxtServerInit',
            call: 'getGtmUserData',
            data: {
              user: { ...authState.user },
              addresses: { ...authState.addresses },
              loggedIn: this.$auth.loggedIn
            },
            exception: err
          })()
        }
      }

      if (headerBanner.length) {
        dispatch('setHeaderBanner', headerBanner[0].templateItems[0] || null)
      }
    } catch (e) {
      sendSentryMessage.bind(this.app, 'Call error in nuxtServerInit', 'fatal', {
        action: 'nuxtServerInit',
        routePath: route.path,
        routeQuery: route.query,
        locale: rootState.locale,
        call: 'first calls',
        exception: e
      })()
    }
  },
  setOutletAccessAllowed ({ commit }, payload) {
    commit('SET_OUTLET_ACCESS_ALLOWED', payload)
  },
  setHeaderBanner ({ commit }, payload) {
    commit('SET_HEADER_BANNER', payload)
  },
  setShowModalConnect ({ commit }, payload) {
    commit('SET_SHOW_MODAL_CONNECT', payload)
  },
  setGlobalPageInfo ({ commit }, payload) {
    commit('SET_GLOBAL_PAGE_INFO', payload)
  },
  setRedirectScrollItem ({ commit }, payload) {
    commit('SET_REDIRECT_SCROLL_POSITION', payload)
  },
  setBreadcrumbTarget ({ commit }, payload) {
    commit('SET_BREADCRUMB_TARGET', payload)
  },
  setSeo ({ commit }, payload) {
    commit('SET_SEO', payload)
  },
  setLocale ({ commit }, payload) {
    commit('SET_LOCALE', payload)
  },

  setPageName ({ commit }, payload) {
    commit('SET_PAGE_NAME', payload)
  },

  togglePopinOutlet ({ commit }, payload) {
    commit('TOGGLE_POPIN_OUTLET', payload)
  },
  setHomeBanner ({ commit }, payload) {
    commit('SET_HOME_BANNER', payload)
  },
  setComeFromStepOneOfBasket ({ commit }, payload) {
    commit('SET_COME_FROM_STEP_ONE', payload)
  }
}
export const getters = {
  getBreadcrumb (state) {
    return state.globalPageInfo?.breadcrumb?.parts ?? []
  },
  getBubbles (state) {
    return state.globalPageInfo?.bubbles ?? []
  },
}
export const mutations = {
  SET_OUTLET_ACCESS_ALLOWED (state, payload) {
    state.outletAccessAllowed = payload
  },
  SET_HEADER_BANNER (state, payload) {
    state.headerBanner = payload
  },
  SET_SHOW_MODAL_CONNECT (state, payload) {
    state.showModalConnect = payload
  },
  SET_GLOBAL_PAGE_INFO (state, payload) {
    state.globalPageInfo = payload
  },
  SET_REDIRECT_SCROLL_POSITION (state, payload) {
    state.redirectScrollItem = payload
  },
  SET_LOCALE (state, payload) {
    state.locale = payload
  },
  SET_BREADCRUMB_TARGET (state, payload) {
    state.breadcrumbTarget = payload
  },
  SET_SEO (state, payload) {
    state.seo = payload
  },
  SET_PAGE_NAME (state, payload) {
    state.pagename = payload
  },
  SET_HOME_BANNER (state, payload) {
    state.homeBanner = payload
  },

  TOGGLE_POPIN_OUTLET (state, payload) {
    state.popinOutlet = payload
  },

  SET_COUNTRIES (state, payload) {
    state.countries = payload
  },

  SET_COME_FROM_STEP_ONE (state, payload) {
    state.comeFromStepOneOfBasket = payload
  },

  SET_LOCAL_STORAGE_WARN (state, payload) {
    state.localStorageWarnAccepted = payload
  }
}
