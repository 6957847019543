<template>
  <div v-if="data" class="c-footer-sitemap">
    <div class="c-footer-sitemap__column c-footer-sitemap__column--categories">
      <div v-if="data[0]" class="c-footer-sitemap__item">
        <div class="c-footer-sitemap__title">
          {{ data[0].label }}
        </div>
        <ul class="c-footer-sitemap__list">
          <li v-for="item in data[0].links" :key="item.id">
            <nuxt-link :to="localePath(item.url.path)" :title="item.altText">
              {{ item.title }}
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="c-footer-sitemap__column c-footer-sitemap__column--top-brands">
      <div v-if="data[1]" class="c-footer-sitemap__item">
        <div class="c-footer-sitemap__title">
          {{ data[1].label }}
        </div>
        <ul class="c-footer-sitemap__list">
          <li v-for="item in data[1].links" :key="item.id">
            <nuxt-link :to="localePath(item.url.path)" :title="item.altText">
              {{ item.title }}
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="c-footer-sitemap__column c-footer-sitemap__column--info">
      <div v-if="data[2]" class="c-footer-sitemap__item">
        <div class="c-footer-sitemap__title">
          {{ data[2].label }}
        </div>
        <ul class="c-footer-sitemap__list">
          <li v-for="item in data[2].links" :key="item.id">
            <nuxt-link :to="localePath(item.url.path)" :title="item.altText">
              {{ item.title }}
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="c-footer-sitemap__item c-footer-sitemap__column--confidentialite">
      <div v-if="data[3]" class="c-footer-sitemap__item">
        <div class="c-footer-sitemap__title">
          {{ data[3].label }}
        </div>
        <ul class="c-footer-sitemap__list">
          <li v-for="item in data[3].links" :key="item.id">
            <nuxt-link :to="localePath(item.url.path)" :title="item.altText">
              {{ item.title }}
            </nuxt-link>
          </li>
          <li>
            <button @click.prevent="handleCookiParameter">
              {{ $t('components.molecules.footer.menu.cookieparam') }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {

  computed: {
    ...mapState({
      data: state => state.website?.footer
    })
  },
  methods: {
    handleCookiParameter () {
      const lastPositionY = window.scrollY
      // eslint-disable-next-line
      if('undefined' !== typeof Cookiebot) {
        window.addEventListener('CookiebotOnAccept', (e) => {
          e.preventDefault()
          window.scrollTo(0, lastPositionY)
        })
        // eslint-disable-next-line
        Cookiebot.renew()
        setTimeout(() => {
          document.querySelector('.c-button--personalize').click()
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-footer-sitemap {
  $c: &;

  display: grid;
  width: 100%;
  padding: 7.4rem 12.2rem 4.5rem;
  background: $grey-100;
  grid-gap: 0;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;

  @include mq($until: tablet) {
    padding: 5rem 1rem 1.5rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto auto;
  }

  &__column {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &--categories {
      grid-area: 1 / 1 / 3 / 2;

      @include mq($until: tablet) {
        grid-area: 1 / 1 /3 / 2;
      }
    }

    &--top-brands {
      grid-area: 1 / 2 / 3 / 3;

      @include mq($until: tablet) {
        grid-area: 1 / 2 / 2 / 3;
      }
    }

    &--info {
      grid-area: 1 / 3 / 2 / 4;

      @include mq($until: tablet) {
        grid-area: 2 / 2 / 4 / 3;
      }
    }

    &--confidentialite {
      grid-area: 2 / 3 / 3 / 4;

      @include mq($until: tablet) {
        grid-area: 3 / 1 / 4 / 2;
      }
    }

    @include mq($from: tablet) {
      max-width: 22.4rem;
    }
  }

  &__item {
    width: 100%;

    @include mq($until: tablet) {
      padding-right: 1rem;
    }
  }

  &__title {
    position: relative;
    padding-left: $global-spacing-unit-xs;
    margin: 0 0 4rem;
    color: $white;
    font-family: $font-family-main;
    font-size: 1.4rem;
    font-weight: 900;
    text-transform: uppercase;

    @include mq($until: tablet) {
      margin-bottom: 3.3rem;
    }

    &::before {
      position: absolute;
      top: 0.2rem;
      left: 0;
      width: 0.2rem;
      height: 1.4rem;
      background: $primary;
      content: "";
    }

    #{$c}__list + & {
      margin-top: 5rem;
    }
  }

  &__list {
    padding: 0 0 0 1.2rem;
    margin: 0 0 4.5rem;
    list-style: none;
  }

  li {
    padding: 0.4rem 0 0;
    margin: 0 0 1.8rem;
    font-size: $font-size-sm;
    font-weight: 300;

    @include mq($until: tablet) {
      padding: 0.2rem 0 0;
      margin: 0 0 1.2rem;
      font-size: $font-size-sm-mobile;
    }
  }

  a,
  button {
    color: $grey-600;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $grey-1100;
    }
  }
}
</style>
