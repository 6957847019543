<template>
  <div class="c-modal-simple">
    <div v-if="!disableTitle" class="c-modal-simple__txt">
      <slot name="title" />
    </div>
    <slot name="content"></slot>
    <VButton v-bind="additionnalPropsButton" @click="$modal.hide(modalName)">
      <slot name="button" />
    </VButton>
  </div>
</template>
<script>
import VButton from '@/components/atoms/global/button/VButton'
export default {
  components: { VButton },
  props: {
    modalName: {
      type: String,
      default: 'simpleModal'
    },
    additionnalPropsButton: {
      type: Object,
      default: () => {}
    },
    disableTitle: Boolean
  }
}
</script>
<style lang="scss" scoped>
.c-modal-simple {
  padding: 8.1rem 7rem 7.6rem;
  text-align: center;

  &__txt {
    margin-bottom: 6rem;
  }
}
</style>
