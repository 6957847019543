import DataStructure from './DataStructure'
// @TODO Finir ReturnOrderProduct model
import ReturnOrderReason from './ReturnOrderReason'
export default class ReturnOrderProduct extends DataStructure {
  constructor ({
    id = null,
    quantity = null,
    totalPrice = null,
    comment = null,
    reason = null,
    orderProduct = null,
    orderProductPicture = null,
    orderProductTitle = null,
    orderProductName = null,
    size = null
  }) {
    super()
    this.id = this.isset(id) ? this.convertToNumber(id) : null
    this.quantity = this.isset(quantity) ? this.convertToNumber(quantity) : null
    this.totalPrice = this.isset(totalPrice) ? this.convertToNumber(totalPrice) : null
    this.comment = this.isset(comment) ? this.convertToString(comment) : null
    this.reason = this.isset(reason) ? this.getReason(reason) : null
    this.orderProduct = this.isset(orderProduct) ? this.convertToNumber(orderProduct) : null
    this.orderProductPicture = this.isset(orderProductPicture) ? this.convertToString(orderProductPicture) : null
    this.orderProductTitle = this.isset(orderProductTitle) ? this.convertToString(orderProductTitle) : null
    this.orderProductName = this.isset(orderProductName) ? this.convertToString(orderProductName) : null
    this.size = this.isset(size) ? this.convertToString(size) : null
  }

  getReason (value) {
    if (!value) {
      return null
    }
    const returnOrderReason = new ReturnOrderReason(value)
    return returnOrderReason.getApiObject()
  }
}
