
<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.2 20.2"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <path d="M20 18.5l-2.6-2.6a9.5 9.5 0 0 0 2.2-6.1A9.8 9.8 0 0 0 0 9.8a9.7 9.7 0 0 0 15.9 7.5l2.6 2.6c.2.2.5.3.7.3s.5-.1.7-.3c.4-.3.4-1 .1-1.4zm-10.2-1C5.5 17.5 2 14 2 9.8S5.5 2 9.8 2s7.8 3.5 7.8 7.8-3.6 7.7-7.8 7.7z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
