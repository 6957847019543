<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29px"
    height="29px"
    viewBox="0 0 28.6 28.7"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <path d="M15.3 28.7c-.1 0-.1 0 0 0H1.9c-.4 0-.7 0-1-.2-.7-.4-.9-.9-.9-1.6V4 1.5C0 1.2.1.9.4.6.8.1 1.3 0 1.9 0h25.3c.7 0 1.4.8 1.4 1.5V27c0 .6-.3 1.1-.8 1.4l-.6.3h-7.3v-.3-10.5-.3h3.7l.2-1.8.3-2.4c0-.1 0-.2-.2-.2h-4l.1-3.4c0-.6.4-1.1 1-1.3l.7-.1H24c.2 0 .3-.1.3-.3V4.7c0-.2-.1-.3-.3-.3l-3.1-.1a7 7 0 0 0-2 .3A5 5 0 0 0 16.6 6a6 6 0 0 0-1.4 3.8V13.2h-3.4c-.2 0-.3 0-.3.3v4.1h3.4c.2 0 .3.1.3.3v10.5l.1.3z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
