import {
  subscribeNewsletter as subscribeNewsletterApi,
  unsubscribeNewsletter as unsubscribeNewsletterApi,
  isNewsletterSubscribed as isNewsletterSubscribedApi
} from '@/api/newsletter'

export const subscribeNewsletter = ({
  email,
  headers,
  instance
} = {}) => {
  return subscribeNewsletterApi({
    email,
    headers,
    instance
  })
}

export const unsubscribeNewsletter = ({
  email,
  headers,
  instance
} = {}) => {
  return unsubscribeNewsletterApi({
    email,
    headers,
    instance
  })
}

export const isNewsletterSubscribed = ({
  email,
  headers,
  instance
} = {}) => {
  return isNewsletterSubscribedApi({
    email,
    headers,
    instance
  })
}
