<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27px"
    height="26px"
    viewBox="0 0 26.2 26"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <path d="M0 17.3V8.6L.1 7A7 7 0 0 1 5.5.3l3-.4h9.2L19 0a7 7 0 0 1 6.8 5.5l.4 3v8.7l-.1 1.6a7 7 0 0 1-5.4 6.7l-3 .4H8.5l-1.3-.1a7 7 0 0 1-6.8-5.5c-.3-1-.3-2-.4-3zm13.1 6.2v.1h3l3-.1c2.8-.1 4.7-2 4.7-4.8V7.2c0-2.7-2-4.7-4.7-4.7H7.2c-2.8.1-4.7 2-4.8 4.9v11.2c0 2.9 1.9 4.8 4.8 4.9h5.9z" />
    <path d="M6.4 12.9c.1-3.8 3.1-6.6 7.1-6.6a6.6 6.6 0 1 1-.7 13.2 6.6 6.6 0 0 1-6.4-6.6zm6.7 4.4c2.4 0 4.4-2 4.4-4.4 0-2.4-2-4.3-4.4-4.3a4.3 4.3 0 0 0-4.4 4.3c0 2.5 2 4.4 4.4 4.4zM21.6 6.1c0 .8-.7 1.5-1.5 1.5-.9 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5c.9-.1 1.5.6 1.5 1.5z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
