<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 336.2"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <path
      d="M538.7,166.1c0.5-7.8-0.4-14.4-2.9-19.8c-2.5-5.4-6-10.1-10.5-14c-4.5-3.9-9.7-7.3-15.7-10.2
      c-5.9-2.9-12-5.8-18.4-8.5c-6.3-2.7-12.5-5.6-18.7-8.5c-6.2-2.9-11.8-6.4-16.8-10.5c-2.7-2.1-4.4-5-5.3-8.6
      c-0.9-3.6-0.7-7.1,0.5-10.5c1.2-3.4,3.6-6.2,6.9-8.5c3.4-2.3,7.9-3.2,13.6-2.7c4.3,0.4,7.4,1.5,9.6,3.6c2.1,2,3.8,4.5,5,7.3
      c1.2,2.8,2.3,5.8,3.1,9c0.8,3.2,2.1,6.2,3.9,8.9c1.8,2.7,4.2,5,7.3,6.8c3.1,1.8,7.6,2.7,13.4,2.7c4.4,0,8.2-1.1,11.2-3.5
      c3-2.3,5-5.2,6-8.7c1-3.5,0.7-7.2-0.8-11.3c-1.5-4.1-4.6-7.8-9.2-11.3c-4.6-3.5-10.9-6.3-19-8.7c-8.1-2.3-18.2-3.5-30.5-3.5
      c-9.7,0-18.7,1.2-26.8,3.5c-8.2,2.3-15.2,5.6-21,9.8c-5.8,4.3-10.4,9.4-13.6,15.3c-3.2,5.9-4.8,12.5-4.8,19.8
      c0,8.2,1.6,15.1,4.7,20.7c3.1,5.7,7.1,10.5,12.1,14.4c5,3.9,10.5,7.2,16.5,10c6,2.7,12,5.4,17.8,8c5.9,2.6,11.3,5.2,16.2,7.8
      c5,2.7,8.8,5.9,11.4,9.8c1.9,2.8,3.1,6,3.5,9.6c0.4,3.6-0.2,6.9-1.7,10.1c-1.5,3.2-3.8,5.9-6.9,8.1c-3.1,2.2-7.1,3.3-12.1,3.3
      c-5.1,0-9-1.2-11.7-3.5c-2.7-2.3-4.8-5.1-6.5-8.5c-1.7-3.4-3.1-7-4.1-11c-1.1-4-2.5-7.7-4.3-11s-4.2-6.1-7.2-8.2
      c-3-2.1-7.3-3.1-12.8-2.9c-5,0.2-9,1.6-12,4.4c-3,2.7-4.8,6.1-5.5,10c-0.6,3.9,0.1,8.2,2.1,12.8c2,4.6,5.6,8.9,10.6,12.8
      c5.1,3.9,11.8,7.1,20.2,9.7c8.4,2.6,18.7,3.9,31,3.9c13.8,0,25.3-1.5,34.4-4.5c9.1-3,16.4-6.9,21.8-11.6c5.4-4.7,9.3-9.9,11.7-15.6
      C536.9,176.9,538.3,171.5,538.7,166.1z"
    />
    <path
      d="M686.1,186.3c1.8-2.5,1.9-4.6,0.5-6.4c-1.4-1.4-3.6-1.7-6.6-0.8c-8,2.1-15.3,3.2-22.1,3.2
      c-8.7,0-16.2-1.5-22.6-4.5c-6.4-3-11.8-7.1-16.2-12.2c-4.4-5.1-8-11.1-10.6-18c-2.7-6.8-4.6-14-5.8-21.7h82.2
      c3.2,0,5.1-1.6,5.6-4.8c0.5-3.2,0.6-7,0.1-11.6c-0.4-4.5-1.5-9.2-3.3-14.1c-1.8-4.9-4.3-9.7-7.6-14.5c-3.3-4.8-7.5-9.1-12.6-12.9
      c-5.1-3.8-11.3-6.9-18.5-9.2c-7.2-2.3-15.6-3.4-25.1-3.2c-11.7,0.2-22.3,2.5-31.9,7c-9.6,4.5-17.7,10.6-24.3,18.4
      c-6.6,7.7-11.7,16.7-15,26.9c-3.4,10.2-4.6,21-3.7,32.6c0.9,10.8,3.5,20.7,7.7,29.8c4.3,9,9.8,16.8,16.7,23.3
      c6.9,6.5,15.1,11.5,24.5,15.2c9.4,3.6,19.5,5.4,30.3,5.4c8.5,0,16.1-1.1,22.7-3.2c6.6-2.1,12.4-4.7,17.1-7.6
      c4.8-2.9,8.7-6,11.7-9.2C682.1,191.1,684.5,188.5,686.1,186.3z M602.9,94.2c1.1-5.9,2.5-11.1,4.4-15.6c1.9-4.4,4.2-8,6.9-10.6
      c2.7-2.7,5.8-4,9.2-4c3,0,5.7,1.1,8,3.3c2.3,2.2,4.2,5,5.8,8.4c1.6,3.4,2.8,7,3.6,11c0.8,4,1.2,7.8,1.2,11.6c0,3.4-0.3,6-0.8,8
      c-0.5,1.9-1.6,3.4-3.2,4.4c-1.6,1-3.8,1.5-6.5,1.7c-2.7,0.2-6.2,0.3-10.5,0.3h-19.4C601.4,106.3,601.8,100.1,602.9,94.2z"
    />
    <path
      d="M825.1,132.4c-4.5-3.9-9.7-7.3-15.7-10.2c-5.9-2.9-12-5.8-18.4-8.5c-6.3-2.7-12.5-5.6-18.7-8.5
      c-6.2-2.9-11.8-6.4-16.8-10.5c-2.7-2.1-4.4-5-5.3-8.6c-0.9-3.6-0.7-7.1,0.5-10.5c1.2-3.4,3.6-6.2,6.9-8.5c3.4-2.3,7.9-3.2,13.6-2.7
      c4.3,0.4,7.4,1.5,9.6,3.6c2.1,2,3.8,4.5,5,7.3c1.2,2.8,2.3,5.8,3.1,9c0.8,3.2,2.1,6.2,3.9,8.9c1.8,2.7,4.2,5,7.3,6.8
      c3.1,1.8,7.6,2.7,13.4,2.7c4.4,0,8.2-1.1,11.2-3.5c3-2.3,5-5.2,6-8.7c1-3.5,0.7-7.2-0.8-11.3c-1.5-4.1-4.6-7.8-9.2-11.3
      c-4.6-3.5-10.9-6.3-19-8.7c-8.1-2.3-18.2-3.5-30.5-3.5c-9.7,0-18.7,1.2-26.8,3.5c-8.2,2.3-15.2,5.6-21,9.8
      c-5.8,4.3-10.4,9.4-13.6,15.3c-3.2,5.9-4.8,12.5-4.8,19.8c0,8.2,1.6,15.1,4.7,20.7c3.1,5.7,7.1,10.5,12.1,14.4
      c5,3.9,10.5,7.2,16.5,10c6,2.7,12,5.4,17.8,8c5.9,2.6,11.3,5.2,16.2,7.8c5,2.7,8.8,5.9,11.4,9.8c1.9,2.8,3.1,6,3.5,9.6
      c0.4,3.6-0.2,6.9-1.7,10.1c-1.5,3.2-3.8,5.9-6.9,8.1c-3.1,2.2-7.1,3.3-12.1,3.3c-5.1,0-9-1.2-11.7-3.5c-2.7-2.3-4.8-5.1-6.5-8.5
      c-1.7-3.4-3.1-7-4.1-11c-1.1-4-2.5-7.7-4.3-11s-4.2-6.1-7.2-8.2c-3-2.1-7.3-3.1-12.8-2.9c-5,0.2-9,1.6-12,4.4
      c-3,2.7-4.8,6.1-5.5,10c-0.6,3.9,0.1,8.2,2.1,12.8c2,4.6,5.6,8.9,10.6,12.8c5.1,3.9,11.8,7.1,20.2,9.7c8.4,2.6,18.7,3.9,31,3.9
      c13.8,0,25.3-1.5,34.4-4.5c9.1-3,16.4-6.9,21.8-11.6c5.4-4.7,9.3-9.9,11.7-15.6c2.4-5.7,3.8-11.2,4.1-16.5
      c0.5-7.8-0.4-14.4-2.9-19.8C833.1,140.9,829.6,136.3,825.1,132.4z"
    />
    <path
      d="M920.5,169.5c-1.7-4-4-7.4-6.9-10.4c-2.9-2.9-6.4-5.2-10.4-6.9c-4-1.7-8.2-2.5-12.6-2.5
      c-4.4,0-8.6,0.8-12.5,2.5c-3.9,1.7-7.3,4-10.2,6.9c-2.9,2.9-5.2,6.4-6.9,10.4c-1.7,4-2.5,8.2-2.5,12.6c0,4.4,0.8,8.6,2.5,12.5
      c1.7,3.9,4,7.3,6.9,10.2c2.9,2.9,6.3,5.2,10.2,6.9c3.9,1.7,8.1,2.5,12.5,2.5c4.4,0,8.6-0.8,12.6-2.5c4-1.7,7.4-4,10.4-6.9
      c2.9-2.9,5.2-6.3,6.9-10.2c1.7-3.9,2.5-8.1,2.5-12.5C923,177.7,922.2,173.4,920.5,169.5z"
    />
    <path
      d="M251.8,211.2c0-1.2-0.4-2-1.3-2.5c-0.9-0.5-2.1-0.9-3.7-1.2c-6.8-1-12-5.8-15.4-14.2l-17.5-40.4
      c9.8-3.4,17.7-8.5,23.7-15.5c6-7,9-16.8,9-29.5c0-10.6-2.7-19.3-8.1-26c-5.4-6.7-12.5-12-21.3-15.8s-18.8-6.5-30-8
      s-22.5-2.2-34-2.2H82c-2.8,0.2-4.2,1.2-4.2,3.3c0,1.6,1.7,2.8,5,3.5c3.1,0.7,5.1,2.3,5.8,4.7c0.7,2.4,1,5.7,1,9.9v115.6v3.5
      c-0.2,3.2-0.7,5.7-1.6,7.5c-0.9,1.8-2.6,3-5.2,3.6c-1.6,0.3-2.8,0.7-3.7,1.2c-0.9,0.5-1.3,1.3-1.3,2.5c0,1.3,0.5,2.2,1.6,2.6
      c0.7,0.3,2,0.4,3.9,0.4c3.1-0.3,7.6-0.5,13.5-0.7c5.8-0.1,13.2-0.2,22.1-0.2s16.3,0.1,22.2,0.2c5.9,0.2,10.5,0.4,13.6,0.7
      c3.7,0.3,5.5-0.7,5.5-3.1c0-1.2-0.4-2-1.3-2.5c-0.9-0.5-2.1-0.9-3.7-1.2c-3-0.7-4.8-2.2-5.6-4.5c-0.8-2.3-1.2-5.5-1.2-9.7v-33.7
      h4.7h7.1c2.3,0,4.5-0.1,6.8-0.2l9.7,33.9c1,3.8,1.8,6.7,2.4,8.7c0.5,2,0.3,3.6-0.8,4.8c-0.9,1-1.7,1.8-2.5,2.3
      c-0.8,0.5-1.2,1.2-1.2,2.1c0,2.3,1.8,3.4,5.5,3.1c1.4-0.1,2.8-0.3,4.2-0.3c1.4-0.1,3.1-0.1,5.1-0.2c2-0.1,4.4-0.2,7.2-0.2
      c2.8-0.1,6.3-0.1,10.5-0.1c9.1,0,17.1,0.1,24.1,0.2c7,0.2,12,0.4,15.2,0.7C250,214.5,251.8,213.5,251.8,211.2z M178.4,138.5
      c-5.3,6.8-13.7,10.2-25.3,10.2h-4.7v-82h4.7c9.8,0,17.8,3.2,23.9,9.6c6.2,6.4,9.3,16.9,9.3,31.5
      C186.4,121.5,183.7,131.7,178.4,138.5z"
    />
    <path
      d="M397.6,75.3c1.8-2.2,3.7-4.1,5.7-5.6c2-1.5,4.2-2.3,6.5-2.5c2.7-0.4,4-1.9,4-4.5c0-2.7-1.9-3.8-5.6-3.5
      c-2.5,0.2-5.3,0.4-8.5,0.7c-3.2,0.3-6.8,0.4-10.9,0.4c-3,0-6.7-0.1-11-0.3c-4.3-0.2-8-0.4-11-0.8c-3.7,0-5.6,1.2-5.6,3.7
      c0,2.7,1.8,4.2,5.3,4.5c3,0.2,5.2,1.1,6.6,2.7c1.4,1.6,2.3,3.5,2.5,5.7c0.3,2.2,0.1,4.7-0.5,7.6c-0.6,2.8-1.5,5.7-2.8,8.5
      l-23.1,57.7l-23.7-64.9c-2.1-5.3-3.1-9.3-2.9-12c0.2-2.7,1.8-4.4,4.8-5.3c3.4-0.9,5.1-2.5,5.1-4.8c0-2.7-1.9-3.8-5.6-3.5
      c-3.2,0.4-7.8,0.6-13.8,0.8s-13.6,0.3-22.6,0.3c-9,0-16.6-0.1-22.6-0.3c-6-0.2-10.6-0.4-13.8-0.8c-3.7-0.3-5.6,0.8-5.6,3.5
      c0,1.4,0.4,2.4,1.3,3.1s2.1,1.2,3.7,1.7c1.6,0.5,2.9,1.1,3.9,1.9c1,0.7,1.9,1.7,2.7,3.1c0.8,1.3,1.6,3,2.5,4.9
      c0.9,1.9,1.9,4.4,3.2,7.4l55.1,120.6c-0.1,0-0.1,0.1-0.2,0.1c-2.5,0.8-5.4,1.2-8.7,1c-2.3-0.2-4.5-0.8-6.5-2
      c-2-1.2-4.1-2.4-6.2-3.9c-2.1-1.4-4.4-2.7-6.9-3.9c-2.5-1.1-5.2-1.7-8.2-1.7c-1.6,0-3.3,0.2-5.1,0.5c-1.8,0.4-3.6,1-5.6,1.9
      c-5.7,2.5-9.3,6.3-10.9,11.6s-1.1,10.3,1.3,15.3c2.5,5,7,9.2,13.6,12.6c6.6,3.5,15.1,4.7,25.5,3.9c7.3-0.7,13.5-2.7,18.6-6.1
      c1.6-1.1,3.2-2.2,4.6-3.4c0.1-0.1,0.1-0.1,0.2-0.2c1.4-1.2,2.8-2.4,4.1-3.7c0.1-0.1,0.1-0.1,0.2-0.2l6.4-9.2c0,0,0.1-0.1,0.1-0.1
      l-6.5,9.4c0.1-0.1,0.2-0.2,0.3-0.4c0.8-0.9,1.6-1.8,2.3-2.7c2.3-2.8,4.2-5.7,5.9-8.5c0.6-1.1,1.2-2.1,1.8-3.1
      c0-0.1,0.1-0.2,0.1-0.3c0.4-0.7,0.7-1.3,1-2c1-2,1.8-3.7,2.3-4.9c0.3-0.7,0.6-1.4,0.9-2.2c0,0,0,0,0,0l0,0c0.2-0.5,0.4-0.9,0.6-1.4
      c1.8-4.4,3.8-9.5,6.1-15.4c2.3-5.9,4.8-12.4,7.6-19.4c2.7-7,5.5-14,8.4-20.9c6.4-16.3,13.5-34.3,21.3-54c1.1-3,2.3-6,3.7-8.9
      C394.2,80.1,395.8,77.5,397.6,75.3z"
    />
    <path
      d="M118.2,259.9c-2.8-3.5-6.7-5.3-11.7-5.3c-4.6,0-8.4,1.6-11.4,4.8c-3,3.2-4.4,7.3-4.4,12c0,5,1.5,9,4.4,12.2
      c3,3.2,7,4.8,12.2,4.8c4,0,7.2-1,9.8-3c2.5-2,4.1-4.5,4.8-7.5h-8.4c-1,2.6-3.1,3.9-6.2,3.9c-2.4,0-4.3-0.7-5.6-2.1
      c-1.4-1.4-2.2-3.4-2.6-5.9h23.3C122.3,268.1,120.9,263.4,118.2,259.9z M99.1,268.1c0.8-4.6,3.3-6.9,7.4-6.9c1.9,0,3.5,0.6,4.8,1.9
      c1.3,1.3,1.9,2.9,2.1,5H99.1z"
    />
    <polygon
      points="163.2,255.5 154.3,255.5 149,264.7 148.8,264.7 143.1,255.5 133.4,255.5 143.9,270.6 132.2,287.6
      141.5,287.6 148.5,277 148.6,277 154.5,287.6 164.7,287.6 153.2,270.5"
    />
    <path
      d="M190.5,281.7c-2.5,0-4.4-0.9-5.7-2.7c-1.3-1.8-1.9-4.3-1.9-7.4c0-3.2,0.7-5.7,2-7.4c1.3-1.8,3.3-2.7,5.8-2.7
      c1.5,0,2.8,0.5,3.9,1.4c1,0.9,1.7,2.1,2,3.6h8.3c-0.6-3.6-2.1-6.4-4.7-8.6c-2.5-2.1-5.8-3.2-9.9-3.2c-4.8,0-8.7,1.6-11.6,4.8
      c-2.9,3.2-4.4,7.3-4.4,12.1c0,4.9,1.5,8.9,4.5,12.1c3,3.2,7,4.8,12,4.8c4.2,0,7.6-1.2,10.1-3.5c2.6-2.3,4-5.2,4.3-8.8H197
      C196.4,279.9,194.2,281.7,190.5,281.7z"
    />
    <rect x="219" y="242.9" width="8.5" height="44.7" />
    <path
      d="M263.2,274c0,2.2-0.6,3.9-1.8,5.3c-1.2,1.3-2.7,2-4.7,2c-3.5,0-5.3-1.9-5.3-5.8v-20H243v21.4
      c0,3.5,1,6.4,2.9,8.4c2,2.1,4.7,3.1,8.2,3.1c2.2,0,4-0.4,5.4-1.1c1.4-0.8,2.7-1.9,3.8-3.5h0.2v3.8h8.2v-32.1h-8.5V274z"
    />
    <path
      d="M311.5,271.8c-0.6-0.6-1.5-1.2-2.7-1.8c-1.1-0.5-2.1-0.9-3-1.1c-0.9-0.2-2-0.5-3.3-0.8
      c-0.5-0.1-1.1-0.2-1.9-0.3c-0.8-0.1-1.4-0.3-1.9-0.4c-0.5-0.1-1-0.2-1.6-0.4c-0.6-0.1-1.1-0.3-1.4-0.4c-0.3-0.1-0.7-0.3-1-0.6
      c-0.4-0.2-0.6-0.5-0.8-0.8c-0.1-0.3-0.2-0.7-0.2-1c0-1.1,0.5-2,1.5-2.6c1-0.6,2.3-0.9,4-0.9c3.8,0,5.9,1.6,6.2,4.7h7.7
      c-0.3-3.3-1.7-5.9-4-7.8c-2.4-1.9-5.6-2.8-9.7-2.8c-3.8,0-7,0.9-9.4,2.6c-2.4,1.8-3.7,4.3-3.7,7.6c0,1.7,0.3,3.1,0.9,4.3
      c0.6,1.2,1.5,2.1,2.7,2.8c1.2,0.7,2.4,1.2,3.5,1.6c1.1,0.4,2.5,0.7,4.3,1c0.3,0.1,0.9,0.2,1.8,0.3c0.9,0.1,1.5,0.3,1.8,0.3
      c0.4,0.1,0.9,0.2,1.5,0.4c0.7,0.2,1.1,0.3,1.4,0.5c0.3,0.2,0.6,0.4,0.9,0.7c0.4,0.3,0.6,0.6,0.8,0.9c0.1,0.3,0.2,0.7,0.2,1.1
      c0,1.1-0.5,2-1.5,2.7c-1,0.7-2.5,1-4.4,1c-4.1,0-6.5-1.8-7-5.3h-7.9c0.3,3.5,1.7,6.3,4.3,8.3c2.6,2,6.2,3,10.8,3
      c4,0,7.3-0.9,9.8-2.8c2.5-1.9,3.7-4.4,3.7-7.5c0-1.5-0.2-2.7-0.7-3.8C312.7,273.3,312.1,272.4,311.5,271.8z"
    />
    <rect x="327.8" y="242.9" width="8.5" height="7.6" />
    <rect x="327.8" y="255.5" width="8.5" height="32.1" />
    <path
      d="M365.9,272.2c-0.6,2-1.1,3.8-1.4,5.1h-0.1l-1.4-5.1l-5.1-16.6h-8.6l11.1,32.1h8.1l11.2-32.1h-8.5L365.9,272.2z
      "
    />
    <path
      d="M417.7,259.9c-2.8-3.5-6.7-5.3-11.7-5.3c-4.6,0-8.4,1.6-11.4,4.8c-3,3.2-4.4,7.3-4.4,12c0,5,1.5,9,4.4,12.2
      c3,3.2,7,4.8,12.2,4.8c4,0,7.2-1,9.8-3c2.5-2,4.1-4.5,4.8-7.5h-8.4c-1,2.6-3.1,3.9-6.2,3.9c-2.4,0-4.3-0.7-5.6-2.1
      c-1.4-1.4-2.2-3.4-2.6-5.9h23.3C421.8,268.1,420.4,263.4,417.7,259.9z M398.7,268.1c0.8-4.6,3.3-6.9,7.4-6.9c1.9,0,3.5,0.6,4.8,1.9
      c1.3,1.3,1.9,2.9,2.1,5H398.7z"
    />
    <path
      d="M480.6,271.8c-0.6-0.6-1.5-1.2-2.7-1.8c-1.1-0.5-2.1-0.9-3-1.1c-0.9-0.2-1.9-0.5-3.3-0.8
      c-0.5-0.1-1.1-0.2-1.9-0.3c-0.8-0.1-1.4-0.3-1.9-0.4c-0.5-0.1-1-0.2-1.6-0.4c-0.6-0.1-1.1-0.3-1.4-0.4c-0.3-0.1-0.7-0.3-1-0.6
      c-0.4-0.2-0.6-0.5-0.8-0.8c-0.1-0.3-0.2-0.7-0.2-1c0-1.1,0.5-2,1.5-2.6c1-0.6,2.3-0.9,4-0.9c3.8,0,5.9,1.6,6.2,4.7h7.7
      c-0.3-3.3-1.7-5.9-4-7.8c-2.4-1.9-5.6-2.8-9.7-2.8c-3.8,0-7,0.9-9.4,2.6c-2.4,1.8-3.7,4.3-3.7,7.6c0,1.7,0.3,3.1,0.9,4.3
      c0.6,1.2,1.5,2.1,2.7,2.8c1.2,0.7,2.4,1.2,3.5,1.6c1.1,0.4,2.5,0.7,4.3,1c0.3,0.1,0.9,0.2,1.8,0.3c0.9,0.1,1.5,0.3,1.8,0.3
      c0.4,0.1,0.9,0.2,1.5,0.4c0.7,0.2,1.1,0.3,1.4,0.5c0.3,0.2,0.6,0.4,0.9,0.7c0.4,0.3,0.6,0.6,0.8,0.9c0.1,0.3,0.2,0.7,0.2,1.1
      c0,1.1-0.5,2-1.5,2.7c-1,0.7-2.5,1-4.3,1c-4.1,0-6.5-1.8-7-5.3h-7.9c0.3,3.5,1.7,6.3,4.3,8.3c2.6,2,6.2,3,10.8,3
      c4,0,7.3-0.9,9.8-2.8c2.5-1.9,3.7-4.4,3.7-7.5c0-1.5-0.2-2.7-0.7-3.8C481.8,273.3,481.2,272.4,480.6,271.8z"
    />
    <path
      d="M507.1,278.2v-17h5.5v-5.6h-5.5v-10h-8.3v10h-4.4v5.6h4.4V280c0,2.8,0.8,4.7,2.5,5.9c1.7,1.2,3.7,1.8,6.2,1.8
      c2.3,0,4,0,5.3-0.1v-6.3l-2.5,0.1C508.2,281.4,507.1,280.3,507.1,278.2z"
    />
    <path
      d="M538.8,256.5c-1.2,0.9-2.3,2.3-3.3,4.2h-0.2v-5.1h-8.2v32.1h8.5V272c0-3.3,0.9-5.7,2.6-7.3
      c1.8-1.5,4.1-2.2,6.9-1.9h0.2v-7.4c-0.5-0.2-1.1-0.3-2-0.3C541.5,255.1,540,255.6,538.8,256.5z"
    />
    <path
      d="M584.2,259.9c-2.8-3.5-6.7-5.3-11.7-5.3c-4.6,0-8.4,1.6-11.4,4.8c-3,3.2-4.4,7.3-4.4,12c0,5,1.5,9,4.4,12.2
      c3,3.2,7,4.8,12.2,4.8c4,0,7.2-1,9.8-3c2.5-2,4.1-4.5,4.8-7.5h-8.4c-1,2.6-3.1,3.9-6.2,3.9c-2.4,0-4.3-0.7-5.6-2.1
      c-1.4-1.4-2.2-3.4-2.6-5.9h23.3C588.3,268.1,586.9,263.4,584.2,259.9z M565.1,268.1c0.8-4.6,3.3-6.9,7.4-6.9c1.9,0,3.5,0.6,4.8,1.9
      c1.3,1.3,1.9,2.9,2.1,5H565.1z"
    />
    <path
      d="M628.3,259.9c-2.8-3.5-6.7-5.3-11.7-5.3c-4.6,0-8.4,1.6-11.4,4.8c-3,3.2-4.4,7.3-4.4,12c0,5,1.5,9,4.4,12.2
      c3,3.2,7,4.8,12.2,4.8c4,0,7.2-1,9.8-3c2.5-2,4.1-4.5,4.8-7.5h-8.4c-1,2.6-3.1,3.9-6.2,3.9c-2.4,0-4.3-0.7-5.6-2.1
      c-1.4-1.4-2.2-3.4-2.6-5.9h23.3C632.4,268.1,631,263.4,628.3,259.9z M609.3,268.1c0.8-4.6,3.3-6.9,7.4-6.9c1.9,0,3.5,0.6,4.8,1.9
      c1.3,1.3,1.9,2.9,2.1,5H609.3z"
    />
    <path
      d="M656.7,278.2v-17h5.5v-5.6h-5.5v-10h-8.3v10h-4.4v5.6h4.4V280c0,2.8,0.8,4.7,2.5,5.9c1.7,1.2,3.7,1.8,6.2,1.8
      c2.3,0,4,0,5.3-0.1v-6.3l-2.5,0.1C657.8,281.4,656.7,280.3,656.7,278.2z"
    />
    <path
      d="M708.9,270.4c-0.5,1.8-0.9,3.8-1.4,6h-0.1l-1.5-6.1l-4.2-14.8h-7.3l-4,14.8c-0.5,1.8-0.9,3.8-1.4,6.1h-0.1
      l-1.4-6l-3.9-14.9h-8.4l9.4,32.1h7.7l4.4-15.8c0.5-1.7,1-3.6,1.5-5.6h0.1l1.4,5.6l4.3,15.8h7.8l9.6-32.1H713L708.9,270.4z"
    />
    <path
      d="M759.3,259.9c-2.8-3.5-6.7-5.3-11.7-5.3c-4.6,0-8.4,1.6-11.4,4.8c-3,3.2-4.4,7.3-4.4,12c0,5,1.5,9,4.4,12.2
      c3,3.2,7,4.8,12.2,4.8c4,0,7.2-1,9.8-3c2.5-2,4.1-4.5,4.8-7.5h-8.4c-1,2.6-3.1,3.9-6.2,3.9c-2.4,0-4.3-0.7-5.6-2.1
      c-1.4-1.4-2.2-3.4-2.6-5.9h23.3C763.5,268.1,762.1,263.4,759.3,259.9z M740.3,268.1c0.8-4.6,3.3-6.9,7.4-6.9c1.9,0,3.5,0.6,4.8,1.9
      c1.3,1.3,1.9,2.9,2.1,5H740.3z"
    />
    <path
      d="M804.8,266.5c0-7.9-4.5-11.8-13.5-11.8c-4.4,0-7.8,1-10.1,3c-2.3,2-3.6,4.5-3.7,7.6h7.6c0.3-3,2.3-4.5,6.2-4.5
      c1.7,0,3.1,0.3,4,1c0.9,0.7,1.4,1.6,1.4,2.8c0,1.1-0.5,1.9-1.6,2.3c-1.1,0.4-3.1,0.9-6.1,1.3c-4.1,0.6-7.3,1.6-9.5,3.2
      c-2.3,1.5-3.4,3.9-3.4,7.2c0,3.1,1,5.5,3.1,7.3c2,1.7,4.8,2.6,8.1,2.6c2.5,0,4.5-0.4,5.9-1.1c1.4-0.8,2.6-1.8,3.5-3.2h0.1
      c0.3,1.8,0.6,3,1,3.6h8.2v-0.3c-0.8-0.5-1.1-2-1.1-4.4V266.5z M796.8,276.3c0,1.7-0.7,3.1-2,4.3c-1.4,1.2-3.2,1.8-5.5,1.8
      c-3.3,0-5-1.4-5-4.1c0-1.4,0.5-2.5,1.5-3.2c1-0.7,2.7-1.3,5.1-1.8c3-0.7,5-1.3,5.9-1.9V276.3z"
    />
    <path
      d="M831.9,256.5c-1.2,0.9-2.3,2.3-3.3,4.2h-0.2v-5.1h-8.2v32.1h8.5V272c0-3.3,0.9-5.7,2.6-7.3
      c1.8-1.5,4.1-2.2,6.9-1.9h0.2v-7.4c-0.5-0.2-1.1-0.3-2-0.3C834.7,255.1,833.2,255.6,831.9,256.5z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Ryses'
    }
  }
}
</script>
