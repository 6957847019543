
export const state = () => ({
  selectedSize: null,
  showRatingModalAfterRedirectFormLoginPage: false
})

export const actions = {
  setSelectedSize ({ commit }, payload) {
    commit('SET_SELECTED_SIZE', payload)
  },
  resetSelectedSize ({ commit }) {
    commit('SET_SELECTED_SIZE', null)
  },
  setShowRatingModal ({ commit }, payload) {
    commit('SET_SHOW_RATING_MODAL', payload)
  }
}

export const mutations = {
  SET_SELECTED_SIZE (state, payload) {
    state.selectedSize = payload
  },
  SET_SHOW_RATING_MODAL (state, payload) {
    state.showRatingModalAfterRedirectFormLoginPage = payload
  }
}
