<template>
  <div
    class="c-delivery-banner"
    :class="{
      'visible': visible
    }"
    @touch.stop="openToolTips"
    @keydown.enter="openToolTips"
    @mouseenter="openToolTips"
    @mouseleave="closeToolTips"
  >
    <div
      class="c-delivery-banner__title"
    >
      <slot name="title" />
      <Explanation
        v-if="!!$slots.contentTooltips"
      />
    </div>
    <div
      v-if="!!$slots.contentTooltips && open"
      class="c-delivery-banner__content"
      @touch.stop="closeToolTips"
    >
      <DeliveryBannerTooltips @close="closeToolTips">
        <template #title>
          <slot name="titleTooltips" />
        </template>
        <template #content>
          <slot name="contentTooltips" />
        </template>
        <template #mentions>
          <slot name="mentionsTooltips" />
        </template>
      </DeliveryBannerTooltips>
    </div>
  </div>
</template>
<script>
import Explanation from '@/components/atoms/icons/Explanation.vue'
import DeliveryBannerTooltips from '@/components/molecules/banner/delivery-banner-tooltips/DeliveryBannerTooltips.vue'
export default {
  components: {
    Explanation,
    DeliveryBannerTooltips
  },

  data () {
    return {
      open: false,
      visible: false
    }
  },

  methods: {
    openToolTips () {
      this.open = true
      setTimeout(() => {
        this.visible = true
      }, 100)
    },

    closeToolTips () {
      this.visible = false
      setTimeout(() => {
        this.open = false
      }, 100)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-delivery-banner {
  $c: &;

  position: relative;

  &.visible {
    #{$c}__content {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  &__title {
    position: relative;
    display:flex;
    justify-content: center;
    padding: 0.5rem;
    background: $accent;
    color: $white;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      cursor: default;
    }

    &::v-deep > svg {
      margin-left: 1rem;
    }
  }

  &__content {
    position: absolute;
    z-index: 100;
    top: 100%;
    left: 50%;
    margin-top: 0.6rem;
    opacity: 0;
    transform: translate(-50%, 2rem);
    transition: all .1s ease;

    &::before {
      position: absolute;
      z-index: 2;
      top: -0.4rem;
      left: 50%;
      width: 0.7rem;
      height: 0.7rem;
      border-top: 0.1rem solid $border-1;
      border-right: 0.1rem solid $border-1;
      background: $grey-1400;
      content: "";
      transform: translateX(-50%) rotate(-45deg);
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 100%;
      left: 0;
      height: 2rem;
      background-color: transparent;
      content: '';
    }
  }

  @include mq($until: tablet) {
    &.visible {
      #{$c}__content {
        transform: translate(0, 0);
      }

      #{$c}__overlay {
        opacity: 1;
      }
    }

    &__content {
      position: fixed;
      top: 0;
      right: 0;
      bottom:0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10%;
      margin-top: 0;
      background-color: rgba($black, 0.7);
      transform: translate(0,0);

      &::before,
      &::after {
        display: none;
      }
    }
  }
}
</style>
