import DataStructure from './DataStructure'

export default class Wish extends DataStructure {
  constructor ({
    id = null,
    product = null
  }) {
    super()
    this.id = this.isset(id) ? this.convertToNumber(id) : null
    this.product = this.isset(product) ? this.convertToNumber(product) : null
  }

  getWishId () {
    return this.id
  }

  getWishProductId () {
    return this.product
  }
}
