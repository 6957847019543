export function getUiStateByPage (page, initialState = {}) {
  const state = Object.assign({
    skeleton: null,
    spacer: false,
    transparentHeader: false
  }, initialState)

  if (page.startsWith('index')) {
    state.skeleton = 'HOME'
    state.spacer = false
    state.transparentHeader = true
  } else if (page.startsWith('all')) {
    state.skeleton = 'LISTING'
    state.spacer = true
    state.transparentHeader = false
  } else if (page.startsWith('artists')) {
    state.skeleton = 'LISTING'
    state.spacer = true
    state.transparentHeader = false
  } else if (page.startsWith('p-all')) {
    state.skeleton = 'PRODUCT'
    state.spacer = true
    state.transparentHeader = false
  } else if (page.startsWith('search')) {
    state.skeleton = 'LISTING'
    state.spacer = true
    state.transparentHeader = false
  }

  return state
}

export function isPageFeaturingSkeleton (routeName) {
  const pagesFeaturedWithSkeleton = {
    HOME: 'index',
    LISTING: 'all',
    PRODUCT: 'p-all',
    ARTISTS: 'artists',
    SEARCH: 'search-query'
  }

  for (const [, value] of Object.entries(pagesFeaturedWithSkeleton)) {
    if (routeName.startsWith(value)) {
      return true
    }
  }

  return false
}

export function resetSkeletonStoreValues (store, valuesToReset = {
  UPDATE_SPACER: false,
  UPDATE_TRANSPARENT_HEADER: false,
  UPDATE_SKELETON: null,
  UPDATE_LOADING: false
}) {
  for (const [key, value] of Object.entries(valuesToReset)) {
    store.commit(`ui/${key}`, value)
  }
}

export function getSkeletonInitialState (store) {
  // Initiate default state ui constants
  return {
    initialSkeletonValue: store.state.ui.skeleton,
    initialSpacerValue: store.state.ui.spacer,
    initialTransparentHeaderValue: store.state.ui.transparentHeaderInitialValue ?? store.state.ui.transparentHeader
  }
}
