<template>
  <SaleBanner
    v-if="banner"
    :image-src="banner.image"
    :image-mobile-src="banner.imageMobile"
    :alt-text="altText"
    :link="link"
  />
</template>

<script>
import SaleBanner from './SaleBanner.vue'
export default {
  components: {
    SaleBanner
  },
  props: {
    banner: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      link: this.banner?.link ?? '',
      altText: this.banner?.altText ?? ''
    }
  },
  computed: {
    watchers () {
      return [this.$store.state.ui.deviceType, this.banner]
    }
  },
  watch: {
    watchers: {
      immediate: true,
      deep: true,
      handler ([deviceType, banner]) {
        if (deviceType && banner) {
          this.altText = deviceType === 'pc' ? this.banner?.altText : this.banner?.altTextMobile
          this.link = deviceType === 'pc' ? this.banner?.link : this.banner?.linkMobile
        }
      }
    }
  }
}
</script>
