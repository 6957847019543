<template>
  <div class="c-user-account" :class="{'c-user-account--mobile': mobile}">
    <div class="c-user-account__top">
      <div class="c-user-account__connect">
        <div v-if="loggedIn">
          <p>
            {{ $t('components.molecules.header.userAccount.connected') }} <ClientOnly>{{ username }}</ClientOnly>
          </p>
        </div>
        <div v-else>
          <nuxt-link :to="localePath('/login?mode=connection')">
            {{ $t('components.molecules.header.userAccount.connection') }}
          </nuxt-link>
          <nuxt-link :to="localePath('/login?mode=subscription')">
            {{ $t('components.molecules.header.userAccount.subscription') }}
          </nuxt-link>
        </div>
      </div>
      <button v-if="!mobile" class="c-user-account__close" @click="$emit('close', $event)">
        <CloseIcon fat :title="$t('components.molecules.header.userAccount.close')" />
      </button>
    </div>
    <ul class="c-user-account__list" @click="$emit('close')" @keypress.enter="$emit('close')">
      <li class="c-user-account__item">
        <nuxt-link :to="localePath('/account/history')">
          <OrderIcon />
          {{ $t('components.molecules.header.userAccount.orderTracking') }}
        </nuxt-link>
      </li>
      <li class="c-user-account__item">
        <nuxt-link :to="localePath('/account')">
          <AccountIcon />
          {{ $t('components.molecules.header.userAccount.profile') }}
        </nuxt-link>
      </li>
      <li class="c-user-account__item">
        <nuxt-link :to="localePath('/account/subscriptions')">
          <SubscriptionIcon />
          {{ $t('components.molecules.header.userAccount.subscriptions') }}
        </nuxt-link>
      </li>
      <li class="c-user-account__item">
        <nuxt-link :to="localePath('/contact')" data-cy="contactUs">
          <ContactIcon />
          {{ $t('components.molecules.header.userAccount.contactUs') }}
        </nuxt-link>
      </li>
      <li v-if="mobile" class="c-user-account__item">
        <nuxt-link :to="localePath(`/t/${CMS_SLUG[currentInterStore.id].faq}`)">
          <HelpIcon />
          {{ $t('components.molecules.header.userAccount.help') }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ContactIcon from '@/components/atoms/icons/Contact'
import AccountIcon from '@/components/atoms/icons/Account'
import OrderIcon from '@/components/atoms/icons/Order'
import SubscriptionIcon from '@/components/atoms/icons/Subscription'
import HelpIcon from '@/components/atoms/icons/Help'
import CloseIcon from '@/components/atoms/icons/Close'
import { CMS_SLUG } from '@/config/cms/config'

export default {
  components: { ContactIcon, AccountIcon, OrderIcon, SubscriptionIcon, HelpIcon, CloseIcon },
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      CMS_SLUG
    }
  },
  computed: {
    ...mapState({
      loggedIn: state => state.auth?.loggedIn,
      username: state => state.auth?.user && (state.auth.user.firstName + ' ' + state.auth.user.lastName),
      currentInterStore: state => state.international.currentInterStore
    })
  }
}

</script>

<style lang="scss" scoped>
.c-user-account {
  $c: &;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 3.2rem 2.2rem 3rem;
    background-color: $grey-200;
    color: $white;
  }

  &__connect {
    > div {
      display: flex;
      align-items: center;
    }

    a {
      position: relative;
      display: block;
      color: $white;
      font-size: $font-size-sm;
      font-weight: 500;

      &:nth-child(odd) {
        padding-right: 2.4rem;
      }

      &:nth-child(even) {
        padding-left: 2.4rem;
      }

      &:first-child {
        &::after {
          position: absolute;
          top: -0.5rem;
          right: 0;
          bottom: -0.5rem;
          width: 0.1rem;
          background-color: $white;
          content: "";
        }
      }
    }

    p {
      margin: 0;
      font-size: $font-size-sm;
      font-weight: 500;
      letter-spacing: 0.13rem;
    }
  }

  &__close {
    width: 1.3rem;
    height: 1.3rem;
    padding: 0;
    border: none;
    background-color: transparent;
    color: $white;

    svg {
      display: block;
      width: 1.3rem;
      height: 1.3rem;
      fill: $white;
    }
  }

  &__list {
    padding: 0 3rem;
    border: 0.1rem solid $grey-800;
    border-top: none;
  }

  &__item {
    &:not(:last-child) {
      border-bottom: 0.1rem solid $grey-800;
    }

    > a {
      display: flex;
      align-items: center;
      padding: 2rem 0.3rem;
      margin: 0;
      color: $grey-300;
      font-size: $font-size-sm;
      font-weight: 500;
      text-decoration: none;
      text-transform: uppercase;
      transition: $transition;

      > svg {
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 2.4rem;
        fill: currentcolor;
      }

      &:hover {
        color: $primary-dark;
      }
    }
    &#{$c}__item--help {
      display: none;

      @include mq($until: tablet) {
        display: block;
      }
    }
  }

  &--mobile {
    #{$c}__top {
      padding: 0;
    }

    #{$c}__connect {
      width: 100%;

      > div {
        a,
        p {
          color: $white;
          font-size: $font-size-sm-mobile;
          font-weight: 900;
          text-transform: uppercase;
        }

        a {
          width: 50%;
          padding: 2.3rem 1rem !important;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;

          &::after {
            display: none;
          }

          &:first-child {
            border-right: 0.1rem solid $white;
          }
        }

        p {
          padding: 2.4rem 2rem;
        }
      }
    }
    #{$c}__list {
      padding: 0;
    }
    #{$c}__item {
      > a {
        padding: 1rem 2.1rem;
        font-size: $font-size-sm-mobile;
        font-weight: 400;

        > svg {
          width: 1.6rem;
          margin-right: 1.7rem;
        }
      }
    }
  }
}
</style>
