var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item && _vm.item.slugs)?_c('a',{staticClass:"c-product-thumbnail",attrs:{"href":_vm.item.slugs[_vm.$i18n.locale],"data-id":_vm.item.productID},on:{"click":function($event){$event.preventDefault();return _vm.click.apply(null, arguments)}}},[_c('picture',{staticClass:"c-product-thumbnail__img-wrapper"},[_c('source',{attrs:{"media":"(min-width:63.99em)","srcset":_vm.getPictureURL('S'),"alt":`${_vm.item.displayInformation.title} - ${_vm.item.names[_vm.$i18n.locale]}`}}),_vm._v(" "),(_vm.image && _vm.item.names)?_c('img',{attrs:{"src":_vm.getPictureURL('S'),"alt":`${_vm.item.displayInformation.title} - ${_vm.item.names[_vm.$i18n.locale]}`,"width":_vm.imgSize,"height":_vm.imgSize}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"c-product-thumbnail__info-wrapper"},[_c('div',{staticClass:"c-product-thumbnail__tag-wrapper"},[(
          !!(
            (_vm.item.label === 'promo' || _vm.item.label === 'flash_sale') &&
            _vm.item.promotion.percent
          )
        )?_c('Sticker',{attrs:{"promo":true}},[_vm._v("\n        -"+_vm._s(_vm.item.promotion.percent)+"%\n      ")]):_vm._e(),_vm._v(" "),(!_vm.noTag && _vm.item.label === 'top_sale')?_c('Sticker',{attrs:{"white":true}},[_vm._v(_vm._s(_vm.$t('components.molecules.listing.productThumbnail.top')))]):_vm._e(),_vm._v(" "),(!_vm.noTag && _vm.item.label === 'new')?_c('Sticker',[_vm._v(_vm._s(_vm.$t('components.molecules.listing.productThumbnail.new')))]):_vm._e(),_vm._v(" "),(_vm.displayMarketLabel)?_c('Sticker',{attrs:{"eco-resp":_vm.marketLabel.class === 'eco_friendly',"exclu":_vm.marketLabel.class === 'exclusivity'}},[_vm._v("\n        "+_vm._s(_vm.marketLabel.name)+"\n      ")]):_vm._e()],1),_vm._v(" "),_c('FavorisBtn',{class:{ 'c-btn-favoris--inactive': !_vm.hydrationCompleted },attrs:{"product-id":_vm.item.productID,"small":""},on:{"setProductDataForTrackingAfterLogin":_vm.setProductDataForTrackingAfterLogin,"addToWishList":function($event){return _vm.handleTrackingAddRemoveToWishlist('lbo_add_to_wishlist')},"removeToWishList":function($event){return _vm.handleTrackingAddRemoveToWishlist('lbo_remove_from_wishlist')}}}),_vm._v(" "),_c('div',{staticClass:"c-product-thumbnail__info"},[_c('div',{staticClass:"c-product-thumbnail__titleWrapper"},[_c('div',{staticClass:"h5-like c-product-thumbnail__title"},[_vm._v("\n          "+_vm._s(_vm.item.displayInformation.title)+"\n        ")])]),_vm._v(" "),(_vm.item.names)?_c('p',{staticClass:"c-product-thumbnail__desc",class:{
          'c-product-thumbnail__desc--with-free-shipping': _vm.freeShipping,
        }},[_vm._v("\n        "+_vm._s(_vm.item.names[_vm.$i18n.locale])+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.freeShipping)?_c('div',{staticClass:"c-product-thumbnail__free-shipping"},[_vm._v("\n        "+_vm._s(_vm.$t('components.molecules.listing.productThumbnail.freeShipping'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('Price',{staticClass:"c-product-thumbnail__price",attrs:{"price":_vm.item.prices[_vm.countryCodeLc].price || _vm.item.prices.default.price,"old":_vm.item.prices[_vm.countryCodeLc].originalPrice ||
          _vm.item.prices.default.originalPrice,"small":""}})],1),_vm._v(" "),_c('div',{staticClass:"c-product-thumbnail__size"},[(!_vm.item.sizes)?[_c('span',[_vm._v(_vm._s(_vm.$t('components.molecules.listing.productThumbnail.uniqueSize')))])]:(_vm.item.sizes && _vm.item.sizes.length > 7)?[_c('span',[_vm._v(_vm._s(_vm.$t(
            'components.molecules.listing.productThumbnail.severalSizesAvailable',
          )))])]:_vm._l((_vm.item.sizes),function(size){return _c('button',{key:size.size,on:{"click":function($event){return _vm.setSelectedSize(size)}}},[_vm._v("\n          "+_vm._s(size.size)+"\n        ")])})],2)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }