<template>
  <div
    class="c-skeleton"
  >
    <div
      v-if="spacer"
      class="u-space-header"
      :class="{'u-space-header--inter': isInternationalDisplay}"
    />
    <component
      :is="componentsToLoad"
    />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import SkeletonHome from '@/components/organisms/skeleton/page/SkeletonHome.vue'
import SkeletonListing from '@/components/organisms/skeleton/page/SkeletonListing.vue'
import SkeletonProduct from '@/components/organisms/skeleton/page/SkeletonProduct.vue'
export default {
  components: {
    HOME: SkeletonHome,
    LISTING: SkeletonListing,
    PRODUCT: SkeletonProduct
  },
  computed: {
    ...mapGetters({ isInternationalDisplay: 'international/isInternationalDisplay' }),
    ...mapState({
      componentsToLoad: state => state.ui.skeleton,
      spacer: state => state.ui.spacer
    })
  },
  mounted () {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}
</script>
