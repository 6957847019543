import {
  createBasketDetail as createBasketDetailApi,
  getCarrierFreeShipping as getCarrierFreeShippingApi
} from '@/api/checkout'

import Basket from '@/models/Basket'

export const createBasketDetail = ({
  basketItems,
  basketPromotion,
  countryIsoCode,
  device,
  headers,
  instance
} = {}) => {
  const basketObject = new Basket({
    basketItems,
    basketPromotion,
    countryIsoCode,
    device
  })
  return createBasketDetailApi({ basketObject: basketObject.getApiObject(), headers, instance })
}

export const getCarrierFreeShipping = ({ headers, axios } = {}) => getCarrierFreeShippingApi({ headers, instance: axios })
