import {
  getTemplatesItems as getTemplatesItemsApi
} from '@/api/template'

export const getTemplatesItems = ({
  slug,
  instance,
  headers
} = {}) => {
  return getTemplatesItemsApi({
    keys: slug,
    headers,
    instance
  })
}
