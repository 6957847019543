import { api } from '../instance'

export const getWishList = (options = {}) => {
  const { headers, instance } = options
  return (instance || api).get(
    '/secure/wishes',
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const createWish = (options = {}) => {
  const { productId, headers, instance } = options
  return (instance || api).post(
    '/secure/wishes',
    { product: productId },
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const deleteWish = (options = {}) => {
  const { wishId, headers, instance } = options
  return (instance || api).delete(
    `/secure/wishes/${wishId}`,
    {},
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}
