<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22.1 20"
    width="18"
    height="16"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <path d="M22 4.3L20 .4c-.2-.2-.5-.4-.8-.4H3a1 1 0 0 0-.9.5l-2 3.9-.1.4v14.4c0 .4.4.8.9.8h20.3c.5 0 .9-.4.9-.9V4.8l-.1-.5zm-2.3-.4H12v-2h6.7l1 2zM3.5 1.8h6.7v2H2.4l1.1-2zm16.8 16.4H1.8V5.7h18.5v12.5z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
