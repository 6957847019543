<template>
  <ProductList
    v-if="products"
    :title="$t('components.organisms.home.topSales.title')"
    :products="products"
    :listing-type="listingType"
    no-tag
    no-market-label
  >
    <VButton
      type="link"
      :to="templatePage ? templatePage.url.path : BTN_LINK[$i18n.locale]"
      red-hover
    >
      {{ templatePage ? templatePage.brand ? $t('pages.templatePage.topSale', {templatePageBrand: templatePage.brand.name}) : $t('components.organisms.home.topSales.all') : $t('components.organisms.home.topSales.all') }}
    </VButton>
  </ProductList>
</template>

<script>
import { BTN_LINK } from '@/config.js'

import ProductList from '@/components/molecules/listing/product-list/ProductList'
import VButton from '@/components/atoms/global/button/VButton'

export default {
  components: {
    ProductList,
    VButton
  },
  props: {
    listingType: {
      type: String,
      default: ''
    },
    products: {
      type: Array,
      default: null
    },
    templatePage: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      BTN_LINK: BTN_LINK.TOP_SALES
    }
  }
}
</script>

<style lang="scss" scoped>
.c-product-list {
  $c: &;

  padding: 2.2rem;
  margin-bottom: 5rem;
  text-align: center;

  &:empty {
    display: none;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 3rem -1.1rem 1rem;
    text-align: left;

    > * {
      display: flex;
      padding-right: 1.1rem;
      padding-left: 1.1rem;
    }
  }

  @include mq($until: tablet) {
    padding: 0 0.5rem !important;

    &__list {
      margin: 2.6rem -0.25rem 1rem !important;

      > * {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
      }
    }
  }
}
</style>
