
export const state = () => ({
  toasts: [],
  notifiedLowStock: false,
  displayToaster: false
})

export const actions = {

  pushToast ({ commit }, toast) {
    commit('PUSH_TOAST', toast)
    setTimeout(() => {
      commit('CLOSE_TOAST', toast)
    }, toast.ttl)
  },

  closeToast ({ commit }, toast) {
    commit('CLOSE_TOAST', toast)
  },

  toggleNotifiedLowStock ({ commit }) {
    commit('TOGGLE_NOTIFIED_LOW_STOCK')
  }
}

export const mutations = {

  PUSH_TOAST (state, payload) {
    state.toasts = [...state.toasts, payload]
    state.displayToaster = true
  },

  CLOSE_TOAST (state, payload) {
    state.toasts = state.toasts.filter(toast => toast.key !== payload.key)
    state.displayToaster = false
  },

  TOGGLE_NOTIFIED_LOW_STOCK (state) {
    state.notifiedLowStock = !state.notifiedLowStock
  }
}
