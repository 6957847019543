var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-form-message",class:{
    'c-form-message--success': _vm.success,
    'c-form-message--error': !_vm.success,
    'c-form-message--alert': _vm.alert,
    'c-form-message--fixed': _vm.fixed,
    'c-form-message--header': _vm.header
  },attrs:{"data-cy":_vm.dataCy}},[_vm._t("default"),_vm._v(" "),(!_vm.noClose)?_c('button',{staticClass:"c-form-message__btn",on:{"click":function($event){return _vm.$emit('hide')}}},[_c('Close',{attrs:{"title":"Fermer"}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }