import { sendSentryMessage } from '@/helpers/errorHelper'

export default async function (context) {
  const { page, $axios, error } = context
  const id = page.pageInfo?.filters?.products?.[0]
  if (!id) {
    return
  }

  try {
    // Axe d'amélioration pour bénéficier du cache redis côté client
    const apiProduct = await $axios.$get(`/products/${id}`)

    // Why object freeze : https://vuedose.tips/improve-performance-on-large-lists-in-vue-js/
    context.product = Object.freeze(apiProduct.product)
    context.productMetadata = Object.freeze(apiProduct.metadata)
    context.scores = Object.freeze(apiProduct.scores)
    context.productInformations = apiProduct.informations
  } catch (err) {
    if (err.response?.status !== 404) {
      sendSentryMessage.bind(context, 'Unable to fetch product informations', 'error', {
        requestedId: id,
        file: 'middleware/product.js',
        exception: err
      })()
    } else {
      error({ statusCode: 404 })
    }
  }
}
