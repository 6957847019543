import { api } from '../instance'

export const getAccountProfile = (options = {}) => {
  const { headers, instance } = options

  return (instance || api).get(
    '/secure/account/profile/v2',
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const patchAccountProfile = (options = {}) => {
  const { customerObject, headers, instance } = options

  return (instance || api).patch(
    '/secure/account/profile',
    { ...customerObject },
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getAccountCustomerAddresses = (options = {}) => {
  const { headers, instance } = options

  return (instance || api).get(
    '/secure/account/addresses',
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const createAddress = (options = {}) => {
  const { addressObject, headers, instance } = options

  return (instance || api).post(
    '/secure/account/addresses',
    { ...addressObject },
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const patchAddress = (options = {}) => {
  const { addressObject, headers, instance } = options

  return (instance || api).patch(
    '/secure/account/addresses',
    { ...addressObject },
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const deleteAddress = (options = {}) => {
  const { addressID, headers, instance } = options

  return (instance || api).delete(
    `/secure/account/addresses/${addressID}`,
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const patchPassword = (options = {}) => {
  const { passwordObject, headers, instance } = options

  return (instance || api).patch(
    '/secure/account/password',
    { ...passwordObject },
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getAccountVouchers = (options = {}) => {
  const { headers, instance } = options

  return (instance || api).get(
    '/secure/account/vouchers',
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}
