<template>
  <span class="c-highlight">
    <slot />
  </span>
</template>

<script>
/**
 * The `Highlight` component: highlight a part of the phrase passed as slot (used in the header Search)
 */
export default {

}
</script>

<style lang="scss" scoped>
.c-highlight {
  margin-right: 0.3rem;

  ::v-deep mark {
    background: transparent;
    color: inherit;
    font-weight: 900;
  }
}
</style>
