<!-- @format -->
<template>
  <Modal
    name="localStorage"
    height="auto"
    @closed="$store.commit('SET_LOCAL_STORAGE_WARN', true)"
  >
    <ModalSimple
      class="c-modal__localstorage"
      modal-name="localStorage"
      disable-title
      :additionnal-props-button="{ primary: true }"
    >
      <template #content>
        <i18n
          class="c-modal__localstorage__content"
          path="components.organisms.home.modalLocalStorage.content"
          tag="p"
        >
          <template #br>
            <br />
          </template>
        </i18n>
      </template>
      <template #button>
        {{ $t('components.organisms.home.modalLocalStorage.buttonLabel') }}
      </template>
    </ModalSimple>
  </Modal>
</template>

<script>
import ModalSimple from '@/components/molecules/modal/modal-simple/ModalSimple.vue'

export default {
  components: {
    ModalSimple,
  },
}
</script>

<style lang="scss" scoped>
.c-modal__localstorage {
  background-color: $white;
}
.c-modal__localstorage__content {
  margin-bottom: 2rem;
}
</style>
