import {
  getRefererByName as getRefererByNameApi
} from '@/api/referer'

export const getRefererByName = ({
  refererName,
  headers,
  instance
} = {}) => {
  return getRefererByNameApi({
    refererName,
    headers,
    instance
  })
}
