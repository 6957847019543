<!-- @format -->
<template>
  <div v-if="!mobile" class="c-header-search">
    <form
      ref="searchForm"
      method="get"
      class="c-header-search__form"
      @submit.prevent="validForm"
    >
      <vue-autosuggest
        ref="searchField"
        v-model="query"
        :suggestions="suggestions"
        :should-render-suggestions="shouldRenderSuggestions"
        :get-suggestion-value="getSuggestionValue"
        :input-props="{
          name: 'motclef',
          placeholder: $t('components.molecules.header.headerSearch.search'),
        }"
        class="autosuggest c-btn-with-input"
        :class="{
          'autosuggest--focus': focus,
          'c-btn-with-input--inactive': !formReady,
        }"
        @selected="onSelect"
        @input="refine"
        @keyup.enter="validForm"
        @focus="
          focus = true
          showSuggestions = true
        "
        @blur="focus = false"
      >
        <template slot="after-input">
          <VButton
            type="submit"
            :title="$t('components.molecules.header.headerSearch.search')"
            :disabled="!formReady"
          >
            <SearchIcon
              :tile="$t('components.molecules.header.headerSearch.search')"
            />
          </VButton>
        </template>
        <template v-if="!query" slot="before-suggestions">
          <div class="c-header-search__title">
            {{ $t('components.molecules.header.headerSearch.lastSearch') }}
          </div>
        </template>
        <div slot-scope="{ suggestion }">
          <Highlight>
            <span
              v-html="
                $options.filters.highlight(
                  suggestion.item.text[$i18n.locale],
                  algoliaQuery,
                )
              "
            />
          </Highlight>
          <span v-if="suggestion.item.productsNumber">
            ({{ suggestion.item.productsNumber }})
          </span>
        </div>
      </vue-autosuggest>
    </form>
  </div>
  <!-- mobile -->
  <div v-else class="c-search-header-mobile">
    <button class="c-search-header-mobile__close" @click="$emit('close')">
      <CloseIcon
        :title="$t('components.molecules.header.headerSearch.close')"
      />
    </button>
    <div class="c-search-header-mobile__wrapper">
      <form @submit.prevent="validForm">
        <InputForm
          v-model="query"
          type="search"
          name="motclef"
          placeholder="Rechercher"
          @input="refine($event)"
        />
      </form>
      <div v-if="query" class="c-search-header-mobile__results">
        <div>
          <a
            v-for="hit in currentRefinement"
            :key="hit.objectID"
            :href="hit.urls[$i18n.locale]"
            class="c-search-header-mobile__results-item"
            @click.prevent="onSelect({ item: hit })"
          >
            <span>
              <Highlight>
                <span
                  v-html="
                    $options.filters.highlight(
                      hit.text[$i18n.locale],
                      algoliaQuery,
                    )
                  "
                />
              </Highlight>
              <span v-if="hit.productsNumber">({{ hit.productsNumber }})</span>
            </span>
            <span class="c-search-header-mobile__results-icon">
              <ArrowRightIcon />
            </span>
          </a>
        </div>
      </div>
      <div v-else class="c-search-header-mobile__results">
        <div class="c-header-search__title">
          {{ $t('components.molecules.header.headerSearch.lastSearch') }}
          <button
            v-if="mobile && previousSearch.length > 0"
            class="c-header-search__clean"
            type="button"
            @click="cleanSearchList"
          >
            {{ $t('components.molecules.header.headerSearch.clean') }}
          </button>
        </div>
        <div class="c-search-header-mobile__results-list">
          <a
            v-for="suggestion in previousSearch"
            :key="suggestion.objectID"
            :href="suggestion.urls[$i18n.locale]"
            class="c-search-header-mobile__results-item"
          >
            <span>{{ suggestion.text[$i18n.locale] }}</span>
            <span class="c-search-header-mobile__results-icon">
              <ArrowRightIcon />
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// LIBS
import { mapGetters, mapState, mapActions } from 'vuex'
import algoliasearch from 'algoliasearch/lite'
import algoliasearchHelper from 'algoliasearch-helper'
import { VueAutosuggest } from 'vue-autosuggest'
import { watchViewport } from 'tornis'

// MISC
import {
  LISTING_TYPE,
  ALGOLIA_CLIENT_ID,
  ALGOLIA_API_KEYS,
} from '@/config/algolia/config'
import { generateAlgoliaIndex } from '@/helpers/generateAlgoliaIndex'
import { algoliaErrorHandler } from '@/helpers/algoliasearchHelper'

// COMPONENTS
import ArrowRightIcon from '@/components/atoms/icons/ArrowRight'
import CloseIcon from '@/components/atoms/icons/Close'
import Highlight from '@/components/atoms/global/highlight/Highlight'
import InputForm from '@/components/atoms/form/input/VInput'
import SearchIcon from '@/components/atoms/icons/Search'
import VButton from '@/components/atoms/global/button/VButton'
import { sendSentryMessage } from '@/helpers/errorHelper'

export default {
  filters: {
    highlight(value, param) {
      const words = param
        .trim()
        .split(' ')
        .filter((i) => i !== '')
      if (words.length > 0) {
        const regex = new RegExp(`(${words.join('|')})`, 'gi')
        value = value.replace(regex, '<mark>$1</mark>')
      }
      return value
    },
  },
  components: {
    ArrowRightIcon,
    CloseIcon,
    Highlight,
    InputForm,
    SearchIcon,
    VButton,
    VueAutosuggest,
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchClient: null,
      helper: null,
      query: '',
      algoliaQuery: '',
      previousSearch: [],
      currentRefinement: null,
      indices: [],
      focus: false,
      showSuggestions: false,
      formReady: false,
    }
  },
  computed: {
    ...mapState({
      currentInterStore: (state) =>
        state.international && state.international.currentInterStore,
    }),
    ...mapGetters({
      algoliaSearchIndex: 'international/getAlgoliaSearchIndex',
    }),
    suggestions() {
      if (this.query) {
        return [{ data: this.currentRefinement }]
      } else {
        return [{ data: this.previousSearch }]
      }
    },
  },
  mounted() {
    this.formReady = true
    if (this.$localStorage?.getItem('previousSearch')) {
      this.previousSearch = JSON.parse(
        this.$localStorage.getItem('previousSearch'),
      )
    }
    this.searchClient = algoliasearch(
      ALGOLIA_CLIENT_ID,
      ALGOLIA_API_KEYS[this.currentInterStore.id],
    )
    this.helper = algoliasearchHelper(
      this.searchClient,
      generateAlgoliaIndex(this.algoliaSearchIndex, LISTING_TYPE.SEARCH),
      {
        hitsPerPage: 15,
      },
    )
    if (this.helper) {
      this.helper.on('result', ({ results }) => {
        this.algoliaQuery = results.query
        this.currentRefinement = results.hits
      })
      this.helper.on('error', (evt) => {
        const exception = algoliaErrorHandler(evt)

        sendSentryMessage.bind(
          this,
          'Unable to get algolia results in header search',
          'error',
          {
            exception,
            store: this.currentInterStore.id,
          },
        )()
      })
    }
    watchViewport(({ scroll }) => {
      if (scroll.changed && scroll.velocity.y > 0) {
        if (this.$refs.searchField && this.showSuggestions) {
          this.focus = false
          this.showSuggestions = false
          this.$refs.searchField.$el.querySelector('input').blur()
        }
      }
    })
  },
  methods: {
    ...mapActions({
      setSearchQueryOnHeaderTools: 'listing/setSearchQueryOnHeaderTools',
    }),
    onSelect(selected) {
      if (selected) {
        if (
          !this.previousSearch.find(
            (item) =>
              item.text[this.$i18n.locale] ===
              selected.item.text[this.$i18n.locale],
          )
        ) {
          this.previousSearch = [selected.item, ...this.previousSearch].slice(
            0,
            5,
          )
          this.$localStorage?.setItem(
            'previousSearch',
            JSON.stringify(this.previousSearch),
          )
        }
        this.$emit('close')
        this.query = null
        this.$router.push({
          path: this.localePath(selected.item.urls[this.$i18n.locale]),
        })
        this.hasRedirect = true
      } else {
        this.validForm(null)
      }
    },

    getSuggestionValue(suggestion) {
      return suggestion.item.text[this.$i18n.locale]
    },

    validForm(event) {
      if (event) {
        event.preventDefault()
      }
      this.$emit('close')
      // if hasredirect, do noting here, redirection is done in select method
      if (this.hasRedirect) {
        this.hasRedirect = false
        return
      }
      if (this.query === '') {
        this.$refs.searchForm.querySelector('input').focus()
      } else if (this.currentRefinement) {
        const match = this.currentRefinement.find(
          (item) =>
            item.text[this.$i18n.locale].toLowerCase() ===
            this.algoliaQuery.trim().toLowerCase(),
        )
        if (match) {
          // if the query match exactly the first suggestion, redirect on it
          this.setSearchQueryOnHeaderTools(this.query)
          if (
            !this.previousSearch.find(
              (item) =>
                item.text[this.$i18n.locale] === match.text[this.$i18n.locale],
            )
          ) {
            this.previousSearch = [match, ...this.previousSearch].slice(0, 5)
            this.$localStorage?.setItem(
              'previousSearch',
              JSON.stringify(this.previousSearch),
            )
          }
          this.$router.push({
            path: this.localePath(match.urls[this.$i18n.locale]),
          })
        } else {
          this.$router.push({
            path: this.localePath(
              `/search/${this.query.trim().replaceAll(' ', '+')}`,
            ),
          })
        }
        this.query = ''
      } else {
        this.$router.push({
          path: this.localePath(
            `/search/${this.query.trim().replaceAll(' ', '+')}`,
          ),
        })
      }
    },
    cleanSearchList() {
      this.previousSearch = []
      if (this.$localStorage?.getItem('previousSearch')) {
        this.$localStorage.removeItem('previousSearch')
      }
    },
    refine(query) {
      this.helper.setQueryParameter('query', query).search()
    },
    refineMobile(indices) {
      this.suggestions = indices.map(({ hits }) => ({ data: hits }))
      return indices
    },
    shouldRenderSuggestions(size, loading) {
      return this.showSuggestions ? size > 0 && !loading : false
    },
  },
}
</script>

<style lang="scss">
.c-header-search {
  flex: 0 1 84.1rem;
  padding: 0 4.1rem 0 3.6rem;

  .c-btn-with-input {
    position: relative;

    &--inactive {
      pointer-events: none;
    }

    input {
      height: 5rem;
      padding: 0 1.8rem;
      padding-left: 6rem;
      border: 0.2rem solid $white;
      border-left: none;
      color: $white;
      font-size: $font-size-sm;
      font-weight: 500;
      letter-spacing: 0.13rem;
      line-height: 2rem;
      text-transform: none;
      transition: $transition;

      &::placeholder {
        color: $white;
        text-transform: none;
      }

      &:focus {
        border-color: $grey-1100;
        background: $white;
        color: $grey-300;
      }
    }

    .c-btn {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 5rem;
      height: 5rem;
      align-items: center;
      justify-content: center;
      padding: 0;
      border-color: $white;
      color: $white;
      transition: none;

      &:disabled {
        border-color: $white !important;
        background: none;
      }

      svg {
        width: 2rem;
        height: 2rem;
        fill: currentcolor;
      }
    }
  }

  &__title {
    margin-bottom: 3rem;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 0.075rem;
  }

  &__form {
    width: 100%;
  }

  // override autosuggest style
  .autosuggest {
    display: flex;
    flex-grow: 1;

    > div {
      width: 100%;
    }

    // stylelint-disable-next-line selector-class-pattern
    & .autosuggest__results-container {
      position: absolute;
      z-index: 10;
      top: 100%;
      left: 0;
      width: 100%;
    }

    &--focus {
      &.c-btn-with-input .c-btn,
      &.c-btn-with-input .c-btn:disabled {
        border-color: $grey-1100 !important;
        color: $grey-300;
      }
    }

    &__results {
      padding: 4rem 6rem;
      border: 0.2rem solid $grey-1100;
      border-top: none;
      background: $white;
      color: $grey-300;

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          padding-bottom: 1.2rem;
          cursor: pointer;
          font-size: $font-size-sm;
          letter-spacing: 0.1rem;

          &:hover {
            color: $primary;
          }
        }
      }
    }

    &__results-item--highlighted {
      color: $primary;
    }
  }

  &__result {
    background: $white;
    color: $grey-300;
  }

  @include mq($until: tablet) {
    display: none;
  }
}

.c-search-header-mobile {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 1.6rem 1.3rem;
  background: $white;
  color: $grey-300;

  &__close {
    position: absolute;
    top: 3rem;
    right: 1.5rem;
    width: 1.7rem;
    height: 1.7rem;
  }

  &__wrapper {
    width: 100%;
  }

  .c-input {
    margin-right: 3.9rem;

    input {
      padding: 1rem;
      border-width: 0.2rem;
      text-transform: none;

      &::placeholder {
        color: $grey-900 !important;
        font-weight: 500;
        text-transform: none;
      }
    }
  }

  // stylelint-disable-next-line selector-class-pattern
  .c-header-search__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $font-size-xl;
  }

  // stylelint-disable-next-line selector-class-pattern
  .c-header-search__clean {
    color: $grey-600;
    font-size: $font-size-sm-mobile;
    font-weight: 400;
    line-height: 1;
    text-decoration: underline;
  }

  &__results {
    height: calc(100% - 7.2rem);
    margin-top: 3rem;

    > div {
      // Si la premier niveau de div dans c-search-header-mobile__results na pas de class
      // (quand lutilisateur fait une recherche)
      &:not([class]) {
        height: calc(100vh - 9rem);
        overflow-y: auto;
      }
    }
  }

  &__results-list {
    height: calc(100% - 5.8rem);
    overflow-y: auto;
  }

  &__results-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0;
    border-top: 0.1rem solid $grey-1300;
    border-bottom: 0.1rem solid $grey-1300;
    margin-top: -0.1rem;
    color: $grey-300;
    text-decoration: none;
  }

  &__results-icon {
    width: 0.9rem;
    height: 0.9rem;

    ::v-deep svg {
      width: 100%;
    }
  }
}
</style>
