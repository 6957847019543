import { sendSentryMessage } from './../helpers/errorHelper'
export const getTranslations = async (context, locale, yml) => {
  try {
    context.$axios.setBaseURL(context.$config.apiUrl)
    return await context.$axios.$get('/translations')
  } catch (e) {
    sendSentryMessage.bind(context, 'Unable to load translations', 'fatal', {
      action: `Load ${locale}`,
      details: e.request,
      call: 'getTranslation',
      exception: e
    })()
    context.$sentry.captureException(e)
    return await Promise.resolve(yml)
  }
}
