<template>
  <div
    class="c-price__wrapper"
    :class="{
      'c-price__wrapper--basket': basket,
      'c-price__wrapper--inverted': inverted
    }"
  >
    <span
      v-if="!old || old === price"
      class="c-price"
      :class="{
        'c-price--large': large,
        'c-price--small': small,
        'c-price--info': info
      }"
    >{{ price|currency }}</span>
    <template v-else>
      <div
        v-if="summary"
        class="c-price--summary"
      >
        <div class="c-price__with-discount">
          <div class="c-price__discount u-1/1">
            {{ $t('components.atoms.global.price.discount') }} <span>-{{ discount }}%</span>
          </div>
          <div class="c-price__wrapper-discount">
            <div
              class="c-price c-price--old"
              :class="{'c-price--small': small}"
            >
              {{ old|currency }}
            </div>
            <div
              class="c-price c-price--discount"
              :class="{'c-price--large': large, 'c-price--small': small}"
            >
              {{ price|currency }}
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <div class="c-price__with-discount">
          <p
            v-if="displayDiscount"
            class="c-price__discount u-hidden@tablet u-1/1"
          >
            {{ $t('components.atoms.global.price.saving') }} <span>{{ discount }}%</span>
          </p>
          <p
            v-if="displayDiscountPromo"
            class="c-price__promo"
          >
            {{ $t('components.atoms.global.price.promo') }} <span>-{{ discount }}%</span>
          </p>
          <p
            v-if="displayDiscount && basket"
            class="c-price__discount u-hidden u-visible@tablet u-1/1"
          >
            {{ $t('components.atoms.global.price.discount') }} <span>-{{ discount }}%</span>
          </p>
          <div class="c-price__wrapper-discount">
            <span
              class="c-price c-price--discount"
              :class="{'c-price--large': large, 'c-price--small': small}"
            >{{ price|currency }}</span>
            <span
              class="c-price c-price--old"
              :class="{'c-price--small-on-mobile': smallOldPrice, 'c-price--small': small}"
            >{{ old|currency }}</span>
          </div>
        </div>
        <div
          v-if="displayDiscount && !basket"
          class="u-hidden u-visible@tablet u-align--right u-margin-top--xs"
        >
          <Sticker large>
            -{{ discount }}%
          </Sticker>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Sticker from '@/components/atoms/product/sticker/Sticker'
/**
 * the `Price` component: display current price and old price if set.
 */
export default {
  components: { Sticker },
  props: {
    /**
     * the current price
     */
    price: {
      type: Number,
      required: true
    },
    /**
     * the old price of the product (optional)
     */
    old: {
      type: Number,
      default: null
    },

    /**
     * display the discount under the price
     */
    displayDiscount: {
      type: Boolean,
      default: false
    },
    /**
     * display the discount promo
     */
    displayDiscountPromo: {
      type: Boolean,
      default: false
    },
    /**
     * enlarge the font-size
     */
    large: {
      type: Boolean,
      default: false
    },

    info: {
      type: Boolean,
      default: false
    },

    /**
     * reduce the font-size
     */
    small: {
      type: Boolean,
      default: false
    },

    /**
     * invert price display
     */
    inverted: {
      type: Boolean,
      default: false
    },

    /**
     * reduce the font-size of the old price on mobile
     */
    smallOldPrice: {
      type: Boolean,
      default: false
    },

    /**
     * add the basket class (to be displayed in basket)
     */
    basket: {
      type: Boolean,
      default: false
    },

    /**
     * add the basket class (to be displayed in basket)
     */
    summary: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    discount () {
      return Math.round((1 - this.price / this.old) * 100)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-price {
  $c: &;

  font-size: $font-size-md;
  font-weight: 700;
  line-height: 1;

  &--discount {
    color: $primary;
    font-size: $font-size-lg;
  }

  &--small {
    font-size: $font-size-sm;
  }

  &--info {
    font-size: 1.8rem;
    font-weight: 500;

    @include mq($until: tablet) {
      font-size: 1.5rem;
    }
  }

  &--old {
    margin-left: 2.7rem;
    font-weight: 400;
    text-decoration: line-through;

    @include mq($until: tablet) {
      margin-left: 1.5rem;
    }
  }

  &--large {
    font-size: $font-size-xl;

    @include mq($until: tablet) {
      font-size: $font-size-xl-mobile;
    }
  }

  &--small-on-mobile {
    @include mq($until: tablet) {
      font-size: $font-size-sm-mobile;
    }
  }

  &__with-discount {
    @include mq($until: tablet) {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
    }
  }

  &__wrapper-discount {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__promo {
    display: inline-block;
    padding: 0.2rem 1.1rem;
    margin: 0 0 1.3rem;
    background: $primary;
    color: $white;

    @include mq($until: tablet) {
      margin: 0.6rem 0 0;
    }
  }

  &__discount {
    margin: 0 0 1.3rem;
    color: $grey-600;
    font-size: $font-size-sm;
  }

  &__wrapper {
    @include mq($from: tablet) {
      display: flex;
    }

    &--basket {
      #{$c}__discount {
        span {
          color: $primary;
          font-weight: 700;
        }

        @include mq($until: tablet) {
          color: #8f8f8f;
          font-size: 1.1rem;
          font-weight: 400 !important;
        }
      }

      #{$c}__with-discount {
        display: flex;
        flex-direction: column;
        #{$c}__wrapper-discount {
          display: flex;
          flex-direction: column;

          // stylelint-disable-next-line selector-class-pattern
          .c-price--discount.c-price--large {
            font-size: 1.5rem;
          }

          @include mq($until: tablet) {
            align-items: flex-end;
          }
        }
        #{$c} {
          margin-left: 0;
          font-size: 1.3rem;
          line-height: inherit;
        }

        #{$c}__discount {
          order: 2;
          margin-bottom: 0;
          font-weight: 700;

          @include mq($until: tablet) {
            order: 0;
          }
        }

        #{$c}--old {
          order: 0;

          @include mq($until: tablet) {
            font-size: $font-size-sm-mobile;
          }
        }

        #{$c}--discount {
          order: 1;
          font-size: 1.8rem;
        }
      }
    }

    &--inverted {
      #{$c}__with-discount {
        #{$c}__wrapper-discount {
          display: flex;
        }
        #{$c}--old {
          order: 0;
          margin-left: 0;
        }

        #{$c}--discount {
          order: 1;
          margin-left: 1.5rem;
          color: inherit;
        }
      }
    }
  }

  &--summary {
    #{$c}__with-discount {
      #{$c}__wrapper-discount {
        display: block;
        min-width: 55px;
        line-height: 0.9;
        text-align: right;
      }
    }

    #{$c}__discount {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      font-size: $font-size-xs-mobile;

      span {
        color: $primary;
      }
    }

    #{$c}--old {
      margin-top: 0.5rem;
      margin-bottom: 0.2rem;
      margin-left: 0;
      font-size: $font-size-xs-mobile;
    }
  }
}
</style>
