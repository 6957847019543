<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 42 34"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <path
      fill-rule="evenodd"
      d="M42 29v5H0v-5h42zm0-14v5H0v-5h42zm0-15v5H0V0h42z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
