<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 19.8"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <path d="M16 10.8a6.8 6.8 0 1 0-11 0c-3 1.9-5 5.3-5 9h2c0-3.2 1.8-6 4.6-7.5a6.8 6.8 0 0 0 8 0 8.5 8.5 0 0 1 4.6 7.5h2c-.2-3.7-2.1-7.1-5.2-9zm-5.5.8a4.8 4.8 0 1 1 4.8-4.8c0 2.6-2.2 4.8-4.8 4.8z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
