<template functional>
  <div
    class="c-btn-with-input"
    :class="{
      'c-btn-with-input--reverse': props.reverse,
      'c-btn-with-input--focused': props.focused
    }"
  >
    <slot name="input" />
    <slot name="btn" />
  </div>
</template>

<script>
/**
 * The `ButtonWithInput` component : give 2 slots :
 *
 *  * `input`: should receive a input component
 *
 *  * `btn`: should receive a Button component
 */
export default {
  props: {
    /**
     * Change the borders and texts color to white
     */
    reverse: {
      type: Boolean,
      default: false
    },
    /**
     * change the background color to white
     */
    focused: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.c-btn-with-input {
  position: relative;
  display: flex;

  input {
    padding: 2rem 3.8rem;
    border: 0.2rem solid $grey-300;

    @include mq($until: tablet) {
      padding: 1.8rem 3.8rem;
      border-width: 0.1rem;
    }

    &::placeholder {
      text-transform: uppercase;
    }
  }

  > :first-child {
    border-right-width: 0.1rem;
  }

  > :last-child {
    border-left: none;
  }

  &--reverse {
    input,
    .c-btn {
      border-color: $white;
      color: $white;

      &:focus,
      &:hover {
        border-color: $grey-1300;
      }
    }

    .c-btn {
      &:focus,
      &:hover {
        background: $white;
        color: $primary;
      }
    }

    input::placeholder {
      color: $white;
    }
  }

  &--focused {
    background: $white;
  }
}
</style>
