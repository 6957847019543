<template functional>
  <div class="c-footer-credits" :class="{'c-footer-credits--special': props.special}">
    <slot />
  </div>
</template>

<script>
/**
 * The `Footer credit` component : Display le footer credit (used in footer of all pages).
 */
export default {
  props: {
    /**
     * if special, the background changes to lighter grey
     */
    special: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-footer-credits {
  padding: 1rem;
  background: $black;
  color: $grey-600;
  font-size: $font-size-sm-mobile;
  font-weight: 400;
  text-align: center;

  &--special {
    background: $grey-1100;
    color: $grey-600;
  }
}
</style>
