/* @format */

import { set } from 'vue'
import omit from 'lodash.omit'
import generateUrl from '@/helpers/generateUrl'

// initial state
export const state = () => ({
  type: null,
  query: null,
  filters: {},
  ranges: null,
  page: null,
  minPage: null,
  maxPage: null,
  sort: null,
  orders: [],
  showFilterMobile: false,
  lastClickOnFilterSort: null,
  refreshListingAfterClickOnHeaderMenu: false,
  reloadAsyncData: false,
  searchQueryOnHeaderTools: null,
  allFacetsRangesAggregate: {},
})

// getters
export const getters = {
  type(state) {
    return state.type
  },
  filters(state) {
    return state.filters
  },
  nbFilters(state) {
    return state.filters
      ? Object.values(state.filters).reduce((a, b) => a + b.length, 0)
      : 0
  },
  ranges(state) {
    return state.ranges
  },
  stateToUrl(state, _, rootState) {
    return (path) => {
      const url = generateUrl(
        state,
        path,
        rootState.international.currentInterStore,
      )
      return url
    }
  },

  orders(state) {
    return state.orders
  },

  createURL(state, _, rootState) {
    const currentInterStore = rootState.international.currentInterStore
    return (type, filter, value, path) => {
      let nextState
      if (type === 'TOGGLE') {
        const nextFilters = { ...state.filters }
        const i =
          state.filters[filter] &&
          state.filters[filter].findIndex((item) => item === value)
        if (i > -1) {
          nextFilters[filter] = [...state.filters[filter]].filter(
            (i) => i !== value,
          )
        } else if (state.filters[filter]) {
          nextFilters[filter] = [...state.filters[filter], value]
        } else {
          nextFilters[filter] = [value]
        }
        // Omitting page parameter as it reduce SEO juice
        // (see: https://laboutiqueofficielle.atlassian.net/browse/RSEO-8)
        nextState = omit({ ...state, filters: nextFilters }, 'page')
      } else if (type === 'CLEAR') {
        let nextFilters
        if (filter) {
          nextFilters = { ...state.filters, [filter]: null }
        } else {
          nextFilters = {}
        }
        // Same as previous comment
        nextState = omit({ ...state, filters: nextFilters }, 'page')
      } else if (type === 'SORT') {
        nextState = { ...state, sort: filter }
        return generateUrl(nextState, path, currentInterStore)
      } else if (type === 'NEXT') {
        const current = state.maxPage || parseInt(path.query?.page) - 1 || 0
        nextState = { ...state, page: current + 1 }
        return generateUrl(nextState, path.path, currentInterStore)
      } else if (type === 'PREV') {
        const current = state.minPage || parseInt(path.query?.page) - 1 || 0
        nextState = { ...state, page: current > 0 ? current - 1 : 0 }
        return generateUrl(nextState, path.path, currentInterStore)
      }
      return generateUrl(nextState, path, currentInterStore)
    }
  },

  sort(state) {
    const sort = state.orders.find((order) => state.sort === order.value)

    return sort?.sort
  },
}

// actions
// FIXME: All the synchronous actions should be deleted... useless boilerplate code
export const actions = {
  setSearchQueryOnHeaderTools({ commit }, payload) {
    commit('SET_SEARCH_QUERY_ON_HEADER_TOOLS', payload)
  },
  resetListingStore({ commit }, payload) {
    commit('RESET_LISTING_STORE', payload)
  },
  setLastClickOnFilterSort({ commit }, payload) {
    commit('SET_LAST_CLICK_ON_FILTER_SORT', payload)
  },
  reinitFilter({ commit }, payload) {
    commit('REINIT_FILTER', payload)
  },
  setReloadAsyncData({ commit }, payload) {
    commit('SET_RELOAD_ASYNC_DATA', payload)
  },
  setType({ commit }, type) {
    commit('SET_TYPE', type)
  },

  setFilters({ commit }, refs) {
    commit('SET_FILTERS', refs)
  },

  setRanges({ commit }, range) {
    commit('SET_RANGES', range)
  },

  setPage({ commit }, page) {
    commit('SET_PAGE', page)
  },

  setIndex({ commit }, index) {
    commit('SET_INDEX', index)
  },

  setQuery({ commit }, query) {
    commit('SET_QUERY', query)
  },

  setOrders({ commit }, orders) {
    commit('SET_ORDERS', orders)
  },

  setMinPage({ commit }, minPage) {
    commit('SET_MIN_PAGE', minPage)
  },

  setMaxPage({ commit }, maxPage) {
    commit('SET_MAX_PAGE', maxPage)
  },

  setShowFilterMobile({ commit }, payload) {
    commit('SET_SHOW_FILTER_MOBILE', payload)
  },
}

// mutations
export const mutations = {
  SET_SEARCH_QUERY_ON_HEADER_TOOLS(state, payload) {
    state.searchQueryOnHeaderTools = payload
  },
  RESET_LISTING_STORE(state) {
    state.type = null
    state.query = null
    state.filters = {}
    state.ranges = null
    state.page = null
    state.minPage = null
    state.maxPage = null
    state.sort = null
    state.orders = []
    state.showFilterMobile = false
    state.lastClickOnFilterSort = null
  },
  SET_LAST_CLICK_ON_FILTER_SORT(state, payload) {
    state.lastClickOnFilterSort = payload
  },
  SET_RELOAD_ASYNC_DATA(state, payload) {
    state.reloadAsyncData = payload
  },
  REINIT_FILTER(state, payload) {
    if (payload) {
      state.filters = {}
    }
    state.refreshListingAfterClickOnHeaderMenu = payload
  },
  SET_FILTERS(state, payload) {
    if (Object.keys(state.filters).length !== 0) {
      state.filters = {}
    }
    if (payload.length) {
      payload.forEach((item) => {
        // store in filters only disjunctive facets, numeric are stored in ranges
        if (item.type === 'disjunctive') {
          if (state.filters[item.attributeName]) {
            set(state.filters, item.attributeName, [
              ...state.filters[item.attributeName],
              item.name,
            ])
          } else {
            set(state.filters, item.attributeName, [item.name])
          }
        }
      })
    }
  },

  SET_RANGES(state, payload) {
    state.ranges = {}
    if (payload) {
      payload.forEach((item) => {
        if (item.type === 'numeric') {
          if (state.ranges[item.attributeName]) {
            if (item.operator === '>=') {
              set(state.ranges, item.attributeName, {
                ...state.ranges[item.attributeName],
                min: item.numericValue,
              })
            } else {
              set(state.ranges, item.attributeName, {
                ...state.ranges[item.attributeName],
                max: item.numericValue,
              })
            }
          } else if (item.operator === '>=') {
            set(state.ranges, item.attributeName, {
              min: item.numericValue,
              max: '',
            })
          } else {
            set(state.ranges, item.attributeName, {
              min: '',
              max: item.numericValue,
            })
          }
        }
      })
    }
  },

  SET_ALL_FACETS_RANGES_AGGREGATE(state, payload) {
    state.allFacetsRangesAggregate = payload
  },

  SET_TYPE(state, payload) {
    state.type = payload
  },

  SET_PAGE(state, payload) {
    state.page = payload
  },

  SET_MIN_PAGE(state, payload) {
    state.minPage = payload
  },

  SET_MAX_PAGE(state, payload) {
    state.maxPage = payload
  },

  SET_INDEX(state, payload) {
    state.sort = payload
  },

  SET_QUERY(state, payload) {
    state.query = payload
  },

  SET_ORDERS(state, payload) {
    set(state, 'orders', payload)
  },

  SET_SHOW_FILTER_MOBILE(state, payload) {
    state.showFilterMobile = payload
  },
}
