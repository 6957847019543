<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 2000 2000"
    width="27px"
    height="26px"
  >
    <path d="M1848 499.6c0-8.8-.2-9.1-9.4-9-22.1.2-44.1-2.4-65.9-5.7-79.2-11.9-335.9-146.6-379.3-366.9-.8-4.3-10.5-59-10.4-82.3.1-10.4-.1-10.4-10.4-10.6h-308.5c-20.2 0-17.4-2.5-17.4 17.8-.1 435.7-.1 871.4 0 1307 0 16.2-.5 32.5-3.3 48.4-15.1 84.3-58.3 150.3-131.4 195.3-63.3 39-132 50.2-205 35.7-23-4.5-44.6-13.2-66.2-21.8-1.8-1.6-3.5-3.2-5.2-4.8-7.3-6.1-15.3-11.1-23.1-16.5-93-64.4-137.1-153.8-123.7-265.6 13.6-113.4 78.9-190.4 186.4-230.1 32.1-11.8 65.8-16.7 100.1-14.9 22.1 1.2 44.1 4 65.5 10.1 7.4 2.1 11.4-.5 11.8-8.4.1-2.8.2-5.5.2-8.3 0-78.2-2.9-249.1-3.3-249.4.2-22.5.1-45 .6-67.6.1-6.5-3.1-7.6-8.6-8.3-40.5-4.8-81-5.7-121.8-2.7-56.3 4.1-110.9 15.7-163.9 34.8-84.6 30.4-158.9 77.5-223 140.3-55.1 53.9-98.3 116.3-130.1 186.6-30.4 67.3-47.8 137.9-52.6 211.4-2.1 31.9-1.7 63.9 1.3 95.9 4.1 44.2 12.5 87.5 25.9 129.7 38.7 121.4 108.9 221 208.6 300 10.1 8 19.9 16.9 32 22.1l15.3 13.5c16.1 12.2 33.4 22.5 51.5 31.5 111.6 55.2 229.3 77.6 353.2 62.4 160.9-19.6 294.9-90.9 400.4-213.7 99.3-115.6 147.5-251.3 148.4-403 1.3-217 .3-433.9.4-650.9 0-5.2-3-12.5 2.7-15.1 4.5-2 9 3.9 13.2 6.6 80.8 53 167.8 91.4 262.1 113 54.9 12.6 110.3 20.2 166.8 20.1 17.8 0 20.2-.9 20.2-18.6-.1-76.9-4.1-287.5-4.1-308z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
