<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29px"
    height="29px"
    viewBox="0 0 21.5 17.8"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <defs />
    <path d="M15 0c-1.6 0-3.1.6-4.2 1.6a6.5 6.5 0 00-8.7 9.7l.2.2 7.8 6.1.6.2.6-.2 7.8-6.1.2-.2A6.4 6.4 0 0015 0zm2.9 10l-.1.1-7 5.5-7-5.5-.1-.1a4.5 4.5 0 012.8-8c1.4 0 2.6.6 3.5 1.6.2.3.4.4.7.4.3 0 .6-.1.8-.4.9-1 2.1-1.6 3.5-1.6 2.5 0 4.5 2 4.5 4.5 0 1.3-.6 2.6-1.6 3.5z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
