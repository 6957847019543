<template functional>
  <svg
    v-if="fat"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9.7 9.7"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <path d="M9.7 1.4L8.3 0 4.8 3.4 1.4 0 0 1.4l3.4 3.4L0 8.3l1.4 1.4 3.4-3.5 3.5 3.5 1.4-1.4-3.5-3.5z" />
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.5 21.6"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <path d="M12.9 10.8v-.1l8.6-8.6L19.3 0l-8.6 8.6L2.1 0 0 2.1l8.6 8.6-.1.1h.1L0 19.4l2.1 2.2 8.6-8.6 8.6 8.6 2.2-2.2-8.6-8.6z" />
  </svg>
</template>

<script>
export default {
  props: {
    fat: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    }
  }
}
</script>
