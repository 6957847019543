<template>
  <div
    class="c-header-tools"
  >
    <nuxt-link :to="localePath(`/t/${CMS_SLUG[currentInterStore.id].faq}`)" class="c-header-tools__link">
      {{ $t('components.molecules.header.headerTools.help') }}
    </nuxt-link>
    <div class="c-header-tools__list">
      <div class="c-header-tools__item c-header-tools__item--account">
        <DropdownMenu
          id="my-account"
        >
          <template #button>
            <nuxt-link :to="localePath('/account')" class="c-header-tools__button" data-cy="c-header-tools__button">
              <AccountIcon :title="$t('components.molecules.header.headerTools.myAccount')" />
            </nuxt-link>
          </template>
          <template #default="{ hide }">
            <UserAccount v-if="!loading" @close="hide()" />
          </template>
        </DropdownMenu>
      </div>

      <div class="c-header-tools__item c-header-tools__item--favorite">
        <nuxt-link :to="localePath('/wishlist')" class="c-header-tools__button">
          <span v-if="nbFavorites > 0 && !loading" class="c-header-tools__numbers">
            {{ nbFavorites }}
          </span>
          <HeartIcon :title="$t('components.molecules.header.headerTools.favorites')" />
        </nuxt-link>
      </div>

      <div class="c-header-tools__item c-header-tools__item--search">
        <button class="c-header-tools__button" @click="showSearch()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.2 20.2">
            <title>{{ $t('components.molecules.header.headerTools.search') }}</title>
            <path d="M20 18.5l-2.6-2.6a9.5 9.5 0 0 0 2.2-6.1A9.8 9.8 0 0 0 0 9.8a9.7 9.7 0 0 0 15.9 7.5l2.6 2.6c.2.2.5.3.7.3s.5-.1.7-.3c.4-.3.4-1 .1-1.4zm-10.2-1C5.5 17.5 2 14 2 9.8S5.5 2 9.8 2s7.8 3.5 7.8 7.8-3.6 7.7-7.8 7.7z" />
          </svg>
        </button>
        <transition name="slide-left">
          <HeaderSearch v-if="searchOpen" :mobile="true" @close="hideSearch()" />
        </transition>
      </div>

      <div class="c-header-tools__item c-header-tools__item--basket">
        <DropdownMenu
          id="basket"
          basket
          :forceshow="showBasket"
          :new-product="newProduct"
          :has-active-class="nbProducts > 0"
          @hoverOn="showBasket = nbProducts > 0 ? true : false"
          @hoverOff="showBasket = false"
        >
          <template #button>
            <nuxt-link :to="localePath('/checkout/basket')" class="c-header-tools__button" data-cy="basket-link">
              <span v-if="nbProducts > 0 && !loading" class="c-header-tools__numbers" data-cy="basket-count">
                {{ nbProducts }}
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.5 21.2">
                <title>{{ $t('components.molecules.header.headerTools.basket') }}</title>
                <path d="M4.2 4.9v.8H1c-.6 0-1 .4-1 1v13.5c0 .6.4 1 1 1h15.5c.6 0 1-.4 1-1V6.7c0-.5-.4-1-1-1h-3.2V5v-.1-.3c0-2.5-2-4.5-4.5-4.5S4.2 2 4.2 4.5m7 .4v.8h-5V5v-.3-.2C6.1 3.1 7.4 2 8.7 2c1.4 0 2.5 1.1 2.5 2.5m4.3 14.7H2V7.7h2.2V10c0 .6.4 1 1 1s1-.4 1-1V7.7h5V10c0 .6.4 1 1 1s1-.4 1-1V7.7h2.2v11.5h.1z" />
              </svg>
            </nuxt-link>
          </template>
          <template #default="{ hide }">
            <Basket
              v-if="showBasket && nbProducts > 0 && !loading"
              :added-new-product="newProduct"
              @close="newProduct = false; showBasket = false; hide()"
            />
          </template>
        </DropdownMenu>
      </div>

      <div class="c-header-tools__item c-header-tools__item--lang">
        <DropdownMenu
          id="lang"
          lang
        >
          <template #button>
            <nuxt-link :to="localePath('/')" :event="''" class="c-header-tools__button" data-cy="lang-link">
              <img
                :src="currentInterStore.pictureFlagUrl"
                :alt="currentInterStore.displayName"
                width="15"
                height="11"
              >
            </nuxt-link>
          </template>
          <template #default="{ hide }">
            <InternationalLangList v-if="!loading" @close="hide()" />
          </template>
        </DropdownMenu>
      </div>
      <button v-if="isListing && fixed" class="c-header-tools__filter u-visible@tablet" @click="setShowFilterMobile(true)">
        {{ $t('components.molecules.header.headerTools.filter') }}
        <span v-if="nbFilters > 0" class="c-header-tools__filter-nb">
          {{ nbFilters }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { EventBus } from '@/helpers/EventBus.js'

import AccountIcon from '@/components/atoms/icons/Account'
import DropdownMenu from '@/components/molecules/header/dropdown-menu/DropdownMenu'
import HeaderSearch from '@/components/molecules/header/header-search/HeaderSearch'
import HeartIcon from '@/components/atoms/icons/Heart'
import InternationalLangList from '@/components/molecules/header/international-lang-list/InternationalLangList'
import UserAccount from '@/components/molecules/header/user-account/UserAccount'
import { CMS_SLUG } from '@/config/cms/config'

export default {
  components: {
    AccountIcon,
    Basket: () => import('@/components/molecules/header/basket/Basket'),
    DropdownMenu,
    HeaderSearch,
    HeartIcon,
    InternationalLangList,
    UserAccount
  },
  props: {
    isListing: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchOpen: false,
      showBasket: false,
      newProduct: false,
      loading: true,
      CMS_SLUG
    }
  },
  computed: {
    ...mapGetters({
      nbProducts: 'basket/nbProducts',
      nbFavorites: 'wishlist/nbProducts',
      nbFilters: 'listing/nbFilters'
    }),
    ...mapState({
      loggedIn: state => state.auth?.loggedIn,
      currentInterStore: state => state.international?.currentInterStore
    })
  },
  created () {
    this.$nextTick(function () {
      this.loading = false
    })
  },
  mounted () {
    window.addEventListener('storage', e => this.sychronizer(e.key))
    EventBus.$on('addProductToBasket', () => {
      this.handleAddProductToBasket()
    })
    if (this.loggedIn) {
      this.getFavorites({
        instance: this.$api
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener('storage', e => this.sychronizer(e.key))
    EventBus.$off('addProductToBasket')
  },
  methods: {
    sychronizer (key) {
      if (key === 'vuex') {
        this.synchronizerStorage()
      }
    },
    ...mapActions({
      synchronizerStorage: 'basket/synchronizerStorage',
      setShowFilterMobile: 'listing/setShowFilterMobile',
      getFavorites: 'wishlist/getProducts'
    }),
    handleAddProductToBasket () {
      this.showBasket = true
      this.newProduct = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.showBasket = false
        this.newProduct = false
      }, 3000)
    },
    showSearch () {
      document.body.classList.add('overflow-hidden')
      this.searchOpen = true
    },
    hideSearch () {
      document.body.classList.remove('overflow-hidden')
      this.searchOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-header-tools {
  $c: &;

  display: flex;
  flex: 0 0 21.5rem;
  align-items: center;
  justify-content: space-between;

  &__link {
    margin-right: 3.2rem;
    color: $white;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0.13rem;
    line-height: 1;
    text-decoration: none;
    transition: none;

    &:hover,
    &:focus {
      color: $primary !important;
    }

    @include mq($until: tablet) {
      display: none;
    }
  }

  &__list {
    display: flex;
    flex: 0 0 9.9rem;
    align-items: center;
    justify-content: space-between;

    @include mq($until: tablet) {
      flex: 0 0 auto;
      justify-content: flex-end;
    }
  }

  &__item {
    padding: 0 1.1rem;

    // Specificities tools display
    &--search {
      display: none;

      @include mq($until: tablet) {
        display: block;
      }
    }

    &--lang,
    &--account {
      @include mq($until: tablet) {
        display: none;
      }
    }

    &--favorite {
      #{$c}__numbers {
        background-color: $grey-1100;
      }
    }

    @include mq($until: tablet) {
      padding: 0 0.6rem;
    }
  }

  &__button {
    position: relative;
    display: flex;
    width: 2rem;
    height: 2.8rem;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0;
    border: none;
    background-color: transparent;
    color: $white;
    transition: none;

    svg {
      width: 2rem;
      height: 2rem;
      fill: currentcolor;

      @include mq($until: tablet) {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }

    &:not(:only-child) {
      &::after {
        position: absolute;
        top: 100%;
        left: 50%;
        display: none;
        width: 0;
        height: 0;
        border-width: 0 1rem 1rem;
        border-style: solid;
        border-color: transparent transparent $grey-800 transparent;
        background-color: blue;
        content: "";
        transform: translateX(-50%);
        transform-origin: center center;

        @include mq($until: tablet) {
          top: calc(100% + 0.4rem);
        }
      }

      &::before {
        position: absolute;
        top: 100%;
        left: 50%;
        display: none;
        width: 5rem;
        height: 2rem;
        background-color: red;
        content: "";
        transform: translateX(-50%);
      }
    }

    &:hover,
    &:focus {
      color: $white;
    }
  }

  &__numbers {
    position: absolute;
    bottom: 69%;
    left: 90%;
    display: flex;
    width: 1.7rem;
    height: 1.7rem;
    align-items: center;
    justify-content: center;
    margin: 0;
    background-color: $primary-light;
    border-radius: 50%;
    color: $white;
    font-size: $font-size-xs-mobile;
    font-weight: 500;
    line-height: 1.2;

    @include mq($until: tablet) {
      width: 1.4rem;
      height: 1.4rem;
      font-size: 0.8rem;
    }
    &#{$c}__numbers--hidden {
      display: none;
    }
  }

  &__filter {
    position: relative;
    display: none;
    padding-right: 1rem;
    padding-left: 2rem;
    margin-left: 1.5rem;
    text-transform: uppercase;

    &::before {
      position: absolute;
      top: -2rem;
      left: 0;
      height: 5.2rem;
      border-left: 0.1rem solid $grey-1300;
      content: "";
    }
  }

  &__filter-nb {
    position: absolute;
    top: 0%;
    right: -0.5rem;
    width: 1.4rem;
    height: 1.4rem;
    padding: 0.2rem;
    background: $accent-light;
    border-radius: 50%;
    color: $white;
    font-size: 0.8rem;
    transform: translateY(-100%);
  }

  @include mq($until: tablet) {
    flex: 0 0 auto;
  }
}

// Regle pour survol des header tools
// stylelint-disable-next-line selector-class-pattern
.c-header--white {
  // stylelint-disable-next-line selector-class-pattern
  .c-header-tools__item {
    &:hover {
      // stylelint-disable-next-line selector-class-pattern
      .c-header-tools__button {
        svg {
          fill: $primary;
        }
      }
    }
  }
}
</style>
