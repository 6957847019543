import { WATERMARK } from '@/config'

export function getCloudinaryWatermarkParam (url, isDesktop) {
  let watermarkUrl = new URL(url)
  const options = {
    size: isDesktop ? WATERMARK.SIZE : WATERMARK.SIZE_MOBILE,
    position: WATERMARK.POSITION,
    marginX: isDesktop ? WATERMARK.MARGIN_X : WATERMARK.MARGIN_X_MOBILE,
    marginY: isDesktop ? WATERMARK.MARGIN_Y : WATERMARK.MARGIN_Y_MOBILE,
    opacity: WATERMARK.OPACITY
  }
  watermarkUrl = watermarkUrl.pathname.split('.')[0].replace(/\//g, ':').substring(1)
  return `w_${options.size},g_${options.position},x_${options.marginX},y_${options.marginY},o_${options.opacity},l_${watermarkUrl}`
}
