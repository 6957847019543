<template>
  <div class="c-header-tunnel">
    <div class="c-header-tunnel__logo">
      <Logo />
    </div>
    <div class="c-header-tunnel__secured-payment">
      <h3>{{ $t('components.organisms.layout.headerTunnel.securedPayment') }}</h3>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/atoms/global/logo/Logo'

export default {
  components: { Logo }
}
</script>

<style lang="scss" scoped>
.c-header-tunnel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6rem;
  background-color: $grey-1200;

  @include mq($until: tablet) {
    padding: 0 1.2rem;
  }

  &__logo {
    padding: 2.3rem 0;

    @include mq($until: tablet) {
      padding: 1.4rem 0;
    }

    svg {
      fill: $primary;

      @include mq($until: tablet) {
        width: auto;
        height: 2.9rem;
      }
    }
  }

  &__secured-payment {
    h3 {
      margin: 0;
      color: $grey-300;
      font-size: $font-size-xxl;
      font-weight: 900;
      text-transform: uppercase;

      @include mq($until: tablet) {
        font-size: $font-size-xl-mobile;
        font-weight: 500;
      }
    }
  }
}
</style>
