import DataStructure from './DataStructure'
// @TODO Finir ReturnOrderProduct model

export default class ReturnOrderReason extends DataStructure {
  constructor (options) {
    if (options === null || options === undefined) {
      throw new Error("options can't be null or undefined")
    }

    super()
    this.id = this.isset(options.id) ? this.convertToNumber(options.id) : null
    this.name = this.isset(options.name) ? this.convertToString(options.name) : null
    this.transportLabelFree = this.isset(options.transportLabelFree) ? this.convertToBoolean(options.transportLabelFree) : null
    this.active = this.isset(options.active) ? this.convertToBoolean(options.active) : null
    this.delete = this.isset(options.delete) ? this.convertToBoolean(options.delete) : null
  }
}
