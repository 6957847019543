import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1aa51f32 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _6197d6dc = () => interopDefault(import('../pages/autoconnect.vue' /* webpackChunkName: "pages/autoconnect" */))
const _1486f598 = () => interopDefault(import('../pages/-artists.vue' /* webpackChunkName: "" */))
const _08e2e552 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _6ed3a7e0 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4f12691f = () => interopDefault(import('../pages/-maintenance.vue' /* webpackChunkName: "" */))
const _a1806d32 = () => interopDefault(import('../pages/marques.vue' /* webpackChunkName: "pages/marques" */))
const _34d46c08 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _1b190b1e = () => interopDefault(import('../pages/wishlist.vue' /* webpackChunkName: "pages/wishlist" */))
const _730a92e0 = () => interopDefault(import('../pages/account/address-book/index.vue' /* webpackChunkName: "pages/account/address-book/index" */))
const _66d4d29b = () => interopDefault(import('../pages/account/card-book.vue' /* webpackChunkName: "pages/account/card-book" */))
const _1e25bdd0 = () => interopDefault(import('../pages/account/giftcard-activation.vue' /* webpackChunkName: "pages/account/giftcard-activation" */))
const _411823ac = () => interopDefault(import('../pages/account/history/index.vue' /* webpackChunkName: "pages/account/history/index" */))
const _fd66a016 = () => interopDefault(import('../pages/account/modify.vue' /* webpackChunkName: "pages/account/modify" */))
const _6f144e23 = () => interopDefault(import('../pages/account/modify-id.vue' /* webpackChunkName: "pages/account/modify-id" */))
const _aef3d8ea = () => interopDefault(import('../pages/account/subscriptions.vue' /* webpackChunkName: "pages/account/subscriptions" */))
const _ce3e05fa = () => interopDefault(import('../pages/account/voucher.vue' /* webpackChunkName: "pages/account/voucher" */))
const _6393da34 = () => interopDefault(import('../pages/checkout/add_address.vue' /* webpackChunkName: "pages/checkout/add_address" */))
const _4e9d6d66 = () => interopDefault(import('../pages/checkout/basket.vue' /* webpackChunkName: "pages/checkout/basket" */))
const _5cc36650 = () => interopDefault(import('../pages/checkout/confirm.vue' /* webpackChunkName: "pages/checkout/confirm" */))
const _2728c2d4 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _acf10964 = () => interopDefault(import('../pages/checkout/shipping.vue' /* webpackChunkName: "pages/checkout/shipping" */))
const _b5a7f66e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _88406570 = () => interopDefault(import('../pages/account/address-book/_id.vue' /* webpackChunkName: "pages/account/address-book/_id" */))
const _629f8897 = () => interopDefault(import('../pages/account/history/_id/index.vue' /* webpackChunkName: "pages/account/history/_id/index" */))
const _1785fe90 = () => interopDefault(import('../pages/account/history/_id/product-return-detail/index.vue' /* webpackChunkName: "pages/account/history/_id/product-return-detail/index" */))
const _95de1f60 = () => interopDefault(import('../pages/account/history/_id/product-return-detail/_idreturn.vue' /* webpackChunkName: "pages/account/history/_id/product-return-detail/_idreturn" */))
const _5e55eff2 = () => interopDefault(import('../pages/search/_query.vue' /* webpackChunkName: "pages/search/_query" */))
const _8554a874 = () => interopDefault(import('../pages/t/_name.vue' /* webpackChunkName: "pages/t/_name" */))
const _19db1670 = () => interopDefault(import('../pages/tp/_name.vue' /* webpackChunkName: "pages/tp/_name" */))
const _50610b37 = () => interopDefault(import('../pages/p/_.vue' /* webpackChunkName: "pages/p/_" */))
const _15f35554 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _1aa51f32,
    pathToRegexpOptions: {"strict":true},
    name: "account___fr"
  }, {
    path: "/account",
    component: _1aa51f32,
    pathToRegexpOptions: {"strict":true},
    name: "account___es"
  }, {
    path: "/account",
    component: _1aa51f32,
    pathToRegexpOptions: {"strict":true},
    name: "account___it"
  }, {
    path: "/account",
    component: _1aa51f32,
    pathToRegexpOptions: {"strict":true},
    name: "account___nl"
  }, {
    path: "/account",
    component: _1aa51f32,
    pathToRegexpOptions: {"strict":true},
    name: "account___de"
  }, {
    path: "/autoconnect",
    component: _6197d6dc,
    pathToRegexpOptions: {"strict":true},
    name: "autoconnect___fr"
  }, {
    path: "/autoconnect",
    component: _6197d6dc,
    pathToRegexpOptions: {"strict":true},
    name: "autoconnect___es"
  }, {
    path: "/autoconnect",
    component: _6197d6dc,
    pathToRegexpOptions: {"strict":true},
    name: "autoconnect___it"
  }, {
    path: "/autoconnect",
    component: _6197d6dc,
    pathToRegexpOptions: {"strict":true},
    name: "autoconnect___nl"
  }, {
    path: "/autoconnect",
    component: _6197d6dc,
    pathToRegexpOptions: {"strict":true},
    name: "autoconnect___de"
  }, {
    path: "/c-(.+)-(\\d+)",
    component: _1486f598,
    name: "artists___fr"
  }, {
    path: "/c-(.+)-(\\d+)",
    component: _1486f598,
    name: "artists___es"
  }, {
    path: "/c-(.+)-(\\d+)",
    component: _1486f598,
    name: "artists___it"
  }, {
    path: "/c-(.+)-(\\d+)",
    component: _1486f598,
    name: "artists___nl"
  }, {
    path: "/c-(.+)-(\\d+)",
    component: _1486f598,
    name: "artists___de"
  }, {
    path: "/contact",
    component: _08e2e552,
    pathToRegexpOptions: {"strict":true},
    name: "contact___fr"
  }, {
    path: "/contact",
    component: _08e2e552,
    pathToRegexpOptions: {"strict":true},
    name: "contact___es"
  }, {
    path: "/contact",
    component: _08e2e552,
    pathToRegexpOptions: {"strict":true},
    name: "contact___it"
  }, {
    path: "/contact",
    component: _08e2e552,
    pathToRegexpOptions: {"strict":true},
    name: "contact___nl"
  }, {
    path: "/contact",
    component: _08e2e552,
    pathToRegexpOptions: {"strict":true},
    name: "contact___de"
  }, {
    path: "/login",
    component: _6ed3a7e0,
    pathToRegexpOptions: {"strict":true},
    name: "login___fr"
  }, {
    path: "/login",
    component: _6ed3a7e0,
    pathToRegexpOptions: {"strict":true},
    name: "login___es"
  }, {
    path: "/login",
    component: _6ed3a7e0,
    pathToRegexpOptions: {"strict":true},
    name: "login___it"
  }, {
    path: "/login",
    component: _6ed3a7e0,
    pathToRegexpOptions: {"strict":true},
    name: "login___nl"
  }, {
    path: "/login",
    component: _6ed3a7e0,
    pathToRegexpOptions: {"strict":true},
    name: "login___de"
  }, {
    path: "/maintenance",
    component: _4f12691f,
    name: "maintenance___fr"
  }, {
    path: "/maintenance",
    component: _4f12691f,
    name: "maintenance___es"
  }, {
    path: "/maintenance",
    component: _4f12691f,
    name: "maintenance___it"
  }, {
    path: "/maintenance",
    component: _4f12691f,
    name: "maintenance___nl"
  }, {
    path: "/maintenance",
    component: _4f12691f,
    name: "maintenance___de"
  }, {
    path: "/marcas",
    component: _a1806d32,
    pathToRegexpOptions: {"strict":true},
    name: "marques___es"
  }, {
    path: "/marche",
    component: _a1806d32,
    pathToRegexpOptions: {"strict":true},
    name: "marques___it"
  }, {
    path: "/marken",
    component: _a1806d32,
    pathToRegexpOptions: {"strict":true},
    name: "marques___de"
  }, {
    path: "/marques",
    component: _a1806d32,
    pathToRegexpOptions: {"strict":true},
    name: "marques___fr"
  }, {
    path: "/merken",
    component: _a1806d32,
    pathToRegexpOptions: {"strict":true},
    name: "marques___nl"
  }, {
    path: "/unsubscribe",
    component: _34d46c08,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___fr"
  }, {
    path: "/unsubscribe",
    component: _34d46c08,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___es"
  }, {
    path: "/unsubscribe",
    component: _34d46c08,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___it"
  }, {
    path: "/unsubscribe",
    component: _34d46c08,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___nl"
  }, {
    path: "/unsubscribe",
    component: _34d46c08,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___de"
  }, {
    path: "/wishlist",
    component: _1b190b1e,
    pathToRegexpOptions: {"strict":true},
    name: "wishlist___fr"
  }, {
    path: "/wishlist",
    component: _1b190b1e,
    pathToRegexpOptions: {"strict":true},
    name: "wishlist___es"
  }, {
    path: "/wishlist",
    component: _1b190b1e,
    pathToRegexpOptions: {"strict":true},
    name: "wishlist___it"
  }, {
    path: "/wishlist",
    component: _1b190b1e,
    pathToRegexpOptions: {"strict":true},
    name: "wishlist___nl"
  }, {
    path: "/wishlist",
    component: _1b190b1e,
    pathToRegexpOptions: {"strict":true},
    name: "wishlist___de"
  }, {
    path: "/account/address-book",
    component: _730a92e0,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book___fr"
  }, {
    path: "/account/address-book",
    component: _730a92e0,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book___es"
  }, {
    path: "/account/address-book",
    component: _730a92e0,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book___it"
  }, {
    path: "/account/address-book",
    component: _730a92e0,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book___nl"
  }, {
    path: "/account/address-book",
    component: _730a92e0,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book___de"
  }, {
    path: "/account/card-book",
    component: _66d4d29b,
    pathToRegexpOptions: {"strict":true},
    name: "account-card-book___fr"
  }, {
    path: "/account/card-book",
    component: _66d4d29b,
    pathToRegexpOptions: {"strict":true},
    name: "account-card-book___es"
  }, {
    path: "/account/card-book",
    component: _66d4d29b,
    pathToRegexpOptions: {"strict":true},
    name: "account-card-book___it"
  }, {
    path: "/account/card-book",
    component: _66d4d29b,
    pathToRegexpOptions: {"strict":true},
    name: "account-card-book___nl"
  }, {
    path: "/account/card-book",
    component: _66d4d29b,
    pathToRegexpOptions: {"strict":true},
    name: "account-card-book___de"
  }, {
    path: "/account/giftcard-activation",
    component: _1e25bdd0,
    pathToRegexpOptions: {"strict":true},
    name: "account-giftcard-activation___fr"
  }, {
    path: "/account/giftcard-activation",
    component: _1e25bdd0,
    pathToRegexpOptions: {"strict":true},
    name: "account-giftcard-activation___es"
  }, {
    path: "/account/giftcard-activation",
    component: _1e25bdd0,
    pathToRegexpOptions: {"strict":true},
    name: "account-giftcard-activation___it"
  }, {
    path: "/account/giftcard-activation",
    component: _1e25bdd0,
    pathToRegexpOptions: {"strict":true},
    name: "account-giftcard-activation___nl"
  }, {
    path: "/account/giftcard-activation",
    component: _1e25bdd0,
    pathToRegexpOptions: {"strict":true},
    name: "account-giftcard-activation___de"
  }, {
    path: "/account/history",
    component: _411823ac,
    pathToRegexpOptions: {"strict":true},
    name: "account-history___fr"
  }, {
    path: "/account/history",
    component: _411823ac,
    pathToRegexpOptions: {"strict":true},
    name: "account-history___es"
  }, {
    path: "/account/history",
    component: _411823ac,
    pathToRegexpOptions: {"strict":true},
    name: "account-history___it"
  }, {
    path: "/account/history",
    component: _411823ac,
    pathToRegexpOptions: {"strict":true},
    name: "account-history___nl"
  }, {
    path: "/account/history",
    component: _411823ac,
    pathToRegexpOptions: {"strict":true},
    name: "account-history___de"
  }, {
    path: "/account/modify",
    component: _fd66a016,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify___fr"
  }, {
    path: "/account/modify",
    component: _fd66a016,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify___es"
  }, {
    path: "/account/modify",
    component: _fd66a016,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify___it"
  }, {
    path: "/account/modify",
    component: _fd66a016,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify___nl"
  }, {
    path: "/account/modify",
    component: _fd66a016,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify___de"
  }, {
    path: "/account/modify-id",
    component: _6f144e23,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify-id___fr"
  }, {
    path: "/account/modify-id",
    component: _6f144e23,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify-id___es"
  }, {
    path: "/account/modify-id",
    component: _6f144e23,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify-id___it"
  }, {
    path: "/account/modify-id",
    component: _6f144e23,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify-id___nl"
  }, {
    path: "/account/modify-id",
    component: _6f144e23,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify-id___de"
  }, {
    path: "/account/subscriptions",
    component: _aef3d8ea,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscriptions___fr"
  }, {
    path: "/account/subscriptions",
    component: _aef3d8ea,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscriptions___es"
  }, {
    path: "/account/subscriptions",
    component: _aef3d8ea,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscriptions___it"
  }, {
    path: "/account/subscriptions",
    component: _aef3d8ea,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscriptions___nl"
  }, {
    path: "/account/subscriptions",
    component: _aef3d8ea,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscriptions___de"
  }, {
    path: "/account/voucher",
    component: _ce3e05fa,
    pathToRegexpOptions: {"strict":true},
    name: "account-voucher___fr"
  }, {
    path: "/account/voucher",
    component: _ce3e05fa,
    pathToRegexpOptions: {"strict":true},
    name: "account-voucher___es"
  }, {
    path: "/account/voucher",
    component: _ce3e05fa,
    pathToRegexpOptions: {"strict":true},
    name: "account-voucher___it"
  }, {
    path: "/account/voucher",
    component: _ce3e05fa,
    pathToRegexpOptions: {"strict":true},
    name: "account-voucher___nl"
  }, {
    path: "/account/voucher",
    component: _ce3e05fa,
    pathToRegexpOptions: {"strict":true},
    name: "account-voucher___de"
  }, {
    path: "/checkout/add_address",
    component: _6393da34,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-add_address___fr"
  }, {
    path: "/checkout/add_address",
    component: _6393da34,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-add_address___es"
  }, {
    path: "/checkout/add_address",
    component: _6393da34,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-add_address___it"
  }, {
    path: "/checkout/add_address",
    component: _6393da34,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-add_address___nl"
  }, {
    path: "/checkout/add_address",
    component: _6393da34,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-add_address___de"
  }, {
    path: "/checkout/basket",
    component: _4e9d6d66,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-basket___fr"
  }, {
    path: "/checkout/basket",
    component: _4e9d6d66,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-basket___es"
  }, {
    path: "/checkout/basket",
    component: _4e9d6d66,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-basket___it"
  }, {
    path: "/checkout/basket",
    component: _4e9d6d66,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-basket___nl"
  }, {
    path: "/checkout/basket",
    component: _4e9d6d66,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-basket___de"
  }, {
    path: "/checkout/confirm",
    component: _5cc36650,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-confirm___fr"
  }, {
    path: "/checkout/confirm",
    component: _5cc36650,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-confirm___es"
  }, {
    path: "/checkout/confirm",
    component: _5cc36650,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-confirm___it"
  }, {
    path: "/checkout/confirm",
    component: _5cc36650,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-confirm___nl"
  }, {
    path: "/checkout/confirm",
    component: _5cc36650,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-confirm___de"
  }, {
    path: "/checkout/payment",
    component: _2728c2d4,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-payment___fr"
  }, {
    path: "/checkout/payment",
    component: _2728c2d4,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-payment___es"
  }, {
    path: "/checkout/payment",
    component: _2728c2d4,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-payment___it"
  }, {
    path: "/checkout/payment",
    component: _2728c2d4,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-payment___nl"
  }, {
    path: "/checkout/payment",
    component: _2728c2d4,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-payment___de"
  }, {
    path: "/checkout/shipping",
    component: _acf10964,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-shipping___fr"
  }, {
    path: "/checkout/shipping",
    component: _acf10964,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-shipping___es"
  }, {
    path: "/checkout/shipping",
    component: _acf10964,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-shipping___it"
  }, {
    path: "/checkout/shipping",
    component: _acf10964,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-shipping___nl"
  }, {
    path: "/checkout/shipping",
    component: _acf10964,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-shipping___de"
  }, {
    path: "/",
    component: _b5a7f66e,
    pathToRegexpOptions: {"strict":true},
    name: "index___fr"
  }, {
    path: "/",
    component: _b5a7f66e,
    pathToRegexpOptions: {"strict":true},
    name: "index___es"
  }, {
    path: "/",
    component: _b5a7f66e,
    pathToRegexpOptions: {"strict":true},
    name: "index___it"
  }, {
    path: "/",
    component: _b5a7f66e,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }, {
    path: "/",
    component: _b5a7f66e,
    pathToRegexpOptions: {"strict":true},
    name: "index___de"
  }, {
    path: "/account/address-book/:id?",
    component: _88406570,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book-id___fr"
  }, {
    path: "/account/address-book/:id?",
    component: _88406570,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book-id___es"
  }, {
    path: "/account/address-book/:id?",
    component: _88406570,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book-id___it"
  }, {
    path: "/account/address-book/:id?",
    component: _88406570,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book-id___nl"
  }, {
    path: "/account/address-book/:id?",
    component: _88406570,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book-id___de"
  }, {
    path: "/account/history/:id",
    component: _629f8897,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id___fr"
  }, {
    path: "/account/history/:id",
    component: _629f8897,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id___es"
  }, {
    path: "/account/history/:id",
    component: _629f8897,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id___it"
  }, {
    path: "/account/history/:id",
    component: _629f8897,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id___nl"
  }, {
    path: "/account/history/:id",
    component: _629f8897,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id___de"
  }, {
    path: "/account/history/:id/product-return-detail",
    component: _1785fe90,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail___fr"
  }, {
    path: "/account/history/:id/product-return-detail",
    component: _1785fe90,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail___es"
  }, {
    path: "/account/history/:id/product-return-detail",
    component: _1785fe90,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail___it"
  }, {
    path: "/account/history/:id/product-return-detail",
    component: _1785fe90,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail___nl"
  }, {
    path: "/account/history/:id/product-return-detail",
    component: _1785fe90,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail___de"
  }, {
    path: "/account/history/:id/product-return-detail/:idreturn?",
    component: _95de1f60,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail-idreturn___fr"
  }, {
    path: "/account/history/:id/product-return-detail/:idreturn?",
    component: _95de1f60,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail-idreturn___es"
  }, {
    path: "/account/history/:id/product-return-detail/:idreturn?",
    component: _95de1f60,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail-idreturn___it"
  }, {
    path: "/account/history/:id/product-return-detail/:idreturn?",
    component: _95de1f60,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail-idreturn___nl"
  }, {
    path: "/account/history/:id/product-return-detail/:idreturn?",
    component: _95de1f60,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail-idreturn___de"
  }, {
    path: "/autoconnect/:token",
    component: _6197d6dc
  }, {
    path: "/autoconnect/:token",
    component: _6197d6dc
  }, {
    path: "/autoconnect/:token",
    component: _6197d6dc
  }, {
    path: "/autoconnect/:token",
    component: _6197d6dc
  }, {
    path: "/autoconnect/:token",
    component: _6197d6dc
  }, {
    path: "/search/:query?",
    component: _5e55eff2,
    pathToRegexpOptions: {"strict":true},
    name: "search-query___fr"
  }, {
    path: "/search/:query?",
    component: _5e55eff2,
    pathToRegexpOptions: {"strict":true},
    name: "search-query___es"
  }, {
    path: "/search/:query?",
    component: _5e55eff2,
    pathToRegexpOptions: {"strict":true},
    name: "search-query___it"
  }, {
    path: "/search/:query?",
    component: _5e55eff2,
    pathToRegexpOptions: {"strict":true},
    name: "search-query___nl"
  }, {
    path: "/search/:query?",
    component: _5e55eff2,
    pathToRegexpOptions: {"strict":true},
    name: "search-query___de"
  }, {
    path: "/t/:name?",
    component: _8554a874,
    pathToRegexpOptions: {"strict":true},
    name: "t-name___fr"
  }, {
    path: "/t/:name?",
    component: _8554a874,
    pathToRegexpOptions: {"strict":true},
    name: "t-name___es"
  }, {
    path: "/t/:name?",
    component: _8554a874,
    pathToRegexpOptions: {"strict":true},
    name: "t-name___it"
  }, {
    path: "/t/:name?",
    component: _8554a874,
    pathToRegexpOptions: {"strict":true},
    name: "t-name___nl"
  }, {
    path: "/t/:name?",
    component: _8554a874,
    pathToRegexpOptions: {"strict":true},
    name: "t-name___de"
  }, {
    path: "/tp/:name?",
    component: _19db1670,
    pathToRegexpOptions: {"strict":true},
    name: "tp-name___fr"
  }, {
    path: "/tp/:name?",
    component: _19db1670,
    pathToRegexpOptions: {"strict":true},
    name: "tp-name___es"
  }, {
    path: "/tp/:name?",
    component: _19db1670,
    pathToRegexpOptions: {"strict":true},
    name: "tp-name___it"
  }, {
    path: "/tp/:name?",
    component: _19db1670,
    pathToRegexpOptions: {"strict":true},
    name: "tp-name___nl"
  }, {
    path: "/tp/:name?",
    component: _19db1670,
    pathToRegexpOptions: {"strict":true},
    name: "tp-name___de"
  }, {
    path: "/search/:query/*",
    component: _5e55eff2,
    name: "search___de"
  }, {
    path: "/search/:query/*",
    component: _5e55eff2,
    name: "search___nl"
  }, {
    path: "/search/:query/*",
    component: _5e55eff2,
    name: "search___it"
  }, {
    path: "/search/:query/*",
    component: _5e55eff2,
    name: "search___es"
  }, {
    path: "/search/:query/*",
    component: _5e55eff2,
    name: "search___fr"
  }, {
    path: "/p/*",
    component: _50610b37,
    pathToRegexpOptions: {"strict":true},
    name: "p-all___de"
  }, {
    path: "/p/*",
    component: _50610b37,
    pathToRegexpOptions: {"strict":true},
    name: "p-all___nl"
  }, {
    path: "/p/*",
    component: _50610b37,
    pathToRegexpOptions: {"strict":true},
    name: "p-all___it"
  }, {
    path: "/p/*",
    component: _50610b37,
    pathToRegexpOptions: {"strict":true},
    name: "p-all___es"
  }, {
    path: "/p/*",
    component: _50610b37,
    pathToRegexpOptions: {"strict":true},
    name: "p-all___fr"
  }, {
    path: "/*",
    component: _15f35554,
    pathToRegexpOptions: {"strict":true},
    name: "all___de"
  }, {
    path: "/*",
    component: _15f35554,
    pathToRegexpOptions: {"strict":true},
    name: "all___nl"
  }, {
    path: "/*",
    component: _15f35554,
    pathToRegexpOptions: {"strict":true},
    name: "all___it"
  }, {
    path: "/*",
    component: _15f35554,
    pathToRegexpOptions: {"strict":true},
    name: "all___es"
  }, {
    path: "/*",
    component: _15f35554,
    pathToRegexpOptions: {"strict":true},
    name: "all___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
