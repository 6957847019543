import { MAINTENANCE } from '@/config'

export default function ({ redirect, route }) {
  if (MAINTENANCE) {
    return redirect('/maintenance')
  }
  if (!MAINTENANCE && route.path === '/maintenance') {
    return redirect('/')
  }
}
