import DataStructure from './DataStructure'

export default class PromotionValidity extends DataStructure {
  constructor (options) {
    if (options === null || options === undefined) {
      throw new Error("Options can't be null nor undefined")
    }

    super()
    this.valid = options.valid ? options.valid : null
    this.message = options.message ? options.message : null
    this.allowedDevices = options.allowedDevices ? this.getallowedDevices(options.allowedDevices) : null
  }

  getallowedDevices (allowedDevices) {
    if (!allowedDevices || !allowedDevices.length) {
      return null
    }
    return allowedDevices
  }
}
