<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 18.7"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <path d="M3.5 18.7l-.6-.2c-.4-.2-.5-.7-.4-1.1l1-3.2C1.3 12.6 0 10.5 0 8.4 0 3.7 5.5 0 12.5 0S25 3.7 25 8.4s-5.5 8.4-12.5 8.4c-1.8 0-3.6-.3-5.2-.8l-3.2 2.5-.6.2zm9-16.7C6.8 2 2 4.9 2 8.4c0 1.7 1.1 3.3 3.2 4.5.4.2.6.7.4 1.1l-.3 1 1.2-.9c.3-.2.6-.3.9-.2 1.6.5 3.3.8 5.1.8 5.7 0 10.5-2.9 10.5-6.4S18.2 2 12.5 2z" />
    <circle
      cx="12.5"
      cy="8.4"
      r="1.5"
    />
    <circle
      cx="16.9"
      cy="8.4"
      r="1.5"
    />
    <circle
      cx="8.1"
      cy="8.4"
      r="1.5"
    />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
