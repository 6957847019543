export default function (_context, inject) {
  if (typeof window === "undefined") {
    // eslint-disable-next-line no-console
    console.warn('[[ localStoragePlugin:: could not inject vueLocalStorage as window is undefined')
    return 0
  }

  try {
    const ls = window.localStorage
    inject('localStorage', ls)
  } catch (err) {
     // eslint-disable-next-line no-console
    console.error(err);
  }
}