<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37px"
    height="26px"
    viewBox="0 0 37.3 26.1"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <path d="M0 14.8v-3.5L.5 6C.7 3.1 2.1 1.1 4.3.7 5.6.4 6.9.2 8.2.2L22.6 0c3.3 0 6.5.4 9.8.6 2.6.2 4.1 2.1 4.4 4.8.6 5.1.6 10.2 0 15.2-.3 2.5-1.7 4.3-3.7 4.7a52 52 0 0 1-3.9.5c-4.8.1-9.6.3-14.4.2-3.5 0-6.9-.4-10.4-.7-.7-.1-1.5-.5-2.1-1C.8 23.2.6 21.5.4 19.9L0 14.8zm14.9-7.4v11.3l9.9-5.6-9.9-5.7z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
