<template>
  <div>
    <div
      ref="header"
      class="c-header js-header-fix-listing"
      :class="{
        'c-header--white': !transparentHeader,
        'c-header--up': scroll === 'up',
        'c-header--down': scroll === 'down',
        'c-header--fixed': fixed,
        'c-header--inter': isInternationalDisplay
      }"
    >
      <div class="c-header__top">
        <button
          class="c-header__toggle u-hidden u-visible@tablet"
          @click="toggle"
        >
          <MenuIcon title="menu" />
        </button>
        <div class="c-header__logo">
          <nuxt-link
            :to="localePath('/')"
            @click.native="checkRedirect('/')"
          >
            <LogoLBO v-if="!isInternationalDisplay" :title="$t('components.organisms.layout.header.logoTitle')" />
            <LogoRYSES v-else :title="currentInterStore.displayName" />
          </nuxt-link>
        </div>
        <HeaderSearch />
        <HeaderTools :is-listing="isListing" :fixed="fixed" />
      </div>
      <div v-if="$store.state.ui.deviceType === 'pc'" class="c-header__bottom">
        <VMenu
          :menu="menu"
        />
      </div>
    </div>
    <MenuMobile
      :is-open="isMenuMobileOpen"
      :menu="menu"
      @close="close"
      @generalClose="generalClose"
    />
  </div>
</template>

<script>
import { watchViewport } from 'tornis'
import { mapState, mapGetters } from 'vuex'
import { EventBus } from '@/helpers/EventBus.js'
import { sendSentryMessage } from '@/helpers/errorHelper'

import HeaderSearch from '@/components/molecules/header/header-search/HeaderSearch'
import HeaderTools from '@/components/molecules/header/header-tools/HeaderTools'
import LogoLBO from '@/components/atoms/icons/LogoLBO'
import LogoRYSES from '@/components/atoms/icons/LogoRYSES'
import MenuIcon from '@/components/atoms/icons/Menu'
import MenuMobile from '@/components/molecules/header/menu-mobile/MenuMobile'
import VMenu from '@/components/molecules/header/menu/VMenu'

export default {
  components: {
    HeaderSearch,
    HeaderTools,
    LogoLBO,
    LogoRYSES,
    MenuIcon,
    MenuMobile,
    VMenu
  },
  props: {
    isListing: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isMenuMobileOpen: false,
      scroll: null,
      fixed: false,
      intervalId: null
    }
  },

  computed: {
    ...mapState({
      menu: state => state.website?.menu,
      currentInterStore: state => state.international && state.international.currentInterStore,
      transparentHeader: state => state.ui.transparentHeader
    }),
    ...mapGetters({ isInternationalDisplay: 'international/isInternationalDisplay' })
  },
  mounted () {
    this.intervalId = setInterval(this.updateMenu, +process.env.MENU_MAX_LIFETIME)
    const headerMenu = this.$refs.header
    const extraElement = document.querySelector('.js-extra-elements')
    const headerHeight = headerMenu.offsetHeight

    let distanceUp = 0

    const hasScrolled = (scroll) => {
      const extraElementHeight = extraElement !== null ? extraElement.offsetHeight : 0
      if (scroll.top <= extraElementHeight) {
        this.fixed = false
        this.scroll = null
      } else if (
        scroll.velocity.y > 0 &&
        scroll.top > headerHeight + extraElementHeight
      ) {
        // Scroll Down
        distanceUp = 0
        if (this.scroll === 'up') {
          this.scroll = 'down'
        } else {
          this.fixed = true
          setTimeout(() => { this.scroll = 'down' }, 20)
        }
      } else if (scroll.velocity.y < 0) {
        // Scroll Up
        distanceUp += Math.abs(scroll.velocity.y)
        if (this.fixed && (Math.abs(scroll.velocity.y) > 30 || distanceUp > 300)) {
          this.scroll = 'up'
        }
      }
    }
    EventBus.$on('addProductToBasket', () => {
      this.handleAddProductToBasket()
    })
    watchViewport(({ scroll }) => {
      if (scroll.changed) {
        if (!this.clickOnOpenLink) {
          hasScrolled(scroll)
        }
        setTimeout(() => {
          this.clickOnOpenLink = false
        }, 50)
      }
    })
  },
  beforeDestroy () {
    EventBus.$off('addProductToBasket')
    EventBus.$off('setHeaderWhiteColor')
    clearInterval(this.intervalId)
  },
  methods: {
    checkRedirect (path) {
      if (this.$route.path === path) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    handleAddProductToBasket () {
      this.fixed = true
      this.scroll = 'up'
    },
    toggle () {
      this.isMenuMobileOpen = !this.isMenuMobileOpen
    },
    close () {
      this.isMenuMobileOpen = false
    },
    generalClose () {
      this.isMenuMobileOpen = false
    },
    async updateMenu () {
      try {
        const menu = await this.$getMenu()
        this.$store.dispatch('website/getMenu', menu)
      } catch (err) {
        sendSentryMessage.bind(this, 'Unable to update menu (header)', 'error', {
          action: 'updateMenu',
          call: 'getMenu',
          exception: err
        })
      }
    }
  }
}
</script>

<style lang="scss">
.c-header {
  $c: &;

  position: absolute;
  z-index: 11;
  top: 0;
  width: 100%;
  max-width: 136.6rem;
  margin: 0 auto;
  background-color: rgba($white, 0.01);

  @include mq($until: tablet) {
    display: flex;
    height: 5.2rem;
  }

  svg {
    fill: currentcolor;
  }

  &__top {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.3rem 6rem;
    border-bottom: 0.1rem solid $white;

    > div {
      &:first-child {
        flex: 0 1 19rem;

        @include mq($until: tablet) {
          flex: 0 1 auto;
        }
      }
    }

    @include mq($until: tablet) {
      width: 100%;
      padding: 0;
      padding-right: 1.4rem;
      padding-left: 1.2rem;
      border-bottom: none;

      > div {
        &:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &__bottom {
    position: relative;
    z-index: 1;
    border-bottom: 0.1rem solid $white;

    @include mq($until: tablet) {
      display: none;
    }
  }

  &__toggle {
    // display: none;
    height: 1.7rem;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    border: none;
    margin-right: 2.6rem;
    background-color: transparent;
    color: $white;

    svg {
      width: 3.4rem;
      height: 1.7rem;

      @include mq($until: tablet) {
        width: 2.1rem;
      }
    }

    @include mq($until: tablet) {
      display: flex;
    }
  }

  &__logo {
    flex-grow: 1;

    a {
      display: block;
      width: 18.9rem;
      height: auto;
      color: $white;
      transition: none;

      &:hover,
      &:focus {
        color: $white;
      }

      @include mq($until: tablet) {
        max-width: 10.8rem;

        > svg {
          width: 100%;
        }
      }
    }
  }

  &--white {
    // stylelint-disable-next-line selector-class-pattern
    .c-header-tools__item--active {
      // stylelint-disable-next-line selector-class-pattern
      .c-header-tools__button {
        svg {
          color: $primary;
        }
      }
    }
  }

  &--white,
  &--fixed {
    background-color: $white;

    // stylelint-disable-next-line selector-class-pattern
    .c-header__bottom, // stylelint-disable-next-line selector-class-pattern
    .c-header__top {
      border-bottom: 0.1rem solid $grey-1100;
    }

    // stylelint-disable-next-line selector-class-pattern
    .c-header__logo {
      a {
        color: $primary !important;
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    .c-header__toggle {
      color: $grey-300;
    }

    // stylelint-disable-next-line selector-class-pattern
    .c-header-search__form {
      .c-btn-with-input {
        .c-btn,
        input {
          border-color: $grey-1100;
          color: $grey-300;
        }

        input {
          &::placeholder {
            color: $grey-300;
          }
        }
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    .c-header-tools__link {
      color: $grey-300;
    }

    // stylelint-disable-next-line selector-class-pattern
    .c-header-tools__button {
      color: $grey-300 !important;
    }

    // stylelint-disable-next-line selector-class-pattern
    .c-header__toggle > span {
      background-color: $grey-300;
    }
    &#{$c}--inter {
      // stylelint-disable-next-line selector-class-pattern
      .c-header__logo {
        a {
          color: $black !important;
        }
      }
    }
  }

  &--fixed {
    position: fixed;
    transform: translateY(-100%);
    &#{$c}--down {
      transition: $default-transition;
    }
    &#{$c}--up {
      transform: translateY(0);
      transition: $default-transition;
    }
  }
}
</style>
