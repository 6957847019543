import DataStructure from './DataStructure'

export default class Address extends DataStructure {
  constructor (address) {
    super()
    if (address === undefined || address === null) {
      throw new Error("Address object can't be null")
    }
    this.id = this.isset(address.id) ? this.convertToNumber(address.id) : null
    this.label = this.isset(address.label) ? this.convertToString(address.label) : null
    this.civility = this.isset(address.civility) ? this.convertToNumber(address.civility) : null
    this.lastName = this.isset(address.lastName) ? this.convertToString(address.lastName) : null
    this.firstName = this.isset(address.firstName) ? this.convertToString(address.firstName) : null
    this.street = this.isset(address.street) ? this.convertToString(address.street) : null
    this.streetDetails = this.isset(address.streetDetails) ? this.convertToString(address.streetDetails) : null
    this.zipCode = this.isset(address.zipCode) ? this.convertToString(address.zipCode) : null
    this.city = this.isset(address.city) ? this.convertToString(address.city) : null
    this.state = this.isset(address.state) ? this.convertToString(address.state) : null
    this.countryIsoCode2 = this.isset(address.countryIsoCode2) ? this.convertToString(address.countryIsoCode2) : null
    this.countryName = this.isset(address.countryName) ? this.convertToString(address.countryName) : null
    this.activeCountry = this.isset(address.activeCountry) ? this.convertToBoolean(address.activeCountry) : null
    this.phoneNumber = this.isset(address.phoneNumber) ? this.convertToString(address.phoneNumber) : null
    this.company = this.isset(address.company) ? this.convertToString(address.company) : null
    this.default = this.isset(address.default) ? this.convertToBoolean(address.default) : null
  }
}
