import { TYPE } from '@/config/algolia/config'

function togglepopinOutlet (from, redirect, store) {
  store.dispatch('togglePopinOutlet', true)
  if (from) {
    return redirect(from.path)
  } else {
    return redirect(302, '/', { outlet: true })
  }
}
export default async function ({ $cookies, $isOutletAccessAllowed, from, redirect, store, page }) {
  if (page && page.pageInfo && page.pageInfo.type === TYPE.OUTLET) {
    const email = store.state.auth?.user?.email || $cookies.get('outletEmail') || null
    if (email) {
      if (!store.state.outletAccessAllowed) {
        try {
          await $isOutletAccessAllowed(email)
          store.dispatch('setOutletAccessAllowed', true)
        } catch (e) {
          togglepopinOutlet(from, redirect, store)
        }
      }
    } else {
      togglepopinOutlet(from, redirect, store)
    }
  }
}
