import Vue from 'vue'

export default ({ app }, inject) => {
  const format = app.i18n.locales.find(locale => locale.code === app.i18n.locale).iso

  const currency = (value) => {
    const formatter = new Intl.NumberFormat(format, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    })

    if (typeof value !== 'number') {
      return value
    }
    return formatter.format(value)
  }
  inject('currency', currency)

  Vue.filter('currency', currency)
}
