<template>
  <div
    class="c-main-heading"
    :class="{
      'c-main-heading--sale': sale,
      'c-main-heading--404': error,
      'c-main-heading--small-title': smallTitle,
    }"
  >
    <p v-if="subtitle" class="c-main-heading__subtitle" :class="{'u-align--left': titleLeft, 'u-align--center@tablet': titleLeft}">
      {{ subtitle }}
    </p>
    <h2
      class="c-main-heading__title"
      :class="{
        'u-align--left': titleLeft,
        'u-align--center@tablet': titleLeft,
        'c-main-heading__title--noCap': noCapitalize
      }"
    >
      {{ title }}
    </h2>
    <p v-if="!!$slots.default" class="c-main-heading__txt">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    sale: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    titleLeft: {
      type: Boolean,
      default: false
    },
    smallTitle: {
      type: Boolean,
      default: false
    },
    noCapitalize: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-main-heading {
  $c: &;

  text-align: center;

  &__subtitle {
    margin: 0;
    margin-bottom: 2.1rem;
    color: $primary-light;
    font-size: $font-size-sm;
    font-weight: 400;
    letter-spacing: 2.6rem;
    line-height: 1;
    text-indent: 2.6rem;
    text-transform: uppercase;

    @include mq($until: tablet) {
      margin-bottom: 0.8rem;
      font-size: $font-size-sm-mobile;
      font-weight: 300;
      letter-spacing: 1.838rem;
      text-indent: 1.838rem;
    }
  }

  &__title {
    margin: 0;
    color: $grey-300;
    font-size: 4.5rem;
    font-weight: 900;
    letter-spacing: 0.135rem;
    line-height: 1;
    text-transform: capitalize;
    &#{$c}__title--noCap {
      text-transform: none;
    }

    @include mq($until: tablet) {
      font-size: 3rem;
      letter-spacing: 0.09rem;
    }
  }

  &--small-title {
    #{$c}__title {
      font-size: 3rem;
    }
  }

  &__txt {
    margin: 0;
    margin-top: 2.2rem;
    color: $grey-300;
    font-size: $font-size-lg;
    font-weight: 300;
    line-height: 1.797rem;

    @include mq($until: tablet) {
      margin-top: 1.3rem;
      font-size: $font-size-lg-mobile;
    }
  }

  &--sale {
    text-align: center;
    #{$c}__subtitle {
      margin-bottom: 1rem;
    }
    #{$c}__title {
      font-size: 3rem;
      text-transform: uppercase;
    }

    * {
      color: $white;
    }
  }

  &--404 {
    padding-top: 6.4rem;
    margin-bottom: 5rem;

    @include mq($until: tablet) {
      margin-bottom: 3.6rem;
      font-size: $font-size-xxl-mobile;
    }
    #{$c}__title {
      font-size: 4rem;
      line-height: 1;

      @include mq($until: tablet) {
        font-size: $font-size-xxl-mobile;
      }
    }
    #{$c}__txt {
      margin-top: 1.7rem;
      color: $grey-200;
      font-size: $font-size-sm;
      font-weight: 500;
      line-height: 1.991rem;

      @include mq($until: tablet) {
        margin-top: 1.3rem;
        color: $grey-300;
        font-size: $font-size-sm-mobile;
        font-weight: 400;
        line-height: 1.5rem;
      }
    }
  }
}
</style>
