<template>
  <div
    class="c-skeleton-main-heading"
    :class="{'c-skeleton-main-heading--small': small}"
  >
    <div v-if="sub" class="c-skeleton-main-heading__sub thumbnail-skeleton-animation" />
    <div class="c-skeleton-main-heading__title thumbnail-skeleton-animation" />
  </div>
</template>
<script>
export default {
  props: {
    sub: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean
    }
  }
}
</script>
<style lang="scss" scoped>
.c-skeleton-main-heading {
  $c: &;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  text-align: center;

  &--small {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;

    #{$c}__title {
      height: 4.5rem;
    }
  }

  > div {
    display: inline-block;
    border-radius: 0.5rem;
  }

  &__sub {
    width: 33rem;
    height: 1.3rem;
    margin-bottom: 2.1rem;

    @include mq($until: tablet) {
      width: 24rem;
      height: 1.1rem;
      margin-bottom: 0.8rem;
    }
  }

  &__title {
    width: 48rem;
    height: 5.3rem;

    @include mq($until: tablet) {
      width: 35rem;
      height: 3rem;
    }
  }
}
</style>
