import DataStructure from './DataStructure'
import CarrierPrice from './CarrierPrice'
import CarrierDeliveryDate from './CarrierDeliveryDate'
export default class CheckoutCarrier extends DataStructure {
  constructor ({
    id,
    rank = null,
    code = null,
    logo = null,
    type = null,
    serviceName = null,
    name = null,
    description = null,
    price = null,
    deliveryDate = null
  }) {
    super()
    this.id = this.convertToNumber(id)
    this.rank = this.isset(rank) ? this.convertToNumber(rank) : null
    this.code = this.isset(code) ? this.convertToString(code) : null
    this.logo = this.isset(logo) ? this.convertToString(logo) : null
    this.type = this.isset(type) ? this.convertToString(type) : null
    this.serviceName = this.isset(serviceName) ? this.convertToString(serviceName) : null
    this.name = this.isset(name) ? this.convertToString(name) : null
    this.description = this.isset(description) ? this.convertToString(description) : null
    this.price = this.isset(price) ? this.getPrice(price) : null
    this.deliveryDate = this.isset(deliveryDate) ? this.getDeliveryDate(deliveryDate) : null
  }

  getPrice (value) {
    if (!value) {
      throw new Error('Price cannot be null nor undefined')
    }
    const price = new CarrierPrice(value)
    return price.getApiObject()
  }

  getDeliveryDate (value) {
    if (!value) {
      throw new Error('Delivery date cannot be null nor undefined')
    }
    const deliveryDate = new CarrierDeliveryDate(value)
    return deliveryDate.getApiObject()
  }
}
