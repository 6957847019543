<template>
  <DeliveryBanner
    class="c-christmas-banner"
    close-tool-tips="closeToolTips"
  >
    <template #title>
      {{ $t('components.molecules.banner.christmasBanner.title') }}
    </template>
    <template #titleTooltips>
      <span v-html="$t('components.molecules.banner.christmasBanner.titleInTooltips')" />
    </template>
    <template
      v-if="contentToDisplay"
      #contentTooltips
    >
      <div v-html="$t(`components.molecules.banner.christmasBanner.${contentToDisplay}`)" />
    </template>
    <template #mentionsTooltips>
      <span v-html="$t('components.molecules.banner.christmasBanner.mentions')" />
    </template>
  </DeliveryBanner>
</template>
<script>
import DeliveryBanner from '@/components/molecules/banner/delivery-banner/DeliveryBanner.vue'
export default {
  components: {
    DeliveryBanner
  },
  props: {
    contentToDisplay: {
      type: String,
      default: 'content1'
    }
  }
}
</script>
