import {
  isOutletAccessAllowed as isOutletAccessAllowedApi
} from '@/api/outlet'

export const isOutletAccessAllowed = ({
  email,
  headers,
  instance
} = {}) => {
  return isOutletAccessAllowedApi({
    email,
    headers,
    instance
  })
}
