import Vue from 'vue'
let dynamicOffset = 0
Vue.directive('smooth-scroll', {
  inserted: (el, binding, vnode) => {
    if (typeof window !== 'object' || window.pageYOffset === undefined) { return }
    const hash = vnode.data.attrs.href
    let { offset } = binding.value || {}
    offset = offset || 0
    dynamicOffset = offset
    const scrollTo = document.getElementById(hash.substring(1))

    if (scrollTo) {
      el.addEventListener('click', (evt) => {
        evt.preventDefault()
        const bodyTop = document.body.getBoundingClientRect().top
        const scrollTop = scrollTo.getBoundingClientRect().top
        window.scrollTo({
          top: scrollTop - bodyTop - dynamicOffset,
          behavior: 'smooth'
        })
      })
    }
  },
  update: (_, binding) => {
    let { offset } = binding.value || {}
    offset = offset || 0
    dynamicOffset = offset
  }
})
