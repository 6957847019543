<!-- @format -->
<template>
  <a
    v-if="item && item.slugs"
    :href="item.slugs[$i18n.locale]"
    class="c-product-thumbnail"
    :data-id="item.productID"
    @click.prevent="click"
  >
    <picture class="c-product-thumbnail__img-wrapper">
      <source
        media="(min-width:63.99em)"
        :srcset="getPictureURL('S')"
        :alt="`${item.displayInformation.title} - ${item.names[$i18n.locale]}`"
      />
      <img
        v-if="image && item.names"
        :src="getPictureURL('S')"
        :alt="`${item.displayInformation.title} - ${item.names[$i18n.locale]}`"
        :width="imgSize"
        :height="imgSize"
      />
    </picture>
    <div class="c-product-thumbnail__info-wrapper">
      <div class="c-product-thumbnail__tag-wrapper">
        <Sticker
          v-if="
            !!(
              (item.label === 'promo' || item.label === 'flash_sale') &&
              item.promotion.percent
            )
          "
          :promo="true"
        >
          -{{ item.promotion.percent }}%
        </Sticker>
        <Sticker v-if="!noTag && item.label === 'top_sale'" :white="true">{{
          $t('components.molecules.listing.productThumbnail.top')
        }}</Sticker>
        <Sticker v-if="!noTag && item.label === 'new'">{{
          $t('components.molecules.listing.productThumbnail.new')
        }}</Sticker>
        <Sticker
          v-if="displayMarketLabel"
          :eco-resp="marketLabel.class === 'eco_friendly'"
          :exclu="marketLabel.class === 'exclusivity'"
        >
          {{ marketLabel.name }}
        </Sticker>
      </div>
      <FavorisBtn
        :class="{ 'c-btn-favoris--inactive': !hydrationCompleted }"
        :product-id="item.productID"
        small
        @setProductDataForTrackingAfterLogin="
          setProductDataForTrackingAfterLogin
        "
        @addToWishList="
          handleTrackingAddRemoveToWishlist('lbo_add_to_wishlist')
        "
        @removeToWishList="
          handleTrackingAddRemoveToWishlist('lbo_remove_from_wishlist')
        "
      />
      <div class="c-product-thumbnail__info">
        <div class="c-product-thumbnail__titleWrapper">
          <div class="h5-like c-product-thumbnail__title">
            {{ item.displayInformation.title }}
          </div>
        </div>
        <p
          v-if="item.names"
          class="c-product-thumbnail__desc"
          :class="{
            'c-product-thumbnail__desc--with-free-shipping': freeShipping,
          }"
        >
          {{ item.names[$i18n.locale] }}
        </p>
        <div v-if="freeShipping" class="c-product-thumbnail__free-shipping">
          {{ $t('components.molecules.listing.productThumbnail.freeShipping') }}
        </div>

        <Price
          class="c-product-thumbnail__price"
          :price="item.prices[countryCodeLc].price || item.prices.default.price"
          :old="
            item.prices[countryCodeLc].originalPrice ||
            item.prices.default.originalPrice
          "
          small
        />
      </div>
      <div class="c-product-thumbnail__size">
        <template v-if="!item.sizes">
          <span>{{
            $t('components.molecules.listing.productThumbnail.uniqueSize')
          }}</span>
        </template>
        <template v-else-if="item.sizes && item.sizes.length > 7">
          <span>{{
            $t(
              'components.molecules.listing.productThumbnail.severalSizesAvailable',
            )
          }}</span>
        </template>
        <template v-else>
          <button
            v-for="size in item.sizes"
            :key="size.size"
            @click="setSelectedSize(size)"
          >
            {{ size.size }}
          </button>
        </template>
      </div>
    </div>
  </a>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { SIZE } from '@/config'
import { getCloudinaryWatermarkParam } from '@/helpers/cloudinary'
import { generateItemObjectV2 } from '@/helpers/gtmHelper'

import FavorisBtn from '@/components/atoms/global/favoris/Favoris.vue'
import Price from '@/components/atoms/global/price/Price'
import Sticker from '@/components/atoms/product/sticker/Sticker'

export default {
  components: { FavorisBtn, Price, Sticker },
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    noTag: {
      type: Boolean,
      default: false,
    },
    noMarketLabel: {
      type: Boolean,
      default: false,
    },
    displayWatermark: {
      type: Boolean,
      default: false,
    },
    imgSize: {
      type: String,
      default: '314',
    },
    listingType: {
      type: String,
      default: null,
    },
    // Position utiliser pour savoir ou se trouve l'item dans le listing
    // Pour le tracking gtm
    position: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      marketLabel: false,
      hydrationCompleted: false,
    }
  },

  computed: {
    ...mapState({
      freeShippingAmount: (state) => state.basket?.freeShippingAmount,
      typeOfListing: (state) => state.listing && state.listing.type,
      favorites: (state) => state.wishlist && state.wishlist.products,
    }),
    ...mapGetters({
      isInternationalDisplay: 'international/isInternationalDisplay',
      countryCode: 'international/getCountryCode',
    }),
    countryCodeLc() {
      return this.countryCode.toLowerCase()
    },
    image() {
      return this.item.pictures && this.item.pictures.find((pic) => pic.default)
    },
    freeShipping() {
      return (
        this.freeShippingAmount &&
        this.item.prices[this.countryCodeLc] &&
        parseFloat(this.item.prices[this.countryCodeLc].price) >=
          parseFloat(this.freeShippingAmount)
      )
    },
    displayMarketLabel() {
      return (
        !this.noMarketLabel &&
        this.marketLabel &&
        this.listingType !== 'LISTING_OUTLET'
      )
    },
  },

  beforeCreate() {
    this.SIZE = SIZE
  },

  created() {
    const marketLabels = Object.entries(this.item.marketLabels)
    if (marketLabels.length) {
      const marketLabel = marketLabels.shift()
      this.marketLabel = {
        name: marketLabel.pop()[this.$i18n.locale],
        class: marketLabel.shift().toLowerCase(),
      }
    }
  },
  mounted() {
    this.hydrationCompleted = true
  },
  methods: {
    ...mapActions({
      setSelectedSize: 'product/setSelectedSize',
      setproductToAddAfterLoginOrSubscribe:
        'wishlist/setproductToAddAfterLoginOrSubscribe',
    }),
    setProductDataForTrackingAfterLogin() {
      this.setproductToAddAfterLoginOrSubscribe(
        generateItemObjectV2({
          product: this.item,
          isAlgoliaData: true,
          locale: this.$i18n.locale,
          countryCode: this.countryCode,
        }),
      )
    },
    handleTrackingAddRemoveToWishlist(eventName) {
      this.$gtm.trackEvent({
        event: eventName,
        value: this.item.prices[this.countryCodeLc].price,
        page_data: {
          type: this.$store.state.gtm.page_data.type,
        },
        item: generateItemObjectV2({
          product: this.item,
          isAlgoliaData: true,
          locale: this.$i18n.locale,
          countryCode: this.countryCode,
        }),
      })
    },
    toggleProductToFavoris() {
      this.productInWishList = !this.productInWishList
    },
    getPictureURL(size) {
      if (this.displayWatermark && this.item.watermarkUrl) {
        return this.generateURLWithWaterMark(size)
      } else {
        return `${this.SIZE[size]}${this.image.picture}`
      }
    },
    gtmProductClick() {
      const item = generateItemObjectV2({
        product: this.item,
        locale: this.$i18n.locale,
        countryCode: this.countryCode,
        isAlgoliaData: true,
      })
      if (this.listingType === 'LISTING_NOSTO_PRODUCT_PAGE') {
        this.$gtm.trackEvent({
          event: 'lbo_select_reco',
          page_data: {
            range: item.range,
            label: item.label,
            nosto_rules: null,
          },
        })
      }
      this.$gtm.trackEvent({
        event: 'lbo_select_item',
        value: this.item.prices[this.countryCodeLc].price,
        page_data: {
          type: this.$store.state.gtm.page_data.type,
        },
        item,
      })
    },
    click(event) {
      if (event.target.classList[0] === 'c-btn-favoris') {
        return
      }
      this.gtmProductClick()
      this.$router.push({
        path: this.localePath(this.item.slugs[this.$i18n.locale]),
      })
    },
    generateURLWithWaterMark(size) {
      const baseUrl = this.SIZE[size].split('/').filter((item) => item)
      const isDesktop = this.$store.state.ui.deviceType === 'pc'
      const waterMarkParams = getCloudinaryWatermarkParam(
        this.item.watermarkUrl,
        isDesktop,
      )
      return `${baseUrl[0]}//${baseUrl[1]}/${baseUrl[2]}/${waterMarkParams}/${baseUrl[3]}${this.image.picture}`
    },
  },
}
</script>

<style lang="scss" scoped>
$coeff-label-size: 0.7;

.c-product-thumbnail {
  $c: &;

  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  padding-bottom: 8rem;
  margin-bottom: 2.2rem;
  color: inherit;
  font-size: $font-size-sm;
  text-decoration: none;

  &__img-wrapper {
    position: relative;
    padding-bottom: 100%;
    background: $grey-1300;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  &__tag {
    position: absolute;
    z-index: 1;
    top: 1.4rem;
    left: 0;
    padding: 0.1rem 0.6rem;
    background: $primary;
    color: $white;
    font-weight: 700;
    letter-spacing: 0.13rem;
    text-transform: uppercase;
    &#{$c}__tag--grey {
      background-color: $grey-1100;
      color: $grey-300;
    }
  }

  &__info-wrapper {
    position: absolute;
    z-index: 1;
    top: 100%;
    width: 100%;
    background: $white;
    transform: translateY(-8rem);
    transition: transform 0.2s;

    &::v-deep .c-btn-favoris {
      position: absolute;
      right: 0.4rem;
      bottom: calc(100% + 1.2rem);
    }
  }

  &__tag-wrapper {
    position: absolute;
    z-index: 1;
    bottom: calc(100% + 1.7rem);
    left: 0;

    @include mq($until: tablet) {
      bottom: calc(100% + 1.3rem);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &:empty {
      display: none;
    }

    .c-sticker {
      padding: 0.28rem 0.49rem;
      font-size: 1.1rem;
      line-height: normal;

      &:not(:last-child) {
        margin-right: 0.4rem;

        @include mq($until: tablet) {
          margin-right: 0;
          margin-bottom: 0.6rem;
        }
      }
    }
  }

  &__info {
    display: flex;
    width: 100%;
    min-height: 8rem;
    flex-direction: column;
    flex-grow: 1;
    background: $white;
  }

  &__titleWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0.8rem 0 0.3rem;

    .c-sticker {
      padding: 0.4rem;
      margin-right: 1rem;
    }
  }

  &__title {
    overflow: hidden;
    padding: 0;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include mq($until: tablet) {
      letter-spacing: 0.1px;
    }
  }

  &__desc {
    /* https://css-tricks.com/almanac/properties/l/line-clamp/ */
    display: -webkit-box;
    overflow: hidden;
    flex-grow: 1;
    margin: 0;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.23;

    @include mq($until: tablet) {
      line-height: 1.33;
    }

    &--with-free-shipping {
      -webkit-line-clamp: 1;
    }
  }

  &__free-shipping {
    color: $accent-light;
    font-weight: 500;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    @include mq($until: tablet) {
      font-size: $font-size-sm-mobile;
      letter-spacing: 0;
    }
  }

  &__price {
    display: flex;
    align-items: flex-end;
    margin-top: 0.3rem;

    .c-price + .c-price {
      margin-left: 2rem;
    }
  }

  &__size {
    display: flex;
    min-height: 3rem;
    flex-wrap: wrap;
    padding-top: 1.2rem;
    color: $grey-600;

    button {
      margin-right: 0.5rem;
      color: inherit;
      transition: color 0.2s ease;

      &:hover,
      &:focus {
        color: $grey-300;
      }
    }
  }

  &:hover,
  &:focus,
  &:focus-within {
    color: $grey-300;

    @include mq($from: tablet) {
      #{$c}__info-wrapper {
        transform: translateY(-100%);
      }
    }
  }
}
</style>
