<template>
  <div class="c-skeleton-wrapper">
    <SkeletonBreadcrumb />
    <div class="c-skeleton-p-listing">
      <SkeletonSeo />
      <SkeletonFilters />
      <SkeletonGrid
        :items-number="4"
        grid-four
      >
        <SkeletonProductThumbnail />
      </SkeletonGrid>
    </div>
  </div>
</template>

<script>
import SkeletonBreadcrumb from '@/components/molecules/skeleton/SkeletonBreadcrumb.vue'
import SkeletonFilters from '@/components/molecules/skeleton/SkeletonFilters.vue'
import SkeletonGrid from '@/components/molecules/skeleton/SkeletonGrid.vue'
import SkeletonProductThumbnail from '@/components/molecules/skeleton/SkeletonProductThumbnail.vue'
import SkeletonSeo from '@/components/molecules/skeleton/SkeletonSeo.vue'
export default {
  components: {
    SkeletonBreadcrumb,
    SkeletonFilters,
    SkeletonGrid,
    SkeletonProductThumbnail,
    SkeletonSeo
  }
}
</script>
<style lang="scss" scoped>
.c-skeleton-p-listing {
  padding: 0 0 2.7rem;

  @include mq($until: tablet) {
    padding: 0;
  }
}
</style>
