import Vue from 'vue'
import dayjs from 'dayjs'
import { sendSentryMessage } from '@/helpers/errorHelper'

const loadLocale = (context) => {
  const locale = context.$config.locale
  if (locale && locale !== 'en') {
    try {
      require(`dayjs/locale/${locale}.js`)
      dayjs.locale(locale)
    } catch (e) {
      sendSentryMessage.bind(context, 'Unable to load date plugin', 'error', {
        exception: e,
        locale,
        dayjsDebugVar: dayjs.locale(locale)
      })()
    }
  }
}
export default async function (context) {
  await loadLocale(context)
  const date = (value, format) => {
    return dayjs(value).format(format)
  }

  Vue.filter('date', date)

  // expose the date function too
  const plugin = {
    install (Vue) {
      Vue.prototype.$date = date
    }
  }

  Vue.use(plugin)
}
