<template functional>
  <svg
    width="36px"
    height="37px"
    viewBox="0 0 36 37"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Aide</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path id="Combined-Shape" d="M18,0.5 C27.9411255,0.5 36,8.5588745 36,18.5 C36,28.4411255 27.9411255,36.5 18,36.5 C8.0588745,36.5 0,28.4411255 0,18.5 C0,8.5588745 8.0588745,0.5 18,0.5 Z M18,3.5 C9.71572875,3.5 3,10.2157288 3,18.5 C3,26.7842712 9.71572875,33.5 18,33.5 C26.2842712,33.5 33,26.7842712 33,18.5 C33,10.2157288 26.2842712,3.5 18,3.5 Z M18,26 C18.8284271,26 19.5,26.6715729 19.5,27.5 C19.5,28.3284271 18.8284271,29 18,29 C17.1715729,29 16.5,28.3284271 16.5,27.5 C16.5,26.6715729 17.1715729,26 18,26 Z M18,8.5 C21.77689,8.5 23.8092839,10.2678219 24.3403207,13.1590227 C24.4728884,13.8807798 24.5,14.444099 24.5,15.5 C24.5,18.2380603 22.8159706,20.1191268 19.7878169,21.0881984 L19.5,21.1763753 L19.5,22.5 C19.5,23.3284271 18.8284271,24 18,24 C17.2203039,24 16.5795513,23.4051119 16.5068666,22.64446 L16.5,22.5 L16.5,18.7702941 L17.7058258,18.529129 C20.4094081,17.9884125 21.5,17.0068798 21.5,15.5 C21.5,14.620901 21.4791768,14.1882419 21.3896793,13.7009773 C21.1154697,12.2080584 20.3014433,11.5 18,11.5 C15.5617499,11.5 14.5,12.7740999 14.5,16 C14.5,16.8284271 13.8284271,17.5 13,17.5 C12.1715729,17.5 11.5,16.8284271 11.5,16 C11.5,11.2259001 13.7715834,8.5 18,8.5 Z" fill="#292B2D" fill-rule="nonzero" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
