import {
  getOrderReturnOrders as getOrderReturnOrdersApi,
  getReturnOrder as getReturnOrderApi,
  getReturnOrdersReasons as getReturnOrdersReasonsApi,
  createReturnOrder as createReturnOrderApi,
  getReturnOrderRelayPoints as getReturnOrderRelayPointsApi,
  getReturnOrderCoupon as getReturnOrderCouponApi,
  cancelReturnOrder as cancelReturnOrderApi
} from '@/api/secure/returnOrder'

import ReturnOrder from '@/models/ReturnOrder'

export const getOrderReturnOrders = ({
  orderId,
  headers,
  instance
} = {}) => {
  return getOrderReturnOrdersApi({
    orderId,
    headers,
    instance
  })
}

export const getReturnOrder = ({
  returnOrderId,
  headers,
  instance
} = {}) => {
  return getReturnOrderApi({
    returnOrderId,
    headers,
    instance
  })
}

export const getReturnOrdersReasons = ({
  headers,
  instance
} = {}) => {
  return getReturnOrdersReasonsApi({
    headers,
    instance
  })
}

export const createReturnOrder = ({
  returnOrder,
  headers,
  instance
} = {}) => {
  const returnOrderObject = new ReturnOrder(returnOrder)

  return createReturnOrderApi({
    returnOrder: returnOrderObject.getApiObject(),
    headers,
    instance
  })
}

export const getReturnOrderRelayPoints = ({
  returnOrderId,
  headers,
  instance
} = {}) => {
  return getReturnOrderRelayPointsApi({
    returnOrderId,
    headers,
    instance
  })
}

export const getReturnOrderCoupon = ({
  returnOrderId,
  headers,
  instance
} = {}) => {
  return getReturnOrderCouponApi({
    returnOrderId,
    headers,
    instance
  })
}

export const cancelReturnOrder = ({
  returnOrderId,
  headers,
  instance
} = {}) => {
  return cancelReturnOrderApi({
    returnOrderId,
    headers,
    instance
  })
}
