<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9.9 5.9"
  >
    <title v-if="props.title">{{ props.title }}</title>
    <path d="M4.9 5.9a1 1 0 0 1-.7-.3L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l3.2 3.2L8.1.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4L5.6 5.6c-.1.2-.4.3-.7.3z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
