import { isRequestSfmc, setSfmcCookie } from '../helpers/marketingCloudConversion'
export default function ({ app, route }) {
  if (Object.keys(route.query).length !== 0) {
    const sfmcParameters = {
      j: route.query.j || null,
      sfmc_sub: route.query.sfmc_sub || null,
      l: route.query.l || null,
      u: route.query.u || null,
      mid: route.query.mid || null,
      jb: route.query.jb || null
    }
    if (isRequestSfmc(sfmcParameters)) {
      setSfmcCookie(sfmcParameters, app.$cookies)
    }
  }
}
