/* @format */

export const state = () => ({
  asyncDataLoading: false,
  pageLoading: null,
  skeleton: null,
  spacer: false,
  transparentHeader: false,
  transparentHeaderInitialValue: null,
  timeoutID: null,
  deviceType: null,
  isBot: null,
})

export const mutations = {
  UPDATE_LOADING(state, payload) {
    state.pageLoading = payload
  },

  UPDATE_SPACER(state, payload) {
    state.spacer = payload
  },

  UPDATE_SKELETON(state, payload) {
    state.skeleton = payload
  },

  UPDATE_TIMEOUT_ID(state, payload) {
    state.timeoutID = payload
  },

  UPDATE_TRANSPARENT_HEADER(state, payload) {
    state.transparentHeader = payload
  },

  UPDATE_TRANSPARENT_HEADER_INITIAL_VALUE(state, payload) {
    state.transparentHeaderInitialValue = payload
  },

  UPDATE_ASYNC_DATA_LOADING(state, payload) {
    state.asyncDataLoading = payload
  },

  SET_DEVICE_TYPE(state, payload) {
    state.deviceType = payload
  },

  SET_IS_BOT(state, payload) {
    state.isBot = payload
  },
}
