/* @format */

import { refresh } from '@/api/secure/authentication'
import { ERROR_CODES } from '@/config/errors/codes'
import { sendSentryMessage } from '@/helpers/errorHelper'
import {
  getAccountCustomerAddresses,
  getAccountProfile,
} from '@/services/secure/account'

export default class BaseCustomStrategy {
  constructor(auth, options) {
    this.$auth = auth
    this.name = options._name
    this._bearer = {}
  }

  _getNuxtContext() {
    return this.$auth.ctx
  }

  get bearer() {
    return this._bearer
  }

  set bearer(v) {
    this._bearer = v
  }

  syncToken() {
    const token = this.$auth.syncToken(this.name)
    this.bearer = {
      apiKey: 'bearer ' + token,
    }
  }

  async mounted() {
    this.syncToken()
    await this.$auth.fetchUserOnce()
  }

  async loginFn() {}

  async login() {
    const defaultHeaders = this._getNuxtContext().$api.defaults.headers
    // Ditch any leftover local tokens before attempting to log in
    this._logoutLocally()

    const res = await this.loginFn(...arguments, defaultHeaders)

    const token = res.token
    const refreshToken = res.refresh_token

    this.$auth.setToken(this.name, token)
    this.bearer = {
      apiKey: 'bearer ' + token,
    }

    if (refreshToken && refreshToken.length) {
      this.$auth.setRefreshToken(this.name, 'bearer ' + refreshToken)
    }

    return this.fetchUser()
  }

  async refreshToken(callback) {
    const refreshTokenDefined = this.$auth.getRefreshToken(this.name)
    if (!refreshTokenDefined) {
      return
    }
    try {
      const defaultHeaders = this._getNuxtContext().$api.defaults.headers

      const res = await refresh({
        headers: {
          Authorization: `bearer ${this.$auth.getToken(this.name)}`,
          'Lbo-Store': defaultHeaders['Lbo-Store'],
          'Content-Type': defaultHeaders['Content-Type'],
          'Lbo-Device': defaultHeaders['Lbo-Device'],
          'Accept-Language': defaultHeaders['Accept-Language'],
        },
        token: refreshTokenDefined.split(' ')[1],
      })
      const token = res.token
      const refreshToken = res.refresh_token
      this.$auth.setToken(this.name, token)
      this.bearer = {
        apiKey: 'bearer ' + token,
      }
      if (refreshToken && refreshToken.length) {
        this.$auth.setRefreshToken(this.name, 'bearer ' + refreshToken)
      }
      if (callback) {
        return this.fetchUser()
      }
    } catch (e) {
      await this.logout()
    } finally {
      if (callback) {
        callback()
      }
    }
  }

  async fetchUser() {
    // Token is required but not available
    if (!this.$auth.getToken(this.name)) {
      return
    }
    try {
      const defaultHeaders = this._getNuxtContext().$api.defaults.headers

      const user = await getAccountProfile({
        headers: {
          Authorization: `bearer ${this.$auth.getToken(this.name)}`,
          'Lbo-Store': defaultHeaders['Lbo-Store'],
          'Content-Type': defaultHeaders['Content-Type'],
          'Lbo-Device': defaultHeaders['Lbo-Device'],
          'Accept-Language': defaultHeaders['Accept-Language'],
        },
      })

      this.$auth.setUser(user)
      await this.fetchUserAddresses()
    } catch (err) {
      if (err.response.status === ERROR_CODES.UNAUTHORIZED) {
        await this.refreshToken(async () => {
          await this.fetchUser()
        })
      } else {
        sendSentryMessage.bind(
          this._getNuxtContext(),
          'Unable to fetch basic connection info',
          'fatal',
          {
            action: 'getAccountProfile or fetchUserAddresses',
            exception: err,
          },
        )()
        throw new Error(err)
      }
    }
  }

  async fetchUserAddresses() {
    const defaultHeaders = this._getNuxtContext().$api.defaults.headers

    try {
      const addresses = await getAccountCustomerAddresses({
        headers: {
          Authorization: `bearer ${this.$auth.getToken(this.name)}`,
          'Lbo-Store': defaultHeaders['Lbo-Store'],
          'Content-Type': defaultHeaders['Content-Type'],
          'Lbo-Device': defaultHeaders['Lbo-Device'],
          'Accept-Language': defaultHeaders['Accept-Language'],
        },
      })

      this.$auth.$storage.setState('addresses', addresses)
    } catch (err) {
      sendSentryMessage.bind(
        this._getNuxtContext(),
        'Unable to fetch customer addresses',
        'fatal',
        {
          action: 'getAccountCustomerAddresses',
          exception: err,
        },
      )()
      throw new Error(err)
    }
  }

  async logout() {
    return await this._logoutLocally()
  }

  // FIXME: Should be removed (useless boilerplate code)
  async _logoutLocally() {
    this.bearer = { apiKey: null }
    this.$auth.$storage.setState('addresses', null)
    await this.$auth.reset()
  }
}
