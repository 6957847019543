<template>
  <div class="c-footer-app">
    <div class="c-footer-app__inner">
      <div class="c-footer-app__lbo">
        <img
          :src="!isInternationalDisplay ? 'https://assets.laboutiqueofficielle.com/assets/general/laboutiqueofficielle_square_logo.svg' : 'https://assets.laboutiqueofficielle.com/assets/general/ryses_square_logo.svg'"
          width="71"
          height="71"
          alt=""
        >
        <p>
          {{ $t('components.molecules.footer.app.downloadapp1') }}<br>
          {{ $t('components.molecules.footer.app.downloadapp2') }}
        </p>
      </div>
      <div class="c-footer-app__list">
        <a
          ref="playstore-link"
          :href="playStoreLink"
          target="_blank"
          rel="noopener"
          @mouseover="updatePosTooltip('androidTooltipPopper')"
        >
          <img
            :src="`https://assets.laboutiqueofficielle.com/assets/footer/googleplay-${currentInterStore.id}.png`"
            width="157"
            height="47"
            loading="lazy"
            :alt="$t('components.molecules.footer.app.googleplay')"
          >
          <img
            ref="tooltip-playstore"
            class="c-footer-app__list__tooltip"
            :src="`https://assets.laboutiqueofficielle.com/Marketing/Home/Desktop/QRCode/${$options.qrcodeTooltipImage}`"
            :alt="$t('components.molecules.footer.app.googleplay')"
          >
        </a>
        <a
          ref="apple-link"
          :href="appStoreLink"
          target="_blank"
          rel="noopener"
          @mouseover="updatePosTooltip('appleTooltipPopper')"
        >
          <img
            :src="`https://assets.laboutiqueofficielle.com/assets/footer/appstore-${currentInterStore.id}.png`"
            width="157"
            height="47"
            loading="lazy"
            :alt="$t('components.molecules.footer.app.appstore')"
          >
          <img
            ref="tooltip-apple-store"
            class="c-footer-app__list__tooltip"
            :src="`https://assets.laboutiqueofficielle.com/Marketing/Home/Desktop/QRCode/${$options.qrcodeTooltipImage}`"
            :alt="$t('components.molecules.footer.app.appstore')"
          >
        </a>
      </div>
    </div>
  </div>
</template>
<script>
// LIBS
import {
  createPopperLite as createPopper,
  preventOverflow,
  flip
} from '@popperjs/core'
import { mapGetters, mapState } from 'vuex'

// MISC
import { APP_LINKS } from '@/config/global'
export default {

  data () {
    return {
      androidTooltipPopper: null,
      appleTooltipPopper: null
    }
  },

  computed: {
    playStoreLink () {
      return APP_LINKS.googlePlay[this.currentInterStore.id]
    },
    appStoreLink () {
      return APP_LINKS.appStore[this.currentInterStore.id]
    },
    ...mapGetters({ isInternationalDisplay: 'international/isInternationalDisplay' }),
    ...mapState({
      currentInterStore: state => state.international && state.international.currentInterStore
    })
  },

  created () {
    this.$options.qrcodeTooltipImage = this.currentInterStore.id.includes('laboutiqueofficielle') ? 'qrcode_lbo.svg' : 'qr-code-ryses.svg'
  },

  mounted () {
    this.androidTooltipPopper = createPopper(this.$refs['playstore-link'], this.$refs['tooltip-playstore'], {
      modifiers: [preventOverflow, flip]
    })

    this.appleTooltipPopper = createPopper(this.$refs['apple-link'], this.$refs['tooltip-apple-store'], {
      modifiers: [preventOverflow, flip]
    })
  },

  methods: {
    updatePosTooltip (tooltip) {
      this[tooltip].update()
    }
  }
}
</script>
<style lang="scss" scoped>
.c-footer-app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 9.5rem 0;
  background: $grey-200;

  &__lbo {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
    color: $white;
    font-family: $font-family-main;
    font-size: 1.6rem;
    font-weight: 900;
    line-height: 1.6rem;
    text-decoration: none;

    &:hover {
      color: $white;
    }

    p {
      flex-shrink: 0;
      margin: 0;
      margin-left: 1.9rem;
      font-weight: 700;
      letter-spacing: 0.03rem;
      line-height: 2.5rem;

      @include mq($until: tablet) {
        font-size: $global-font-size;
        line-height: 2.4rem;
      }
    }

    img {
      display: block;
      width: 7.1rem;
      height: auto;

      @include mq($until: tablet) {
        width: 6.6rem;
      }
    }
  }

  &__list {
    @include mq($until: tablet) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }

    a,
    img {
      display: block;
    }

    img {
      width: 100%;
      max-width: 15.7rem;
      height: auto;
    }

    img.c-footer-app__list__tooltip {
      display: none;
    }

    a:hover {
      img.c-footer-app__list__tooltip {
        display: block;
      }
    }

    >a {
      &:not(:last-child) {
        margin-bottom: 1.4rem;

        @include mq($until: tablet) {
          margin-top: 1.4rem;
          margin-bottom: 0;
        }
      }

      &:only-child {
        margin: 0;
      }
    }
  }
}
</style>
