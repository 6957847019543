import { api } from '../instance'
export const getOrderReturnOrders = (options = {}) => {
  const {
    orderId,
    headers,
    instance
  } = options
  return (instance || api).get(
    `/secure/orders/${orderId}/return-orders`,
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getReturnOrder = (options = {}) => {
  const {
    returnOrderId,
    headers,
    instance
  } = options
  return (instance || api).get(
    `/secure/return-orders/${returnOrderId}`,
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getReturnOrdersReasons = (options = {}) => {
  const {
    headers,
    instance
  } = options
  return (instance || api).get(
    '/return-orders-reasons',
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const createReturnOrder = (options = {}) => {
  const {
    returnOrder,
    headers,
    instance
  } = options
  return (instance || api).post(
    '/secure/return-orders',
    {
      ...returnOrder
    },
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getReturnOrderRelayPoints = (options = {}) => {
  const {
    returnOrderId,
    headers,
    instance
  } = options
  return (instance || api).get(
    `/secure/return-orders/${returnOrderId}/relay-points`,
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getReturnOrderCoupon = (options = {}) => {
  const {
    returnOrderId,
    headers,
    instance
  } = options
  return (instance || api).get(
    `/secure/return-orders/${returnOrderId}/return-coupon`,
    {
      headers,
      responseType: 'blob'
    }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const cancelReturnOrder = (options = {}) => {
  const {
    returnOrderId,
    headers,
    instance
  } = options
  return (instance || api).patch(
    `/secure/return-orders/${returnOrderId}/cancel`,
    {
      headers
    }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}
