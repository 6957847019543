import { getTemplatePageBySlug } from '@/services/templatePage'
export default async function (context) {
  const { route, store, error, $api } = context
  let content
  try {
    content = await getTemplatePageBySlug({
      slug: route.params.name,
      instance: $api
    })
    store.dispatch('templatePage/setTemplatePageData', content)
  } catch (e) {
    return error({ statusCode: 404 })
  }
}
