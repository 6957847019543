<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.1 13.1">
    <title v-if="props.title">{{ props.title }}</title>
    <path d="M12.1 5.6H7.6V1c0-.6-.4-1-1-1s-1 .4-1 1v4.6H1c-.6 0-1 .4-1 1s.4 1 1 1h4.6v4.6c0 .6.4 1 1 1s1-.4 1-1V7.6h4.6c.6 0 1-.4 1-1s-.5-1-1.1-1z" />
  </svg>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
