<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32">
    <title v-if="props.title">{{ props.title }}</title>
    <g fill="none" fill-rule="evenodd">
      <path stroke="#333" stroke-linejoin="square" stroke-width="1.5" d="M20 8.75c1.2288336 0 2.3413336.49808322 3.1466252 1.3033748C23.9519168 10.8586664 24.45 11.9711664 24.45 13.2c0 1.367549-.6169522 2.5909379-1.5875115 3.4073033l-6.8693888 5.393681-6.87546261-5.3795592C8.16614422 15.7896901 7.55 14.567169 7.55 13.2c0-1.2288336.49808322-2.3413336 1.30337482-3.1466252C9.65866643 9.24808322 10.7711664 8.75 12 8.75c1.3768452 0 2.6075613.62536973 3.4238941 1.607352.2269528.2730059.4209551.5724664.5774589.8914547.1560172-.3192758.3496639-.6188051.5763324-.8918314C17.3928162 9.37513515 18.6233987 8.75 20 8.75Z" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
