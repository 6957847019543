import axios from 'axios'

const BASE_URL = process.env.BACK_API_URL
const DEFAULT_HEADERS = {
  'Content-Type': 'application/json'
}

export const api = axios.create({
  baseURL: BASE_URL,
  headers: { ...DEFAULT_HEADERS }
})
