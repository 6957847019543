import Vue from 'vue'

const round = (value) => {
  return Math.round((value + Number.EPSILON) * 100) / 100
}

Vue.filter('round', round)

// expose the currency function too
const plugin = {
  install (Vue) {
    Vue.prototype.$round = round
  }
}

Vue.use(plugin)
