import DataStructure from './DataStructure'
import ReturnOrderProduct from './ReturnOrderProduct'

export default class ReturnOrder extends DataStructure {
  constructor ({
    orderId = null,
    returnOrderRefundTypeId = null,
    // Vu avec nico, on peut mettre cette valeur par default
    // https://laboutiqueofficielle.atlassian.net/browse/PHPSF-76
    // Ticket pour qu'il change l'api pour le status
    status = 'waitClient',
    products = null
  }) {
    super()
    this.orderId = this.isset(orderId) ? this.convertToNumber(orderId) : null
    this.returnOrderRefundTypeId = this.isset(returnOrderRefundTypeId) ? this.convertToString(returnOrderRefundTypeId) : null
    this.status = this.isset(status) ? this.convertToString(status) : null
    this.products = this.isset(products) ? this.getProducts(products) : null
  }

  getProducts (products) {
    if (!products || !products.length) {
      return null
    }
    return products.map((product) => {
      const returnOrderProduct = new ReturnOrderProduct(product)
      return returnOrderProduct.getApiObject()
    })
  }
}
