import { api } from './instance'
export const getCmsPage = (options = {}) => {
  const { pageKey, headers, instance } = options
  return (instance || api).get(
    `/cms/slug/${pageKey}`,
    { headers }
  ).then(res => res.data)
    .catch((error) => {
      throw error
    })
}
