<template>
  <div
    class="c-form-message"
    :data-cy="dataCy"
    :class="{
      'c-form-message--success': success,
      'c-form-message--error': !success,
      'c-form-message--alert': alert,
      'c-form-message--fixed': fixed,
      'c-form-message--header': header
    }"
  >
    <slot />
    <button v-if="!noClose" class="c-form-message__btn" @click="$emit('hide')">
      <Close title="Fermer" />
    </button>
  </div>
</template>

<script>
import Close from '@/components/atoms/icons/Close'

export default {
  components: { Close },
  props: {
    success: {
      type: Boolean,
      default: true
    },
    alert: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: false
    },
    noClose: {
      type: Boolean,
      default: false
    },
    dataCy: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.c-form-message {
  position: relative;
  width: 100%;
  padding: 1rem 4rem;
  margin-bottom: 1.5rem;
  color: $white;
  font-size: $font-size-sm;
  text-align: center;
  transition: opacity ease-in-out 0.3s;

  a {
    color: $white;
  }

  &--success {
    border: 0.1rem solid $accent-dark;
    background-color: rgba($accent-dark, 1);
  }

  &--error {
    border: 0.1rem solid $primary-dark;
    background-color: rgba($primary-dark, 1);
  }

  &--alert {
    border: 0.1rem solid $secondary;
    background-color: $secondary;
    font-weight: 700;
  }

  &--noSpace {
    margin-bottom: 0;
  }

  &--fixed {
    position: fixed;
    z-index: 12;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 136.6rem;
    transform: translateX(-50%);
  }

  &--header {
    margin-bottom: 0;
  }

  &__btn {
    position: absolute;
    top: 50%;
    right: 2.1rem;
    width: 1.3rem;
    height: 1.3rem;
    padding: 0;
    border: none;
    background-color: transparent;
    color: $white;
    transform: translateY(-50%);

    > svg {
      display: block;
      width: 100%;
      height: auto;
      fill: currentcolor;
    }
  }
}
</style>
