export default class DataStructure {
  filterNullValues () {
    const filteredOptions = {}

    for (const key in this) {
      const value = this[key]

      if (value !== null && value !== undefined) {
        filteredOptions[key] = value
      }
    }

    return filteredOptions
  }

  setPropertiesList (object) {
    this.propertiesList = object
  }

  convertToBoolean (value) {
    return Boolean(value)
  }

  convertToInteger (value) {
    return parseInt(value, 10)
  }

  convertToNumber (value) {
    return parseFloat(value)
  }

  convertToString (value) {
    return String(value)
  }

  isset (parameter) {
    if (parameter === null || parameter === undefined) {
      return false
    }

    return true
  }

  getApiObject () {
    return this.filterNullValues()
  }
}
