<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 23 18.7">
    <title v-if="props.title">{{ props.title }}</title>
    <path fill="none" stroke="#333" stroke-width="1.5" d="M16.5.8c1.6 0 3 .6 4.1 1.7 1 1 1.7 2.5 1.7 4.1 0 1.8-.8 3.3-2.1 4.4l-8.7 6.8L2.8 11C1.6 9.9.8 8.3.8 6.6c0-1.6.6-3 1.7-4.1S5 .8 6.6.8c1.8 0 3.4.8 4.4 2.1.2.3.4.5.6.8.2-.3.4-.6.6-.8.9-1.3 2.5-2.1 4.3-2.1z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
