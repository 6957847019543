import { api } from './instance'

export const getTemplatesItems = (options = {}) => {
  const { keys = [], headers, instance } = options
  return (instance || api).get(`/templates?${keys.map(key => `templateSlugs[]=${key}`)}`, {
    headers
  })
    .then(res => res.data)
    .catch((e) => {
      throw e
    })
}
