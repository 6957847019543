
<template>
  <div
    v-if="!loading"
    class="o-layout u-bg--grey-light"
  >
    <div>
      <HeaderTunnel />
      <ChristmasBanner
        v-if="displayChristmasBanner"
        :content-to-display="christmasBannerContentToDisplay"
      />
      <main
        class="l-container"
        :class="className"
      >
        <Timeline :current="current" :class="{'u-hidden@tablet': hideTimelineOnMobile}" />
        <nuxt />
      </main>
      <Reinsurance v-if="isShippingPage" only-on-mobile />
      <FooterCredits special>
        &copy; {{ year }} - {{ $t('components.molecules.footer.credits.copyright') }}
      </FooterCredits>
      <ModalLocalStorage />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dayjs from 'dayjs'
import christmasBanner from '@/mixins/christmasBanner'
import ChristmasBanner from '@/components/molecules/banner/christmas-banner/ChristmasBanner.vue'
import HeaderTunnel from '@/components/organisms/layout/header-tunnel/HeaderTunnel'
import FooterCredits from '@/components/atoms/footer/footer-credits/FooterCredits'
import Timeline from '@/components/atoms/tunnel/timeline/Timeline'
import Reinsurance from '@/components/molecules/footer/reinsurance/Reinsurance'
import ModalLocalStorage from '@/components/organisms/home/modal-localstorage/ModalLocalStorage.vue'

export default {
  name: 'CheckoutLayout',
  components: {
    ChristmasBanner,
    HeaderTunnel,
    FooterCredits,
    Timeline,
    Reinsurance,
    ModalLocalStorage
  },
  mixins: [christmasBanner],
  data () {
    return {
      year: dayjs().format('YYYY'),
      loading: true
    }
  },
  computed: {
    ...mapState({
      localStorageWarnAccepted: state => state.localStorageWarnAccepted
    }),
    className () {
      if (!this.$store.state.pagename) {
        return
      }
      return {
        'l-container--basket': this.$store.state.pagename.startsWith('checkout-basket'),
        'l-container--shipping': this.$store.state.pagename.startsWith('checkout-shipping'),
        'l-container--addAddress': this.$store.state.pagename.startsWith('checkout-add_address'),
        'l-container--payment': this.$store.state.pagename.startsWith('checkout-payment')
      }
    },
    isShippingPage () {
      return this.$store.state.pagename && this.$store.state.pagename.startsWith('checkout-shipping')
    },
    current () {
      if (this.$store.state.pagename && this.$store.state.pagename) {
        if (this.$store.state.pagename.startsWith('checkout-basket')) {
          return 1
        } else if (this.$store.state.pagename.startsWith('checkout-shipping') || this.$store.state.pagename.startsWith('checkout-add_address')) {
          return 2
        } else if (this.$store.state.pagename.startsWith('checkout-payment')) {
          return 3
        }
      }
      // default
      return 1
    },
    hideTimelineOnMobile () {
      return this.$store.state.pagename != null && this.$store.state.pagename.startsWith('checkout-basket')
    }
  },
  created () {
    this.$nextTick(function () {
      this.loading = false
    })
  },
  mounted () {
    if (!this.$localStorage && !this.localStorageWarnAccepted) {
      this.$modal.show('localStorage')
    }
  },
  beforeDestroy () {
    if (this.$route.path !== '/login') {
      this.setComeFromStepOneOfBasket(false)
    }
  },
  methods: {
    ...mapActions({
      setComeFromStepOneOfBasket: 'setComeFromStepOneOfBasket'
    })
  }
}
</script>

<style lang="scss" scoped>
.l-container {
  width: 100%;
  max-width: 91.8rem;
  min-height: calc(100vh - 13.3rem);
  padding-bottom: 6rem;
  margin: 0 auto;

  &--addAddress {
    max-width: 114.2rem;
    padding-bottom: 11.1rem;
  }

  @include mq($until: tablet) {
    height: calc(100% - 5.7rem);
    min-height: 1rem;
    padding-bottom: 0;

    &--basket {
      max-width: 100%;
    }

    &--shipping {
      padding-bottom: 6rem;
    }
  }
}
</style>
