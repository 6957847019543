import { api } from './instance'

export const isOutletAccessAllowed = (options = {}) => {
  const { email, headers, instance } = options
  return (instance || api).post(
    '/outlet/access',
    { email },
    { headers }
  ).then(res => res.data)
    .catch((error) => {
      throw error
    })
}
