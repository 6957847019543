
<template>
  <div>
    <div
      v-show="isOpen"
      class="c-menu-mobile__overlay"
      @click="currentMenuName = null; $emit('generalClose')"
      @keyup.enter="currentMenuName = null; $emit('generalClose')"
    />
    <transition name="slide" @enter="enter" @leave="leave">
      <div v-show="isOpen" class="c-menu-mobile">
        <div
          class="c-menu-mobile__main"
          :class="{'c-menu-mobile__main--open': currentMenuName}"
        >
          <button
            class="c-menu-mobile__close"
            @click="$emit('generalClose')"
          >
            {{ $t('components.molecules.header.menuMobile.close') }}
            <CloseIcon />
          </button>
          <nav class="c-menu-mobile-nav">
            <div class="c-quick-link">
              <nuxt-link
                v-for="item in quickLinks"
                :key="item.type"
                :to="localePath(returnUrl(item))"
                class="c-quick-link__item"
                @click.native="reinitFilterAndRedirect(item)"
              >
                {{ item.name }}
              </nuxt-link>
            </div>
            <div v-for="item in notQuickLinks" :key="item.type" class="c-menu-mobile-nav__item">
              <button v-if="!item.url" class="c-menu-mobile-nav__btn" @click="currentMenuName = item.type">
                <span class="c-menu-mobile-nav__label">{{ item.name }}</span>
                <span><ArrowRightIcon /></span>
              </button>
              <nuxt-link
                v-else
                :to="localePath(returnUrl(item))"
                class="c-menu-mobile-nav__btn"
                @click.native="$emit('close')"
              >
                <span class="c-menu-mobile-nav__label">{{ item.name }}</span>
                <span><PlusIcon /></span>
              </nuxt-link>
              <transition name="slide">
                <SubMenuMobile
                  v-show="currentMenuName === item.type"
                  :submenu="item"
                  class="c-menu-mobile-nav__sub-menu"
                  @close="currentMenuName = null"
                  @closeMenu="currentMenuName = null; $emit('close')"
                />
              </transition>
            </div>
          </nav>
          <UserAccount mobile @close="$emit('close')" />
          <MenuLang @open="toggleMenuLang" />
          <FooterSocialNetwork
            class="c-menu-mobile__social"
            :logo-store="false"
            white
          />
          <div class="c-menu-mobile__link">
            <nuxt-link
              :to="localePath(`/t/${CMS_SLUG[currentInterStore.id].legalNotices}`)"
              @click.native="$emit('close')"
            >
              {{ $t('components.molecules.header.menuMobile.legalNotice') }}
            </nuxt-link>
            <nuxt-link
              :to="localePath(`/t/${CMS_SLUG[currentInterStore.id].cgv}`)"
              @click.native="$emit('close')"
            >
              {{ $t('components.molecules.header.menuMobile.termsAndConditions') }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { generatePathWithQueryParams } from '@/helpers/generatePathWithQueryParams'
import { CMS_SLUG } from '@/config/cms/config'
import { PAGETYPE } from '@/config'

import MenuLang from '@/components/molecules/header/menu-lang/MenuLang'
import UserAccount from '@/components/molecules/header/user-account/UserAccount'
import SubMenuMobile from '@/components/molecules/header/sub-menu-mobile/SubMenuMobile'
import FooterSocialNetwork from '@/components/molecules/footer/footer-social-network/FooterSocialNetwork'
import CloseIcon from '@/components/atoms/icons/Close'
import ArrowRightIcon from '@/components/atoms/icons/ArrowRight'
import PlusIcon from '@/components/atoms/icons/Plus'

export default {
  components: {
    ArrowRightIcon,
    CloseIcon,
    FooterSocialNetwork,
    MenuLang,
    PlusIcon,
    SubMenuMobile,
    UserAccount
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    menu: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      currentMenuName: null,
      CMS_SLUG
    }
  },

  computed: {
    ...mapState({
      currentInterStore: state => state.international && state.international.currentInterStore,
      filters: state => state.listing && state.listing.filters
    }),
    quickLinks () {
      return this.menu && this.menu.filter(
        item =>
          [PAGETYPE.NOVELTIES, PAGETYPE.TOPSALES, PAGETYPE.PROMO].includes(
            item.type
          )
      )
    },
    notQuickLinks () {
      return this.menu && this.menu.filter(
        item =>
          ![PAGETYPE.NOVELTIES, PAGETYPE.TOPSALES, PAGETYPE.PROMO].includes(
            item.type
          )
      )
    }
  },

  beforeDestroy () {
    document.body.classList.remove('overflow-hidden')
  },

  methods: {
    ...mapActions({
      reinitFilter: 'listing/reinitFilter',
      setReloadAsyncData: 'listing/setReloadAsyncData'
    }),
    returnUrl (item) {
      return generatePathWithQueryParams(item.url)
    },
    reinitFilterAndRedirect (item) {
      if ((this.$route.path === item.url.path) && (Object.keys(this.filters).length > 0)) {
        this.reinitFilter(true)
      }
      if (this.$route.path === item.url.path) {
        this.$nuxt.$loading.start()
        this.setReloadAsyncData(true)
      }
      this.$emit('close')
    },
    toggleMenuLang (value) {
      this.currentMenuName = value ? 'LANG' : null
    },
    enter () {
      document.body.classList.add('overflow-hidden')
    },

    leave () {
      document.body.classList.remove('overflow-hidden')
    }
  }
}
</script>

<style lang="scss" scoped>
.c-menu-mobile {
  $c: &;

  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  display: none;
  overflow: hidden;
  width: 83.88%;
  height: 100%;

  @include mq($until: tablet) {
    display: block;
  }

  &__main {
    position: relative;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: $white;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;

    &--open {
      overflow-y: hidden;
    }
  }

  &__close {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 1.8rem 2.3rem;
    border: none;
    background-color: $grey-200;
    color: $white;
    font-size: $font-size-sm;
    text-transform: uppercase;

    > svg {
      display: block;
      width: auto;
      height: 1rem;
      margin-left: 1rem;
      fill: currentcolor;
    }
  }

  &__social {
    padding: 1.7rem 0;

    &::v-deep .c-footer-social-network {
      padding: 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.1rem 1.1rem 0.9rem;
    background-color: $grey-200;

    a {
      color: $white;
      font-size: $font-size-xs-mobile;
      font-weight: 400;
      text-decoration: none;

      &:not(:first-child) {
        padding-left: 0.7rem;
        border-left: 0.1rem solid $white;
        margin-left: 0.7rem;
      }
    }
  }

  &__overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.7);
    cursor: pointer;
    opacity: 1;
    transition: $default-transition;
  }
}

.c-menu-mobile-nav {
  $c: &;

  overflow: hidden;

  &__list,
  &__sub {
    padding-left: 0;
    list-style-type: none;
  }

  &__label {
    text-transform: uppercase;
  }

  &__item {
    border-top: 0.1rem solid $grey-1100;
    margin-bottom: 0;

    &:only-child {
      border-bottom: none;
    }

    &#{$c}__item--active {
      > #{$c}__sub--lvl2 {
        transform: translateX(0);
      }
      > #{$c}__btn {
        #{$c}__sub--lvl2 & {
          font-weight: 700;

          span {
            transform: rotate(-180deg);
          }
        }
      }
      > #{$c}__sub--lvl3 {
        max-height: 999rem;
      }
    }
  }

  &__btn {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2.3rem 2rem 1.8rem;
    border: none;
    background-color: $white;
    color: $grey-300;
    font-size: 1.3rem;
    font-weight: 700;
    text-decoration: none;
    text-transform: none;

    svg {
      display: block;
      width: 100%;
      height: auto;
      fill: currentcolor;
    }

    > span {
      color: $grey-300;
      pointer-events: none;
      transition: $default-transition;

      &:last-child {
        width: 0.8rem;
      }
    }

    #{$c}__item--promo & {
      color: $primary;
    }
  }

  &__sub-menu {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    overflow-y: auto;
  }
}

.c-quick-link {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  margin: 0 -0.25rem;

  &__item {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 1rem 0.5rem;
    border: 0.1rem solid $black;
    margin: 0 0.25rem;
    color: $black;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.slide-enter-active {
  transform: translateX(0);
  transition: transform 0.3s ease;
}

.slide-leave-active {
  transform: translateX(0);
  transition: transform 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}
</style>
