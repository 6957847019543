<template>
  <div>
    <MainHeading :title="$t('layouts.error.title')" error>
      <i18n path="layouts.error.content">
        <br>
      </i18n>
    </MainHeading>
    <div v-if="isDev && devError">
      <h2>DEV DEBUG : </h2>
      <code>
        <pre v-html="JSON.stringify(devError, null, 2)" />
      </code>
    </div>
    <HighlightProducts
      v-if="highlightedProducts.length"
      :products="highlightedProducts"
    />
    <TopSales
      v-if="topSales && topSales.length > 0"
      :products="topSales"
      listing-type="LISTING_TOP_SALES_ERROR_PAGE"
    />
    <ModalLocalStorage />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { TYPE } from '@/config/algolia/config'
import { getTemplatesItems } from '@/services/template'
import { algoliaErrorHandler, lboAlgoliaSearchHelper } from '@/helpers/algoliasearchHelper'

import HighlightProducts from '@/components/organisms/home/highlight-products/HighlightProducts'
import MainHeading from '@/components/molecules/global/main-heading/MainHeading'
import TopSales from '@/components/organisms/home/top-sales/TopSales'
import { sendSentryMessage } from '@/helpers/errorHelper'
import ModalLocalStorage from '@/components/organisms/home/modal-localstorage/ModalLocalStorage.vue'

export default {
  name: 'Error',
  components: {
    HighlightProducts,
    MainHeading,
    TopSales,
    ModalLocalStorage
  },
  props: { error: { type: Object, default: () => { return {} } } },
  data () {
    return {
      highlightedProducts: [],
      topSales: [],
      devError: null,
      isDev: false
    }
  },
  fetch () {
    this.devError = this.$nuxt.context.devError
    this.isDev = this.$nuxt.context.isDev
  },
  head () {
    const faviconFolder = this.isInternationalDisplay ? 'other' : 'fr'

    return {
      title: this.$t('components.atoms.global.logo.title'),
      htmlAttrs: {
        lang: this.currentInterStore.langCode
      },
      meta: [
        {
          hid: 'viewport',
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0,  shrink-to-fit=no, maximum-scale=1.0,user-scalable=0'
        }
      ],
      link: [
        { rel: 'icon', sizes: 'any', href: `/favicon/${faviconFolder}/favicon.ico` },
        { rel: 'icon', type: 'image/svg+xml', href: `/favicon/${faviconFolder}/favicon.svg` },
        { rel: 'apple-touch-icon', sizes: '180x180', href: `/favicon/${faviconFolder}/apple-touch-icon.png` },
        { rel: 'mask-icon', color: '#e9252f', href: `/favicon/${faviconFolder}/safari-pinned-tab.svg` },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: `/favicon/${faviconFolder}/favicon-32x32.png` },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: `/favicon/${faviconFolder}/favicon-16x16.png` },
        { rel: 'manifest', href: `/favicon/${faviconFolder}/site.webmanifest` }
      ]
    }
  },
  computed: {
    ...mapState({
      localStorageWarnAccepted: state => state.localStorageWarnAccepted,
      currentInterStore: state => state.international && state.international.currentInterStore,
      algoliaProductIndex: state => state.international.currentInterStore.algoliaProductIndex
    }),
    ...mapGetters({
      isInternationalDisplay: 'international/isInternationalDisplay'
    })
  },
  async mounted () {
    if (!this.$localStorage && !this.localStorageWarnAccepted) {
      this.$modal.show('localStorage')
    }

    this.setPageName('error')

    try {
      const res = await getTemplatesItems({
        slug: ['top_categorie_homepage'],
        instance: this.$api
      })
      const topCategoriesTemplate = res.find(item => item.slug === 'top_categorie_homepage')
      if (topCategoriesTemplate) {
        this.highlightedProducts = topCategoriesTemplate.templateItems
      }
    } catch (error) {
      sendSentryMessage.bind(this, 'Unable to fetch templates on Error page (Express api)', 'error', {
        call: 'getTemplatesItems',
        exception: error
      })()
    }

    // top sales
    const { helper } = lboAlgoliaSearchHelper({
      type: TYPE.TOPSALES,
      hitsPerPage: 6,
      currentInterStoreId: this.currentInterStore.id,
      currentInterStoreLangCode: this.currentInterStore.langCode,
      algoliaIndex: this.algoliaProductIndex
    })
    helper.search()
    try {
      const results = await new Promise((resolve, reject) => {
        helper.on('result', ({ results }) => {
          resolve(results)
        })
        helper.on('error', (evt) => {
          reject(evt)
        })
      })
      this.topSales = results?.hits
    } catch (err) {
      const exception = algoliaErrorHandler(err)

      sendSentryMessage.bind(this, 'Unable to get products from algolia on error page', 'error', {
        exception,
        indexAlgolia: this.algoliaProductIndex,
        currentStore: this.currentInterStore,
        listingType: TYPE.TOPSALES
      })()
    }
  },
  methods: {
    ...mapActions(
      {
        setPageName: 'setPageName'
      }
    )
  }
}
</script>
