<template>
  <div
    v-if="products"
    class="c-highlight-products"
  >
    <MainHeading :title="$t('components.organisms.home.highlightProducts.title')" :subtitle="$t('components.organisms.home.highlightProducts.subtitle')" />

    <ul class="c-highlight-products__list">
      <li
        v-for="item in products"
        :key="item.id"
        class="c-highlight-products__item u-1/4 u-1/2@tablet"
      >
        <Category
          :link="item.link"
          :title="item.title"
          :image="item.image"
          :image-mobile="item.imageMobile"
          :promotion-data="promotionData"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex'
// import { createTrackPromotionEvent } from '@/helpers/gtmHelper'

import Category from '@/components/atoms/global/category/Category'
import MainHeading from '@/components/molecules/global/main-heading/MainHeading'
export default {
  components: { Category, MainHeading },
  props: {
    products: {
      type: Array,
      default: null
    },
    promotionData: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState({
      readyToTrack: state => state.gtm && state.gtm.readyToTrack
    })
  },
  watch: {
    readyToTrack () {
      this.startTrackingViewPromotion()
    }
  },
  mounted () {
    this.startTrackingViewPromotion()
  },
  methods: {
    startTrackingViewPromotion () {
      // if (this.readyToTrack && this.promotionData?.length) {
      //   this.$gtm.trackEvent(
      //     createTrackPromotionEvent('view_promotion', this.promotionData)
      //   )
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-highlight-products {
  $c: &;

  padding: 0 2.2rem;

  a {
    display: block;
  }

  .c-main-heading {
    margin-bottom: 3.1rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 -1.1rem;
    list-style-type: none;
  }

  &__item {
    padding: 0 1.1rem;
    margin-bottom: 4.4rem;

    @include mq($until: tablet) {
      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }
  }

  @include mq($until: tablet) {
    padding: 0 0.5rem;
    margin-bottom: 6.5rem;

    &__list {
      margin: 0 -0.25rem;
    }

    &__item {
      padding: 0 0.25rem;
      margin-bottom: 2.5rem;
    }

    &__title {
      font-size: $font-size-xl-mobile;
      letter-spacing: 0.074rem;
    }
  }
}
</style>
