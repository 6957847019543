<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.3 24.9"
    width="15"
    height="19"
  >
    <title v-if="props.title">{{ props.title }}</title>

    <path d="M18.3 24.9l-.5-.1-8.1-4.7-8.1 4.7a1 1 0 0 1-1 0c-.4-.2-.6-.5-.6-.9V1c0-.6.4-1 1-1h17.3c.6 0 1 .4 1 1v22.9c0 .4-.2.7-.5.9l-.5.1zm-8.7-7l.5.1 7.1 4.1V2H2v20.2L9.1 18l.5-.1z" />
    <path d="M7 14.8l-.4-.1c-.2-.2-.3-.5-.3-.8l.8-2.6L5 9.7c-.2-.2-.3-.5-.2-.8s.3-.5.6-.5h2.7L9 5.8c.2-.6 1.1-.6 1.3 0l.9 2.6h2.7c.3 0 .5.2.6.5.1.3 0 .6-.3.7L12 11.3l.8 2.6c.1.3 0 .6-.3.8-.2.2-.6.2-.8 0L9.6 13l-2.2 1.6-.4.2zm4.9-.6zm-4.6 0zm-.5-.4zm5.6 0zM9.6 12l.4.1 1.6 1.1-.6-1.8c-.1-.3 0-.6.3-.9l1.5-1.1h-1.9a.8.8 0 0 1-.7-.5l-.6-1.8L9 8.9c-.1.3-.4.6-.7.6H6.4l1.5 1.1c.3.2.4.6.3.9l-.6 1.8 1.5-1.1c.2-.2.4-.2.5-.2zm-2.3-.9zm4.7 0zm1.7-2.2zm-8.1 0zm5.6-.3z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
