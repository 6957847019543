<template>
  <div class="c-product-list">
    <MainHeading
      v-if="title"
      :title="title"
      :title-left="titleLeft"
      :small-title="smallTitle"
      :no-capitalize="titleNoCap"
    />
    <div
      v-if="products"
      class="c-product-list__list"
    >
      <div
        v-for="(item, index) in products"
        :key="item.id"
        class="u-1/6 u-1/2@tablet"
      >
        <ProductThumbnail
          :item="item"
          :no-tag="noTag"
          :no-market-label="noMarketLabel"
          :img-size="productThumbnailSize"
          :listing-type="listingType"
          :position="index + 1"
        />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import MainHeading from '@/components/molecules/global/main-heading/MainHeading'
import ProductThumbnail from '@/components/molecules/listing/product-thumbnail/ProductThumbnail'

export default {
  components: {
    MainHeading,
    ProductThumbnail
  },
  props: {
    title: {
      type: String,
      default: null
    },
    titleLeft: {
      type: Boolean,
      default: false
    },
    titleNoCap: {
      type: Boolean,
      default: false
    },
    products: {
      type: Array,
      default: null
    },
    smallTitle: {
      type: Boolean,
      default: false
    },
    noTag: {
      type: Boolean,
      default: false
    },
    productThumbnailSize: {
      type: String,
      default: '314'
    },
    listingType: {
      type: String,
      default: ''
    },
    // Disable or not market label.
    // Usefull for example on home page to disable marketLabel
    // on top sale listing
    noMarketLabel: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-product-list {
  $c: &;

  padding: 2.2rem;
  margin-bottom: 5rem;
  text-align: center;

  &:empty {
    display: none;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 3rem -1.1rem 1rem;
    text-align: left;

    > * {
      display: flex;
      padding-right: 1.1rem;
      padding-left: 1.1rem;
    }

    @include mq($until: tablet) {
      margin: 2.6rem -0.25rem 1rem !important;

      > * {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
      }
    }
  }

  @include mq($until: tablet) {
    padding: 0 0.5rem !important;
  }
}
</style>
