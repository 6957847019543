var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"c-sticker",class:{
    'c-sticker--ecoresponsable' : _vm.ecoResp,
    'c-sticker--exclusivity': _vm.exclu,
    'c-sticker--promo': _vm.promo,
    'c-sticker--grey': _vm.grey,
    'c-sticker--white': _vm.white,
    'c-sticker--large': _vm.large
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }