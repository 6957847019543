import DataStructure from './DataStructure'
export default class BasketItem extends DataStructure {
  constructor ({
    productId = null,
    productSizeId = null,
    quantity = null,
    quantityAvailable = null,
    originPrice = null,
    price = null,
    dutyFreePrice = null,
    discountAmountWithDuty = null
  }) {
    super()
    this.productId = this.isset(productId) ? this.convertToNumber(productId) : null
    this.productSizeId = this.isset(productSizeId) ? this.convertToNumber(productSizeId) : null
    this.quantity = this.isset(quantity) ? this.convertToNumber(quantity) : null
    this.quantityAvailable = this.isset(quantityAvailable) ? this.convertToNumber(quantityAvailable) : null
    this.originPrice = this.isset(originPrice) ? this.convertToNumber(originPrice) : null
    this.price = this.isset(price) ? this.convertToNumber(price) : null
    this.dutyFreePrice = this.isset(dutyFreePrice) ? this.convertToNumber(dutyFreePrice) : null
    this.discountAmountWithDuty = this.isset(discountAmountWithDuty) ? this.convertToNumber(discountAmountWithDuty) : null
  }
}
