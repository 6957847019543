<template>
  <div v-if="submenu" class="c-sub-menu-mobile">
    <div class="c-sub-menu-mobile__back">
      <button @click="$emit('close')">
        <ArrowLeftIcon />
        {{ submenu.name }}
      </button>
    </div>
    <ul>
      <template v-if="submenu.type === 'ARTISTS'">
        <li v-for="subsection in submenu.menuSubSections" :key="subsection.name" class="c-sub-sub-menu-mobile-nav__item">
          <nuxt-link v-if="subsection.url" :to="localePath(subsection.url.path)" class="c-sub-menu-mobile-nav__btn c-sub-menu-mobile-nav__btn--main" @click.native="$emit('closeMenu')">
            <span class="c-sub-menu-mobile-nav__label">{{ subsection.name }}</span>
            <span><PlusIcon /></span>
          </nuxt-link>
        </li>
      </template>
      <template v-else>
        <li v-if="submenu.type === 'ACCESSORIES' && submenu.menuSubSections[1]" class="c-sub-sub-menu-mobile-nav__item">
          <nuxt-link v-if="submenu.menuSubSections[1].url" :to="localePath(submenu.menuSubSections[1].url.path)" class="c-sub-menu-mobile-nav__btn c-sub-menu-mobile-nav__btn--main" @click.native="$emit('closeMenu')">
            <span class="c-sub-menu-mobile-nav__label">{{ submenu.menuSubSections[1].name }}</span>
            <span><PlusIcon /></span>
          </nuxt-link>
        </li>
        <li v-if="submenu.menuSubSections[0] && submenu.menuSubSections[0].url" class="c-sub-sub-menu-mobile-nav__item">
          <nuxt-link v-if="submenu.menuSubSections[0].url" :to="localePath(submenu.menuSubSections[0].url.path)" class="c-sub-menu-mobile-nav__btn c-sub-menu-mobile-nav__btn--main" @click.native="$emit('closeMenu')">
            <span class="c-sub-menu-mobile-nav__label">{{ submenu.menuSubSections[0].name }}</span>
            <span><PlusIcon /></span>
          </nuxt-link>
        </li>
        <template v-if="submenu.menuSubSections[0]">
          <li v-for="(item, index) in submenu.menuSubSections[0].menuSubSectionItems" :key="item.name" class="c-sub-menu-mobile-nav__item">
            <div v-if="item.children.length > 0">
              <button class="c-sub-menu-mobile-nav__btn" :class="{'c-sub-menu-mobile-nav__btn--open': openMenu === index}" @click="toggle(index)">
                <span class="c-sub-menu-mobile-nav__label">{{ item.name }}</span>
                <span><ArrowDownIcon /></span>
              </button>
              <ul v-show="openMenu === index" class="c-sub-sub-menu-mobile-nav">
                <li class="c-sub-sub-menu-mobile-nav__item">
                  <nuxt-link :to="localePath(item.url.path)" class="c-sub-sub-menu-mobile-nav__link c-sub-sub-menu-mobile-nav__link--main" @click.native="$emit('closeMenu')">
                    {{ $t('components.molecules.header.menuMobile.all_prefix') }} {{ item.name }}
                  </nuxt-link>
                </li>
                <li v-for="subitem in item.children" :key="subitem.name" class="c-sub-sub-menu-mobile-nav__item">
                  <nuxt-link :to="localePath(subitem.url.path)" class="c-sub-sub-menu-mobile-nav__link" @click.native="$emit('closeMenu')">
                    <span class="c-sub-menu-mobile-nav__label">{{ subitem.name }}</span>
                  </nuxt-link>
                </li>
              </ul>
            </div>
            <nuxt-link v-else :to="localePath(item.url.path)" class="c-sub-menu-mobile-nav__btn" @click.native="$emit('closeMenu')">
              <span class="c-sub-menu-mobile-nav__label">{{ item.name }}</span>
              <span><PlusIcon /></span>
            </nuxt-link>
          </li>
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
import ArrowLeftIcon from '@/components/atoms/icons/ArrowLeft'
import ArrowDownIcon from '@/components/atoms/icons/ArrowDown'
import PlusIcon from '@/components/atoms/icons/Plus'

export default {
  components: { ArrowLeftIcon, ArrowDownIcon, PlusIcon },
  props: {
    submenu: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      openMenu: null
    }
  },

  methods: {
    toggle (index) {
      if (this.openMenu === index) {
        this.openMenu = null
      } else {
        this.openMenu = index
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-sub-menu-mobile {
  &__back {
    padding: 2rem 2.3rem 2rem 1.8rem;
    background-color: $grey-200;
    text-align: left;

    button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $white;
      font-size: 1.3rem;
      font-weight: 700;
      text-transform: uppercase;
    }

    ::v-deep svg {
      display: block;
      width: 1rem;
      height: auto;
      margin-right: 1.9rem;
      fill: $white;
    }
  }
}

.c-sub-menu-mobile-nav {
  $c: &;

  overflow: hidden;

  &__list {
    padding-left: 0;
    list-style-type: none;
  }

  &__item {
    border-top: 0.1rem solid $grey-1100;
    margin-bottom: 0;

    &:first-child {
      border-bottom: none;
    }
  }

  &__btn {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2.3rem 2rem 1.8rem;
    border: none;
    background-color: $white;
    color: $grey-300;
    font-size: 1.3rem;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;

    ::v-deep svg {
      display: block;
      width: 100%;
      height: auto;
      fill: currentcolor;
    }

    > span {
      color: $grey-300;
      pointer-events: none;
      transition: $default-transition;

      &:last-child {
        width: 0.8rem;
      }
    }

    &--open {
      font-weight: 700;

      span + span {
        transform: rotate(-180deg);
      }
    }

    &--main {
      text-transform: uppercase;
    }
  }

  &__sub-menu {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    overflow-y: auto;
  }
}

.c-sub-sub-menu-mobile-nav {
  padding: 0 2rem;
  background: $grey-1300;

  &__item {
    border-top: 0.1rem solid $grey-1100;
    margin-bottom: 0;
  }

  &__link {
    display: block;
    padding: 2rem 1rem;
    background-color: transparent;
    color: $grey-300;
    font-size: 1.2rem;
    text-decoration: none;

    &--main {
      text-transform: uppercase;
    }
  }
}
</style>
