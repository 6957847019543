import DataStructure from './DataStructure'

import BasketItem from './BasketItem'
import BasketPromotion from './BasketPromotion'
import CheckoutCarrier from './checkoutCarrier'
export default class Basket extends DataStructure {
  constructor ({
    basketItems = null,
    basketPromotion = null,
    defaultCarrier = null,
    price = null,
    dutyFreePrice = null,
    priceWithDefaultCarrier = null,
    weight = null,
    countryIsoCode = null,
    device = null
  }) {
    super()
    this.basketItems = this.isset(basketItems) ? this.generateBasketItems(basketItems) : []
    this.basketPromotion = this.isset(basketPromotion) ? this.generateBasketPromotion(basketPromotion) : null
    this.defaultCarrier = this.isset(defaultCarrier) ? this.generateDefaultCarrier(defaultCarrier) : null
    this.price = this.isset(price) ? this.convertToNumber(price) : null
    this.dutyFreePrice = this.isset(dutyFreePrice) ? this.convertToNumber(dutyFreePrice) : null
    this.priceWithDefaultCarrier = priceWithDefaultCarrier
    this.weight = this.isset(weight) ? this.convertToNumber(weight) : null
    this.countryIsoCode = this.isset(countryIsoCode) ? this.convertToString(countryIsoCode) : null
    this.device = this.isset(device) ? this.convertToNumber(device) : null
  }

  generateBasketItems (basketItemsArray) {
    if (!basketItemsArray || !basketItemsArray.length) {
      return []
    }
    return basketItemsArray.map((basketItem) => {
      const item = new BasketItem(basketItem)
      return item.getApiObject()
    })
  }

  generateBasketPromotion (basketPromotion) {
    if (!basketPromotion) {
      return null
    }
    const basketPromotionClass = new BasketPromotion(basketPromotion)
    return basketPromotionClass.getApiObject()
  }

  generateDefaultCarrier (defaultCarrier) {
    if (!defaultCarrier) {
      return null
    }
    const CheckoutCarrierClass = new CheckoutCarrier(defaultCarrier)
    return CheckoutCarrierClass.getApiObject()
  }
}
