import { getRefererByName } from '@/services/referer'

export async function getReferer ({ route, $api }) {
  if (!route.query?.referer) {
    return null
  } else {
    try {
      const referer = await getRefererByName({
        refererName: route.query?.referer,
        instance: $api
      })
      return referer
    } catch (e) {
      return null
    }
  }
}

export function getCookiesReferer (cookies) {
  return cookies.get('referer') ? cookies.get('referer') : null
}

export function resetCookiesReferer (cookies) {
  if (cookies.get('referer')) {
    cookies.remove('referer')
  }
}
