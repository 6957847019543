<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      basket: state => state.basket && state.basket.basket,
      notifiedLowStock: state => state.toast && state.toast.notifiedLowStock,
      displayToaster: state => state.toast && state.toast.displayToaster
    })
  },
  mounted () {
    if (this.basket && !this.notifiedLowStock && !this.isProductPage()) {
      const lowStockProducts = this.basket.filter((product) => {
        return product.info.quantity <= 2
      }).sort((a, b) => {
        if (a.info.price < b.info.price) {
          return -1
        }
        if (a.info.price > b.info.price) {
          return 1
        }
        return 0
      })

      const product = lowStockProducts[0]
      if (product) {
        const title = this.$tc('components.atoms.toast.lowStockTitle', product.info.quantity === 1)
        const defaultPicture = product.info.pictures.find(picture => picture.default)
        this.pushToast({
          key: product.info.id,
          thumbnail: defaultPicture ? defaultPicture.url : null,
          title,
          message: this.$t('components.atoms.toast.lowStockMessage'),
          ttl: 7000,
          clickAction: this.goToBasket
        })
        this.toggleNotifiedLowStock()
      }
    }
  },
  methods: {
    goToBasket () {
      this.$router.push({ path: '/checkout/basket' })
    },
    ...mapActions({
      toggleNotifiedLowStock: 'toast/toggleNotifiedLowStock',
      pushToast: 'toast/pushToast'
    }),
    isProductPage () {
      return this.$store.state.pagename != null && (this.$store.state.pagename.startsWith('p-all') || this.$store.state.pagename.startsWith('marques___'))
    }
  }
}
</script>
