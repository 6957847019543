import DataStructure from './DataStructure'
import PromotionValidity from './promotionValidity'
export default class BasketPromotion extends DataStructure {
  constructor ({
    code = null,
    valid = null,
    name = null,
    discount = null,
    taxRate = null,
    promotionValidity = null
  }) {
    super()
    this.code = this.isset(code) ? this.convertToString(code) : null
    this.valid = this.isset(valid) ? this.convertToBoolean(valid) : null
    this.name = this.isset(name) ? this.convertToString(name) : null
    this.discount = this.isset(discount) ? this.convertToNumber(discount) : null
    this.taxRate = this.isset(taxRate) ? this.convertToNumber(taxRate) : null
    this.promotionValidity = this.isset(promotionValidity) ? this.getPromotionValidity(promotionValidity) : null
  }

  getPromotionValidity (value) {
    if (!value) {
      return null
    }
    const promotionValidity = new PromotionValidity(value)
    return promotionValidity.getApiObject()
  }
}
