<template>
  <div class="c-skeleton-grid">
    <div
      v-for="item in itemsNumber"
      :key="item"
      class="c-skeleton-grid__item"
      :class="{
        'u-1/6 u-1/2@tablet': gridSix,
        'u-1/4 u-1/2@tablet': gridFour,
        'u-1/2 u-1/1@tablet':gridTwo
      }"
    >
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    itemsNumber: {
      type: Number,
      default: 6
    },
    gridTwo: {
      type: Boolean
    },
    gridFour: {
      type: Boolean
    },
    gridSix: {
      type: Boolean
    }
  }
}
</script>
<style lang="scss" scoped>
.c-skeleton-grid {
  display: flex;
  flex-wrap: wrap;

  &__item {
    padding: 0 1.1rem;
    margin-bottom: 2.2rem;

    @include mq($until: tablet) {
      padding: 0 0.25rem;
    }
  }
}
</style>
