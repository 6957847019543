export function generatePathWithQueryParams (urlObject) {
  let queryParam = ''
  if (typeof urlObject.queryParams === 'object') {
    for (const key in urlObject.queryParams) {
      if (urlObject.queryParams[key]) {
        queryParam += key + '=' + urlObject.queryParams[key] + '&'
      }
    }
  }
  return queryParam.length ? urlObject.path + '?' + queryParam : urlObject.path
}
