<template functional>
  <div
    class="c-reinsurance"
    :class="{'u-hidden u-visible@tablet': props.onlyOnMobile}"
  >
    <ul>
      <li class="c-reinsurance__col u-1/4 u-1/3@tablet">
        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 30.5 29.1"><path d="M25.1 0H5.4L0 7.6v21.5h30.5V7.6L25.1 0zm2.4 6.9H16.2V2H24l3.5 4.9zM6.4 2h7.8v4.9H2.9L6.4 2zM2 27.1V8.9h26.5v18.2H2z" /><path d="M14.3 14.2l-1.4-1.4-5.4 5.3 5.4 5.4 1.4-1.4-2.9-3h10.1v-2H11.4z" /></svg>
        <p>
          {{ parent.$t('components.molecules.footer.reinssurance.satisfied1') }}<br>
          {{ parent.$t('components.molecules.footer.reinssurance.satisfied2') }}
        </p>
      </li>
      <li class="c-reinsurance__col u-1/4 u-1/3@tablet">
        <svg xmlns="http://www.w3.org/2000/svg" width="37px" height="24px" viewBox="0 0 38.1 24"><path d="M26.1 24c-6.6 0-12-5.4-12-12s5.4-12 12-12 12 5.4 12 12-5.4 12-12 12zm0-22c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10z" /><path d="M27.8 14.6l-2.7-2.8V5.2h2V11l2.1 2.2zM0 5.3h11.5v2H0zM4.1 11h7.4v2H4.1zM7.1 16.7h4.4v2H7.1z" /></svg>
        <p>
          {{ parent.$t('components.molecules.footer.reinssurance.quickdelivery1') }}<br>
          {{ parent.$t('components.molecules.footer.reinssurance.quickdelivery2') }}
        </p>
      </li>
      <li class="c-reinsurance__col u-1/4 u-1/3@tablet">
        <svg xmlns="http://www.w3.org/2000/svg" width="35px" height="22px" viewBox="0 0 36.2 22.1"><path d="M36.2 10.4L30.1 0H0v19.4h5.5c.4 1.6 1.8 2.7 3.5 2.7s3.1-1.1 3.5-2.7h12.3c.4 1.6 1.9 2.7 3.5 2.7s3.1-1.1 3.5-2.7H36v-9zM28.9 2l4.5 7.7H22.8V2h6.1zM9 20.1c-.9 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7 1.7.8 1.7 1.7-.8 1.7-1.7 1.7zm0-5.4a3.5 3.5 0 0 0-3.5 2.7H2V2h18.8v15.4h-8.2A3.9 3.9 0 0 0 9 14.7zm19.4 5.4c-.9 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7 1.7.8 1.7 1.7-.7 1.7-1.7 1.7zm3.6-2.7c-.4-1.6-1.9-2.7-3.5-2.7s-3.1 1.1-3.5 2.7h-2.1v-5.7h11.4v5.7H32z" /><path d="M14.8 4.9L10 9.7 8.2 7.9 6.7 9.3l3.3 3.2 6.2-6.2z" /></svg>
        <p>
          {{ parent.$t('components.molecules.footer.reinssurance.freedelivery') }}
        </p>
      </li>
      <li class="c-reinsurance__col u-1/4 u-hidden@tablet">
        <svg xmlns="http://www.w3.org/2000/svg" width="23px" height="28px" viewBox="0 0 23.6 28.7"><path d="M12 28.7l-.4-.2C-.1 22.9 0 11.9 0 7.7V6h1C3.8 6 9.2 2 11.1.5l.6-.5.6.5c1.9 1.5 7.4 5.3 10.2 5.3h1v1.7c.1 4.1.4 15.1-11.2 21l-.3.2zM2 7.9c0 4.2 0 13.5 9.9 18.6 9.8-5.2 9.7-14.6 9.6-18.8a29 29 0 0 1-9.8-5.2A27 27 0 0 1 2 7.9z" /><path d="M10.1 19l-3.6-3.6L7.9 14l2.2 2.2 5.6-5.6 1.4 1.4z" /></svg>
        <p>
          {{ parent.$t('components.molecules.footer.reinssurance.securedPayment') }}
        </p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    onlyOnMobile: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-reinsurance {
  $c: &;

  background: $grey-1100;

  ul {
    display: flex;
    justify-content: center;
    padding: 3.5rem 2rem;
    margin: 0;
    list-style: none;

    @include mq($until: tablet) {
      padding: 2.5rem 0.8rem;
    }
  }

  &__col {
    display: flex;
    max-width: 30.3rem;
    align-items: center;
    justify-content: center;
    margin: 0;
    &#{$c}__col--livraison {
      br {
        display: none;

        @include mq($until: tablet) {
          display: block;
        }
      }
    }

    @include mq($until: tablet) {
      flex-direction: column;
      justify-content: space-between;
    }

    p {
      margin: 0 0 0 2rem;
      font-size: $font-size-sm;
      font-weight: 300;
      line-height: 1.076;

      @include mq($until: tablet) {
        flex-grow: 1;
        margin: 1rem 0 0;
        font-size: 1rem;
        line-height: 1.2;
        text-align: center;
      }
    }
  }

  svg {
    fill: $grey-300;

    @include mq($until: tablet) {
      height: 2.5rem;
    }
  }
}
</style>
