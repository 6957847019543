import { api } from './instance'

export const getMenu = (options = {}) => {
  const { headers, instance } = options
  return (instance || api).get('/website-main/menu/v3', {
    headers
  })
    .then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getFooter = (options = {}) => {
  const { headers, instance } = options
  return (instance || api).get('/website-main/footer/v3', {
    headers
  })
    .then(res => res.data)
    .catch((e) => {
      throw e
    })
}
