/* @format */

import { api } from '../instance'

export const login = (options = {}) => {
  const { headers, instance, credentials } = options
  return (instance || api)
    .post('/security/login', credentials, { headers })
    .then((res) => res.data)
    .catch((e) => {
      throw e
    })
}

export const refresh = (options = {}) => {
  const { headers, instance, token } = options
  return (instance || api)
    .post(
      '/security/refresh',
      { refresh_token: token },
      {
        headers,
      },
    )
    .then((res) => res.data)
    .catch((e) => {
      throw e
    })
}

export const appleLogin = (options = {}) => {
  const { headers, instance, auth, user, redirectUri } = options

  return new Promise((resolve, reject) => {
    if (!auth.code) {
      reject(new Error('auth code not found'))
    }

    const applePayload = {
      code: auth.code,
      clientId: process.env.APPLE_CLIENT_ID,
      firstName: user?.firstName || null,
      lastName: user?.lastName || null,
      redirectUri: redirectUri || null,
    }

    const apiInstance = instance || api

    apiInstance
      .post('/social-connect/apple', applePayload, {
        headers,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        throw e
      })
  })
}

export const facebookLogin = (options = {}) => {
  const { headers, instance, accessToken, userID } = options

  return new Promise((resolve, reject) => {
    if (!accessToken) {
      reject(new Error('access token not found'))
    }

    if (!userID) {
      reject(new Error('user id code not found'))
    }

    const facebookPayload = {
      accessToken,
      userID,
    }

    const apiInstance = instance || api

    apiInstance
      .post('/social-connect/facebook', facebookPayload, {
        headers,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        throw e
      })
  })
}

export function getFacebookAuthInfos() {
  return new Promise((resolve, reject) => {
    window.FB.getLoginStatus(function (response) {
      if (response.status !== 'connected') {
        window.FB.login(
          (response) => {
            if (response.status === 'not_authorized') {
              reject(new Error(response.status))
            } else {
              resolve(response)
            }
          },
          { scope: 'public_profile,email' },
        )
      } else {
        resolve(response)
      }
    })
  })
}
