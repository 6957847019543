import {
  getTemplatePageBySlug as getTemplatePageBySlugApi
} from '@/api/templatePage'

export const getTemplatePageBySlug = ({
  slug,
  version = 'v3',
  instance,
  headers
} = {}) => {
  return getTemplatePageBySlugApi({
    slug,
    version,
    headers,
    instance
  })
}
