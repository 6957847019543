import {
  getCustomerOrders as getCustomerOrdersApi,
  getOrder as getOrderApi,
  getOrderBill as getOrderBillApi,
  getPurchaseOrderPdf as getPurchaseOrderPdfApi
} from '@/api/secure/order'

export const getCustomerOrders = ({
  headers,
  instance
} = {}) => {
  return getCustomerOrdersApi({
    headers,
    instance
  })
}

export const getOrder = ({
  orderId,
  headers,
  instance
} = {}) => {
  return getOrderApi({
    orderId,
    headers,
    instance
  })
}

export const getOrderBill = ({
  orderId,
  headers,
  instance
} = {}) => {
  return getOrderBillApi({
    orderId,
    headers,
    instance
  }).then((response) => {
    const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0]

    return { filename, data: response.data }
  })
}

export const getPurchaseOrderPdf = ({
  orderId,
  headers,
  instance
} = {}) => {
  return getPurchaseOrderPdfApi({
    orderId,
    headers,
    instance
  }).then((response) => {
    const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0]

    return { filename, data: response.data }
  })
}
