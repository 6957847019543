<template>
  <div>
    <div class="o-layout js-extra-elements">
      <AppBanner :current-inter-store="currentInterStore" />
      <SaleBannerContext v-if="headerBanner" :banner="headerBanner" />
      <FormMessage
        v-if="homeBanner"
        success
        header
        no-close
        @hide="hideAccountBanner"
      >
        {{ homeBanner }}
      </FormMessage>
    </div>
    <div class="o-layout">
      <div>
        <VHeader />
        <div>
          <nuxt />
        </div>
        <Reinsurance />
        <NewsletterForm />
        <VFooter>
          <template #left>
            <FooterSitemap />
          </template>
          <template #right>
            <FooterSocialNetwork />
            <FooterApp />
          </template>
        </VFooter>
        <FooterCredits>
          &copy; {{ year }} - {{ $t('components.molecules.footer.credits.copyright') }}
        </FooterCredits>
        <ModalLocalStorage />
        <ModalOutlet v-if="popinOutlet" />
      </div>
    </div>
    <ClientOnly>
      <ToastOverlay v-if="displayToaster" />
    </ClientOnly>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import dayjs from 'dayjs'

import FooterCredits from '@/components/atoms/footer/footer-credits/FooterCredits'
import FooterApp from '@/components/molecules/footer/footer-app/FooterApp'
import FooterSocialNetwork from '@/components/molecules/footer/footer-social-network/FooterSocialNetwork'
import FooterSitemap from '@/components/molecules/footer/footer-sitemap/FooterSitemap'
import NewsletterForm from '@/components/molecules/footer/newsletter-form/NewsletterForm'
import Reinsurance from '@/components/molecules/footer/reinsurance/Reinsurance'
import productToastMixin from '~/mixins/productToastMixin'
import SaleBannerContext from '@/components/molecules/header/sale-banner/SaleBannerContext'
import VFooter from '@/components/organisms/layout/footer/VFooter'
import VHeader from '@/components/organisms/layout/header/VHeader'
import { sendSentryMessage } from '@/helpers/errorHelper'
import ModalLocalStorage from '@/components/organisms/home/modal-localstorage/ModalLocalStorage.vue'

export default {
  name: 'Home',
  components: {
    AppBanner: () => import('@/components/molecules/header/app-banner/AppBanner.vue'),
    FooterApp,
    FooterCredits,
    FooterSitemap,
    FooterSocialNetwork,
    FormMessage: () => import('@/components/atoms/form/message/FormMessage.vue'),
    ModalOutlet: () => import('@/components/molecules/modal/modal-outlet/ModalOutlet'),
    ModalLocalStorage,
    NewsletterForm,
    Reinsurance,
    SaleBannerContext,
    ToastOverlay: () => import('@/components/molecules/toast/ToastOverlay'),
    VFooter,
    VHeader
  },
  mixins: [productToastMixin],
  middleware: ['marketingCloudConversion'],
  data () {
    return {
      year: dayjs().format('YYYY')
    }
  },
  computed: {
    ...mapState({
      popinOutlet: state => state.popinOutlet,
      localStorageWarnAccepted: state => state.localStorageWarnAccepted,
      homeBanner: state => state.homeBanner,
      headerBanner: state => state.headerBanner,
      currentInterStore: state => state.international && state.international.currentInterStore
    })
  },
  created () {
    this.$store.commit('ui/UPDATE_TRANSPARENT_HEADER', true)
  },
  mounted () {
    if (!this.$localStorage && !this.localStorageWarnAccepted) {
      this.$modal.show('localStorage')
    }
    if (!this.currentInterStore) {
      sendSentryMessage.bind(this, 'Store is undefined or null', 'fatal', {
        action: 'Mounted Layout',
        currentInterStore: this.currentInterStore
      })()

      this.$error({ statusCode: 500 })
    }
  },
  methods: {
    ...mapActions({
      setHomeBanner: 'setHomeBanner'
    }),
    hideAccountBanner () {
      this.setHomeBanner(null)
    }
  }
}
</script>
