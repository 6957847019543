/* @format */

import { login } from '@/api/secure/authentication'
import BaseCustomStrategy from '@/auth/BaseCustomStrategy'

export default class CustomLocalScheme extends BaseCustomStrategy {
  async loginFn() {
    const [_username, _password, headers] = arguments
    return await login({
      headers,
      credentials: { _username, _password },
    })
  }
}
