<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32">
    <title v-if="props.title">{{ props.title }}</title>
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h32v32H0z" />
      <path fill="#E9252F" stroke="#FFF" stroke-width=".5" d="M20 6.95c1.5049759 0 2.8674759.61001203 3.853732 1.59626804C24.839988 9.53252406 25.45 10.8950241 25.45 12.4c0 1.6795637-.7597288 3.1817043-1.9604002 4.1863277l-7.4954242 5.8852291-7.51797073-5.8826888C7.30582145 15.5738557 6.55 14.0750692 6.55 12.4c0-1.5049759.61001203-2.86747594 1.59626804-3.85373196C9.13252406 7.56001203 10.4950241 6.95 12 6.95c1.5811333 0 3.0050307.6732904 4.0005862 1.74876884C16.9950345 7.62327153 18.4188933 6.95 20 6.95Z" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
