<template>
  <div class="o-layout">
    <div>
      <VHeader />

      <div
        v-if="!pageLoading"
        class="u-space-header"
        :class="{'u-space-header--inter': isInternationalDisplay}"
      >
        <div class="c-account" :class="{'c-account--home': isHome}">
          <div class="c-account__side" :class="{'c-account__side--active': isHome}">
            <AccountPanel />
          </div>

          <div class="c-account__main" :class="{'c-account__main--hidden': isHome}">
            <nuxt />
          </div>
        </div>
      </div>
      <template v-else>
        <Skeleton />
        <Reinsurance />
        <NewsletterForm />
        <VFooter>
          <template #left>
            <FooterSitemap />
          </template>
          <template #right>
            <FooterSocialNetwork />
            <FooterApp />
          </template>
        </VFooter>
      </template>
      <FooterCredits :special="!pageLoading">
        &copy; {{ year }} - {{ $t('components.molecules.footer.credits.copyright') }}
      </FooterCredits>
      <ModalLocalStorage />
      <ModalOutlet v-if="popinOutlet" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import FooterCredits from '@/components/atoms/footer/footer-credits/FooterCredits'
import Skeleton from '@/components/organisms/skeleton/Skeleton.vue'
import VHeader from '@/components/organisms/layout/header/VHeader'
import ModalLocalStorage from '@/components/organisms/home/modal-localstorage/ModalLocalStorage.vue'

export default {
  name: 'Account',
  components: {
    AccountPanel: () => import('@/components/organisms/account/panel/AccountPanel'),
    FooterApp: () => import('@/components/molecules/footer/footer-app/FooterApp'),
    FooterCredits,
    FooterSitemap: () => import('@/components/molecules/footer/footer-sitemap/FooterSitemap'),
    FooterSocialNetwork: () => import('@/components/molecules/footer/footer-social-network/FooterSocialNetwork'),
    ModalOutlet: () => import('@/components/molecules/modal/modal-outlet/ModalOutlet'),
    ModalLocalStorage,
    NewsletterForm: () => import('@/components/molecules/footer/newsletter-form/NewsletterForm'),
    Skeleton,
    Reinsurance: () => import('@/components/molecules/footer/reinsurance/Reinsurance'),
    VFooter: () => import('@/components/organisms/layout/footer/VFooter'),
    VHeader
  },
  data () {
    return {
      year: dayjs().format('YYYY')
    }
  },
  computed: {
    isHome () {
      return this.$store.state.pagename && this.$store.state.pagename.startsWith('account__')
    },
    ...mapState({
      popinOutlet: state => state.popinOutlet,
      localStorageWarnAccepted: state => state.localStorageWarnAccepted,
      pageLoading: state => state.ui.pageLoading
    }),
    ...mapGetters({ isInternationalDisplay: 'international/isInternationalDisplay' })
  },
  watch: {
    popinOutlet (val) {
      if (val) {
        this.$modal.show('outlet')
      }
    }
  },
  mounted () {
    if (!this.$localStorage && !this.localStorageWarnAccepted) {
      this.$modal.show('localStorage')
    }
  }
}
</script>

<style lang="scss" scoped>
.c-account {
  $c: &;

  display: flex;
  width: 100%;
  max-width: 132.2rem;
  min-height: calc(100vh - 18.1rem);
  padding: 3.4rem 0;
  margin: 0 auto;

  @include mq($until: tablet) {
    min-height: calc(100vh - 9rem);
    flex-wrap: wrap;
    padding: 0 0 2rem;
  }

  &__side {
    flex: 0 1 42.6rem;
    padding: 0 4.8rem 0 3.8rem;

    @include mq($until: tablet) {
      display: none;
    }
  }

  &__main {
    // width: calc(100% - 42.6rem);
    flex: 1;
    padding: 0 2.2rem;
    padding-top: 1.5rem;
    &#{$c}__main--hidden {
      @include mq($until: tablet) {
        display: none;
      }
    }
  }

  &__side--active {
    @include mq($until: tablet) {
      display: block !important;
    }
  }

  @include mq($until: tablet) {
    &__side,
    &__main {
      flex: 1 1 100% !important;
      padding: 0 !important;
    }
  }

  // surcharge sur la page accueil du mon compte
  &#{$c}--home {
    flex-wrap: nowrap;
    align-items: flex-start;

    #{$c}__side {
      flex: 0 1 37.8rem;
      padding: 0;
      padding-left: 3.8rem;
    }

    #{$c}__main {
      flex: 1 1 auto;
      padding: 0 4rem 0 1rem;
    }
  }
}
</style>
