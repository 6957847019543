import { api } from './instance'

export const getRefererByName = (options = {}) => {
  const { refererName, headers, instance } = options
  return (instance || api).get(
    `/referers/${refererName}`,
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}
