<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 22.5 18.1">
    <title v-if="props.title">{{ props.title }}</title>
    <path fill="#e9252f" stroke="#e9252f" stroke-miterlimit="10" d="M16.3.5c1.6 0 3 .6 4.1 1.7 1 1 1.7 2.5 1.7 4.1 0 1.8-.8 3.3-2.1 4.4l-8.7 6.8-8.7-6.8C1.4 9.6.6 8 .6 6.3c0-1.6.6-3 1.7-4.1 1-1 2.5-1.7 4.1-1.7 1.8 0 3.4.8 4.4 2.1.2.3.4.5.6.8.2-.3.4-.6.6-.8.9-1.3 2.5-2.1 4.3-2.1z" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
