<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" width="12.5" height="9" viewBox="0 0 25 18">
    <title v-if="props.title">{{ props.title }}</title>
    <path fill="#333" d="m1 6 10 11M24 1 9 17" />
    <path fill="none" stroke="#333" stroke-miterlimit="20" stroke-width="3" d="m1 6 10 11M24 1 9 17" />
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
