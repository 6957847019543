export const state = () => ({
  templatePageData: null
})
export const actions = {
  setTemplatePageData ({ commit }, payload) {
    commit('SET_TEMPLATE_PAGE_DATA', payload)
  },
  cleanTemplatePageData ({ commit }) {
    commit('CLEAN_TEMPLATE_PAGE_DATA')
  }
}

export const mutations = {

  SET_TEMPLATE_PAGE_DATA (state, payload) {
    state.templatePageData = payload
  },
  CLEAN_TEMPLATE_PAGE_DATA (state) {
    state.templatePageData = null
  }
}
