<template>
  <div
    class="c-logo"
    :class="{
      'c-logo--center': center,
      'c-logo--inter': isInternationalDisplay
    }"
  >
    <nuxt-link :to="localePath('/')">
      <LogoLBO
        v-if="!isInternationalDisplay"
        :title="$t('components.atoms.global.logo.title')"
      />
      <LogoRYSES
        v-else
        :title="currentInterStore.displayName"
      />
    </nuxt-link>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LogoLBO from '@/components/atoms/icons/LogoLBO'
import LogoRYSES from '@/components/atoms/icons/LogoRYSES'
/**
 * The `Logo` component : display the website main logo, with a nuxt link to go back to the home page
 */
export default {
  components: { LogoLBO, LogoRYSES },
  props: {
    /**
     * center the logo in his container
     */
    center: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({ isInternationalDisplay: 'international/isInternationalDisplay' }),
    ...mapState({
      currentInterStore: state => state.international && state.international.currentInterStore
    })
  }
}
</script>

<style lang="scss" scoped>
.c-logo {
  display: block;
  width: 100%;
  max-width: 18.9rem;
  margin: 0;

  @include mq($until: tablet) {
    max-width: 10.8rem;
  }

  &--center {
    margin: auto;
  }

  &--inter {
    ::v-deep svg {
      fill: $black !important;
    }
  }

  ::v-deep svg {
    width: 18.9rem;
    fill: $primary;

    @include mq($until: tablet) {
      width: auto;
      height: 2.9rem;
    }
  }
}
</style>
