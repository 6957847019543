<template>
  <nuxt-link
    v-if="link"
    class="c-category"
    :class="{'c-category--small': small}"
    :to="localePath(link)"
    @click.native="trackingClickEndRedirect"
  >
    <LazyImage
      v-if="image"
      :image-mobile="imageMobile"
      :image="image"
      class="c-category__media"
      :alt="title"
      :width="small ? 202: 314"
      :height="small ? 202: 314"
    />
    <div class="c-category__title">
      {{ title }}
    </div>
  </nuxt-link>
</template>

<script>
import LazyImage from '@/components/atoms/global/lazy-image/LazyImage'
import { createTrackPromotionEvent } from '@/helpers/gtmHelper'
/**
 * The `Category` component : display image + text and link
 * the width and height are forced to 202x181 to improve performance : https://web.dev/optimize-cls/?utm_source=lighthouse&utm_medium=devtools#images-without-dimensions
 */
export default {
  components: { LazyImage },
  props: {
    /**
     * the target of the category
     */
    link: {
      type: String,
      required: true
    },
    /**
     * the path of the image to be displayed on desktop
     */
    image: {
      type: String,
      default: null
    },
    /**
     * the path of the image to be displayed on mobile only
     */
    imageMobile: {
      type: String,
      default: null
    },

    /**
     * the title displayed under the image
     */
    title: {
      type: String,
      default: null
    },

    /**
     * reduce margin and font-size
     */
    small: {
      type: Boolean,
      default: false
    },
    promotionData: {
      type: Object,
      default: null
    }
  },
  methods: {
    trackingClickEndRedirect () {
      if (this.promotionData) {
        this.$gtm.trackEvent(
          createTrackPromotionEvent('select_promotion', this.promotionData)
        )
      }
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.c-category {
  $c: &;

  width: 100%;
  color: $grey-300;
  text-decoration: none;
  transition: $transition;

  &:hover,
  &:focus {
    color: $primary;
  }

  picture {
    position: relative;
    display: block;
    padding-bottom: 100%;
    margin-bottom: 2rem;
    background: $grey-1300;

    @include mq($until: tablet) {
      margin-bottom: 1rem;
    }

    & ::v-deep img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }

  &__media {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 2rem;

    &:only-child {
      margin-bottom: 0 !important;
    }

    @include mq($until: tablet) {
      margin-bottom: 1rem;
    }
  }

  &__title {
    padding: 0 0.5rem;
    margin: 0;
    font-size: $font-size-xxl;
    font-weight: 900;
    letter-spacing: 0.3px;
    line-height: 1;
    text-align: center;
    text-transform: none;

    @include mq($until: tablet) {
      font-size: 1.5rem;
      letter-spacing: 0.074rem;
    }
  }

  &--small {
    #{$c}__media {
      margin-bottom: 1.1rem;
    }

    #{$c}__title {
      font-size: $global-font-size;
      line-height: 1;
    }
  }
}
</style>
