<template>
  <div class="c-timeline">
    <div
      class="c-timeline__item"
      :class="{'c-timeline__item--current': current === 1, 'c-timeline__item--activated': current > 1}"
    >
      <span>
        <nuxt-link
          v-if="current > 1 && !specialStep"
          :to="localePath('/checkout/basket')"
        >
          <CheckIcon />
        </nuxt-link>
        <template v-else>
          {{ $t('components.atoms.tunnel.timeline.step1') }}
        </template>
      </span>
      <p>{{ $t('components.atoms.tunnel.timeline.basket') }}</p>
    </div>
    <div
      class="c-timeline__item"
      :class="{'c-timeline__item--current': current === 2, 'c-timeline__item--activated': current > 2}"
    >
      <span>
        <nuxt-link
          v-if="current > 2"
          :to="localePath('/checkout/shipping')"
        >
          <CheckIcon />
        </nuxt-link>
        <template v-else>
          {{ $t('components.atoms.tunnel.timeline.step2') }}
        </template>
      </span>
      <p>{{ $t('components.atoms.tunnel.timeline.shipping') }}</p>
    </div>
    <div
      class="c-timeline__item"
      :class="{'c-timeline__item--current': current === 3, 'c-timeline__item--activated': current > 3}"
    >
      <span>
        <nuxt-link
          v-if="current > 3"
          :to="localePath('/checkout/summary')"
        >
          <CheckIcon />
        </nuxt-link>
        <template v-else>
          {{ $t('components.atoms.tunnel.timeline.step3') }}
        </template>
      </span>
      <p>{{ $t('components.atoms.tunnel.timeline.payment') }}</p>
    </div>
    <div
      class="c-timeline__item"
      :class="{'c-timeline__item--current': current === 4, 'c-timeline__item--activated': current > 4}"
    >
      <span>
        {{ $t('components.atoms.tunnel.timeline.step4') }}
      </span>
      <p>{{ $t('components.atoms.tunnel.timeline.confirm') }}</p>
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/components/atoms/icons/Check'
/**
 * `timeline` component : displays the purshase 4 steps used in the tunnel pages
 **/
export default {
  components: { CheckIcon },
  props: {
    /**
     * Choose witch one is the current step
     */
    current: {
      type: Number,
      default: 1
    },
    specialStep: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-timeline {
  display: flex;
  width: 100%;
  max-width: 91.8rem;
  padding: 2rem 0;
  margin: 0 auto;

  @include mq($until: tablet) {
    padding: 2rem;
  }

  &__item {
    overflow: hidden;
    flex: 1;
    text-align: center;

    &:first-child > span::before {
      content: none;
    }

    &:last-child > span::after {
      content: none;
    }

    span {
      position: relative;
      display: inline-block;
      width: 3rem;
      height: 3rem;
      border: 0.2rem solid $grey-1100;
      background-color: transparent;
      border-radius: 50%;
      color: $grey-900;
      font-size: $font-size-lg;
      font-weight: 900;
      line-height: 1.6;

      &::after {
        position: absolute;
        top: 50%;
        left: 2.8rem;
        width: 1000%;
        height: 0.2rem;
        background-color: $grey-1100;
        content: "";
      }

      &::before {
        position: absolute;
        top: 50%;
        right: 2.8rem;
        width: 1000%;
        height: 0.2rem;
        background-color: $grey-1100;
        content: "";
      }
    }

    p {
      margin: 0;
      margin-top: 0.6rem;
      color: $grey-900;
      font-size: $font-size-sm;
    }

    &--current {
      span {
        border: 0.2rem solid $accent-dark;
        color: $accent-dark;

        &::before {
          background-color: $accent-dark;
        }
      }

      p {
        color: $accent-dark;
      }
    }

    &--activated {
      span {
        border: 0.2rem solid $accent-dark;
        background-color: $accent-dark;
        color: $white;

        svg {
          width: 1.5rem;
          margin-top: -5px;

          path {
            fill: $white;
          }
        }

        &::before,
        &::after {
          background-color: $accent-dark;
        }
      }

      p {
        color: $accent-dark;
      }
    }
  }
}
</style>
