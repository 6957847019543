import DataStructure from './DataStructure'
export default class CarrierPrice extends DataStructure {
  constructor ({
    id = null,
    freeShipping = null,
    freeShippingAmount = null,
    priceWithoutDuty = null,
    priceWithDuty = null
  }) {
    super()
    this.id = this.isset(id) ? this.convertToNumber(id) : null
    this.freeShipping = this.isset(freeShipping) ? this.convertToBoolean(freeShipping) : null
    this.freeShippingAmount = this.isset(freeShippingAmount) ? this.convertToNumber(freeShippingAmount) : null
    this.priceWithoutDuty = this.isset(priceWithoutDuty) ? this.convertToNumber(priceWithoutDuty) : null
    this.priceWithDuty = this.isset(priceWithDuty) ? this.convertToNumber(priceWithDuty) : null
  }
}
