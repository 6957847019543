<template>
  <div class="o-layout u-bg--grey-lightest">
    <div>
      <HeaderTunnel />
      <main class="l-container">
        <nuxt />
      </main>
      <Reinsurance />
      <FooterCredits special>
        &copy; {{ year }} - {{ $t('components.molecules.footer.credits.copyright') }}
      </FooterCredits>
      <ModalLocalStorage />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import HeaderTunnel from '@/components/organisms/layout/header-tunnel/HeaderTunnel'
import FooterCredits from '@/components/atoms/footer/footer-credits/FooterCredits'
import Reinsurance from '@/components/molecules/footer/reinsurance/Reinsurance'
import GtmMixin from '@/mixins/gtm'
import ModalLocalStorage from '@/components/organisms/home/modal-localstorage/ModalLocalStorage.vue'

export default {
  name: 'Confirm',
  components: { HeaderTunnel, FooterCredits, Reinsurance, ModalLocalStorage },
  mixins: [GtmMixin],
  data () {
    return {
      year: dayjs().format('YYYY')
    }
  },
  computed: {
    ...mapState({
      localStorageWarnAccepted: state => state.localStorageWarnAccepted
    }),
  },
  mounted () {
    if (!this.$localStorage && !this.localStorageWarnAccepted) {
      this.$modal.show('localStorage')
    }
  }
}
</script>

<style lang="scss" scoped>
.l-container {
  width: 100%;
  max-width: 91.8rem;
  min-height: calc(100vh - 13.2rem);
  padding-bottom: 6rem;
  margin: 0 auto;

  @include mq($until: tablet) {
    max-width: 90%;
    min-height: auto;
    padding-bottom: 0;
  }
}
</style>
