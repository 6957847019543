<template>
  <div>
    <div class="o-layout js-extra-elements">
      <AppBanner :current-inter-store="currentInterStore" />
      <SaleBannerContext v-if="headerBanner" :banner="headerBanner" />
    </div>
    <div class="o-layout">
      <div>
        <VHeader
          :is-listing="isListing"
        />
        <div :class="spaceHeaderClasses">
          <nuxt />
        </div>
        <Reinsurance />
        <NewsletterForm />
        <VFooter>
          <template #left>
            <FooterSitemap />
          </template>
          <template #right>
            <FooterSocialNetwork />
            <FooterApp />
          </template>
        </VFooter>
        <FooterCredits>
          &copy; {{ year }} - {{ $t('components.molecules.footer.credits.copyright') }}
        </FooterCredits>
        <ModalLocalStorage />
        <ModalOutlet v-if="popinOutlet" />
      </div>
    </div>
  </div>
</template>

<script>
// LIBS
import { mapState, mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'

// MISC
import { sendSentryMessage } from '@/helpers/errorHelper'

// COMPONENTS
import FooterCredits from '@/components/atoms/footer/footer-credits/FooterCredits'
import FooterApp from '@/components/molecules/footer/footer-app/FooterApp'
import FooterSocialNetwork from '@/components/molecules/footer/footer-social-network/FooterSocialNetwork'
import FooterSitemap from '@/components/molecules/footer/footer-sitemap/FooterSitemap'
import NewsletterForm from '@/components/molecules/footer/newsletter-form/NewsletterForm'
import Reinsurance from '@/components/molecules/footer/reinsurance/Reinsurance'
import VFooter from '@/components/organisms/layout/footer/VFooter'
import VHeader from '@/components/organisms/layout/header/VHeader'
import SaleBannerContext from '@/components/molecules/header/sale-banner/SaleBannerContext'
import ModalLocalStorage from '@/components/organisms/home/modal-localstorage/ModalLocalStorage.vue'

export default {
  name: 'TemplatePage',
  components: {
    AppBanner: () => import('@/components/molecules/header/app-banner/AppBanner'),
    FooterApp,
    FooterCredits,
    FooterSitemap,
    FooterSocialNetwork,
    ModalOutlet: () => import('@/components/molecules/modal/modal-outlet/ModalOutlet'),
    ModalLocalStorage,
    NewsletterForm,
    Reinsurance,
    SaleBannerContext,
    VFooter,
    VHeader
  },
  middleware: ['marketingCloudConversion', 'templatePage'],
  data () {
    return {
      year: dayjs().format('YYYY')
    }
  },
  computed: {
    isListing () {
      return this.$store.state.pagename != null && this.$store.state.pagename.startsWith('all')
    },
    isProduct () {
      return this.$store.state.pagename != null && this.$store.state.pagename.startsWith('p-all')
    },
    spaceHeaderClasses () {
      return {
        'u-space-header': !this.templateData.header,
        'u-space-header--inter': this.isInternationalDisplay && !this.templateData.header
      }
    },
    ...mapState({
      templateData: state => state.templatePage?.templatePageData,
      localStorageWarnAccepted: state => state.localStorageWarnAccepted,
      popinOutlet: state => state.popinOutlet,
      headerBanner: state => state.headerBanner,
      currentInterStore: state => state.international && state.international.currentInterStore
    }),
    ...mapGetters({
      isInternationalDisplay: 'international/isInternationalDisplay'
    })
  },
  watch: {
    popinOutlet (val) {
      if (val) {
        this.$modal.show('outlet')
      }
    }
  },
  beforeDestroy () {
    this.cleanTemplatePageData()
  },
  created () {
    if (this.templateData.header.layout === 'slider') {
      this.$store.commit('ui/UPDATE_TRANSPARENT_HEADER', true)
    }
  },
  mounted () {
    if (!this.$localStorage && !this.localStorageWarnAccepted) {
      this.$modal.show('localStorage')
    }
    if (!this.currentInterStore) {
      sendSentryMessage.bind(this, 'Store is undefined or null', 'fatal', {
        action: 'Mounted Layout',
        currentInterStore: this.currentInterStore
      })()

      this.$error({ statusCode: 500 })
    }
  },
  methods: {
    ...mapActions({
      cleanTemplatePageData: 'templatePage/cleanTemplatePageData'
    })
  }
}
</script>
