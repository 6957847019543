<template>
  <nav class="c-menu-lang">
    <button
      class="c-menu-lang__btn"
      @click="toggleMenuLang"
    >
      <WorldWide :title="$t('components.molecules.header.menuLang.country')" />
      {{ $t('components.molecules.header.menuLang.country') }}
    </button>
    <transition name="slide">
      <div
        v-if="langOpen"
        class="c-menu-lang__content"
      >
        <button
          class="c-menu-lang__close"
          @click="toggleMenuLang"
        >
          <span>{{ $t('components.molecules.header.menuLang.chooseCountry') }}</span>
          <CloseIcon />
        </button>
        <InternationalLangList :mobile="true" />
      </div>
    </transition>
  </nav>
</template>
<script>
import { mapState } from 'vuex'
import CloseIcon from '@/components/atoms/icons/Close'
import InternationalLangList from '@/components/molecules/header/international-lang-list/InternationalLangList'
import WorldWide from '@/components/atoms/icons/WorldWide'
export default {
  components: {
    CloseIcon,
    InternationalLangList,
    WorldWide
  },
  data () {
    return {
      langOpen: false
    }
  },
  computed: {
    ...mapState({
      currentInterStore: state => state.international?.currentInterStore
    })
  },
  methods: {
    toggleMenuLang () {
      this.langOpen = !this.langOpen
      this.$emit('open', this.langOpen)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-menu-lang {
  &__btn {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1.3rem 2.1rem;
    border-bottom: 0.1rem solid $grey-800;
    color: $grey-300;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;

    &::v-deep svg {
      width: 1.7rem;
      height: 1.7rem;
      margin-right: 1.5rem;
      fill: $grey-250;
    }
  }

  &__content {
    position: fixed;
    top: 0;
    left: 0;
    width: 83.88%;
    height: 100%;
    background-color: $white;
  }

  &__close {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2.3rem 2rem 1.8rem;
    background-color: $grey-200;
    color: $white;
    font-size: 1.3rem;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;

    svg {
      display: block;
      width: 1rem;
      height: 1rem;
      fill: $white;
    }
  }
}

.slide-enter-active {
  transform: translateX(0);
  transition: transform 0.3s ease;
}

.slide-leave-active {
  transform: translateX(0);
  transition: transform 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}
</style>
