import dayjs from 'dayjs'
import { mapState } from 'vuex'
import { CHRISTMAS_DATE_BANNER } from '@/config.js'

export default {
  data () {
    return {
      displayChristmasBanner: false,
      christmasBannerContentToDisplay: 'content1'
    }
  },
  computed: {
    ...mapState({
      currentInterStore: state => state.international && state.international.currentInterStore
    })
  },
  mounted () {
    const dateContent1 = dayjs(CHRISTMAS_DATE_BANNER[this.currentInterStore.id].content1)
    const dateContent2 = dayjs(CHRISTMAS_DATE_BANNER[this.currentInterStore.id].content2)
    const dateContent3 = dayjs(CHRISTMAS_DATE_BANNER[this.currentInterStore.id].content3)
    const dateContent4 = dayjs(CHRISTMAS_DATE_BANNER[this.currentInterStore.id].content4)
    const endDate = dayjs(CHRISTMAS_DATE_BANNER[this.currentInterStore.id].endDate)
    const currentDate = dayjs()
    if (currentDate.isAfter(dateContent1) && currentDate.isBefore(endDate)) {
      this.displayChristmasBanner = true
      switch (true) {
        case currentDate.isBefore(dateContent2):
          this.christmasBannerContentToDisplay = 'content1'
          break
        case currentDate.isBefore(dateContent3):
          this.christmasBannerContentToDisplay = 'content2'
          break
        case currentDate.isBefore(dateContent4):
          this.christmasBannerContentToDisplay = 'content3'
          break
        case currentDate.isBefore(endDate):
          this.christmasBannerContentToDisplay = 'content4'
          break
      }
    }
  }
}
