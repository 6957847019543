var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.mobile)?_c('div',{staticClass:"c-header-search"},[_c('form',{ref:"searchForm",staticClass:"c-header-search__form",attrs:{"method":"get"},on:{"submit":function($event){$event.preventDefault();return _vm.validForm.apply(null, arguments)}}},[_c('vue-autosuggest',{ref:"searchField",staticClass:"autosuggest c-btn-with-input",class:{
        'autosuggest--focus': _vm.focus,
        'c-btn-with-input--inactive': !_vm.formReady,
      },attrs:{"suggestions":_vm.suggestions,"should-render-suggestions":_vm.shouldRenderSuggestions,"get-suggestion-value":_vm.getSuggestionValue,"input-props":{
        name: 'motclef',
        placeholder: _vm.$t('components.molecules.header.headerSearch.search'),
      }},on:{"selected":_vm.onSelect,"input":_vm.refine,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validForm.apply(null, arguments)},"focus":function($event){_vm.focus = true
        _vm.showSuggestions = true},"blur":function($event){_vm.focus = false}},scopedSlots:_vm._u([{key:"default",fn:function({ suggestion }){return _c('div',{},[_c('Highlight',[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$options.filters.highlight(
                suggestion.item.text[_vm.$i18n.locale],
                _vm.algoliaQuery,
              )
            )}})]),_vm._v(" "),(suggestion.item.productsNumber)?_c('span',[_vm._v("\n          ("+_vm._s(suggestion.item.productsNumber)+")\n        ")]):_vm._e()],1)}}],null,false,2596543368),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[_c('template',{slot:"after-input"},[_c('VButton',{attrs:{"type":"submit","title":_vm.$t('components.molecules.header.headerSearch.search'),"disabled":!_vm.formReady}},[_c('SearchIcon',{attrs:{"tile":_vm.$t('components.molecules.header.headerSearch.search')}})],1)],1),_vm._v(" "),(!_vm.query)?_c('template',{slot:"before-suggestions"},[_c('div',{staticClass:"c-header-search__title"},[_vm._v("\n          "+_vm._s(_vm.$t('components.molecules.header.headerSearch.lastSearch'))+"\n        ")])]):_vm._e()],2)],1)]):_c('div',{staticClass:"c-search-header-mobile"},[_c('button',{staticClass:"c-search-header-mobile__close",on:{"click":function($event){return _vm.$emit('close')}}},[_c('CloseIcon',{attrs:{"title":_vm.$t('components.molecules.header.headerSearch.close')}})],1),_vm._v(" "),_c('div',{staticClass:"c-search-header-mobile__wrapper"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validForm.apply(null, arguments)}}},[_c('InputForm',{attrs:{"type":"search","name":"motclef","placeholder":"Rechercher"},on:{"input":function($event){return _vm.refine($event)}},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_vm._v(" "),(_vm.query)?_c('div',{staticClass:"c-search-header-mobile__results"},[_c('div',_vm._l((_vm.currentRefinement),function(hit){return _c('a',{key:hit.objectID,staticClass:"c-search-header-mobile__results-item",attrs:{"href":hit.urls[_vm.$i18n.locale]},on:{"click":function($event){$event.preventDefault();return _vm.onSelect({ item: hit })}}},[_c('span',[_c('Highlight',[_c('span',{domProps:{"innerHTML":_vm._s(
                  _vm.$options.filters.highlight(
                    hit.text[_vm.$i18n.locale],
                    _vm.algoliaQuery,
                  )
                )}})]),_vm._v(" "),(hit.productsNumber)?_c('span',[_vm._v("("+_vm._s(hit.productsNumber)+")")]):_vm._e()],1),_vm._v(" "),_c('span',{staticClass:"c-search-header-mobile__results-icon"},[_c('ArrowRightIcon')],1)])}),0)]):_c('div',{staticClass:"c-search-header-mobile__results"},[_c('div',{staticClass:"c-header-search__title"},[_vm._v("\n        "+_vm._s(_vm.$t('components.molecules.header.headerSearch.lastSearch'))+"\n        "),(_vm.mobile && _vm.previousSearch.length > 0)?_c('button',{staticClass:"c-header-search__clean",attrs:{"type":"button"},on:{"click":_vm.cleanSearchList}},[_vm._v("\n          "+_vm._s(_vm.$t('components.molecules.header.headerSearch.clean'))+"\n        ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"c-search-header-mobile__results-list"},_vm._l((_vm.previousSearch),function(suggestion){return _c('a',{key:suggestion.objectID,staticClass:"c-search-header-mobile__results-item",attrs:{"href":suggestion.urls[_vm.$i18n.locale]}},[_c('span',[_vm._v(_vm._s(suggestion.text[_vm.$i18n.locale]))]),_vm._v(" "),_c('span',{staticClass:"c-search-header-mobile__results-icon"},[_c('ArrowRightIcon')],1)])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }