<template>
  <div class="c-sale-banner">
    <picture>
      <!-- Prevent CLS by setting width/height attributes  -->
      <source
        media="(max-width: 1024px)"
        :srcset="imageMobileSrc"
        width="1000"
        height="65"
      >
      <img
        width="1366"
        height="27"
        :src="imageSrc"
        :alt="altText"
      >
    </picture>
    <ClientOnly>
      <a
        class="c-sale-banner__link"
        noopener
        noreferrer
        :aria-label="altText"
        :href="link"
      >
        <span class="u-text--sr-only">
          {{ altText }}
        </span>
      </a>
    </ClientOnly>
  </div>
</template>

<script>
export default {
  name: 'SaleBanner',
  props: {
    imageSrc: {
      type: String,
      required: true
    },
    imageMobileSrc: {
      type: String,
      required: true
    },
    altText: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.c-sale-banner {
  position: relative;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  &__link {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
