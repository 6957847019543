var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"header",staticClass:"c-header js-header-fix-listing",class:{
      'c-header--white': !_vm.transparentHeader,
      'c-header--up': _vm.scroll === 'up',
      'c-header--down': _vm.scroll === 'down',
      'c-header--fixed': _vm.fixed,
      'c-header--inter': _vm.isInternationalDisplay
    }},[_c('div',{staticClass:"c-header__top"},[_c('button',{staticClass:"c-header__toggle u-hidden u-visible@tablet",on:{"click":_vm.toggle}},[_c('MenuIcon',{attrs:{"title":"menu"}})],1),_vm._v(" "),_c('div',{staticClass:"c-header__logo"},[_c('nuxt-link',{attrs:{"to":_vm.localePath('/')},nativeOn:{"click":function($event){return _vm.checkRedirect('/')}}},[(!_vm.isInternationalDisplay)?_c('LogoLBO',{attrs:{"title":_vm.$t('components.organisms.layout.header.logoTitle')}}):_c('LogoRYSES',{attrs:{"title":_vm.currentInterStore.displayName}})],1)],1),_vm._v(" "),_c('HeaderSearch'),_vm._v(" "),_c('HeaderTools',{attrs:{"is-listing":_vm.isListing,"fixed":_vm.fixed}})],1),_vm._v(" "),(_vm.$store.state.ui.deviceType === 'pc')?_c('div',{staticClass:"c-header__bottom"},[_c('VMenu',{attrs:{"menu":_vm.menu}})],1):_vm._e()]),_vm._v(" "),_c('MenuMobile',{attrs:{"is-open":_vm.isMenuMobileOpen,"menu":_vm.menu},on:{"close":_vm.close,"generalClose":_vm.generalClose}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }