<template>
  <SkeletonGrid
    grid-two
    :items-number="2"
  >
    <div class="c-skeleton-highlight thumbnail-skeleton-animation" />
  </SkeletonGrid>
</template>
<script>
import SkeletonGrid from '@/components/molecules/skeleton/SkeletonGrid.vue'
export default {
  components: {
    SkeletonGrid
  }
}
</script>
<style lang="scss" scoped>
.c-skeleton-highlight {
  width: 100%;
  padding-top: 44.8%;
}
</style>
