<template>
  <div class="c-skeleton-p-home">
    <SkeletonSlider />
    <SkeletonHighlight />
    <SkeletonMainHeading />
    <SkeletonGrid
      :items-number="8"
      grid-four
    >
      <SkeletonCategory />
    </SkeletonGrid>
    <SkeletonHighlight />
    <SkeletonMainHeading :sub="false" />
    <SkeletonGrid
      grid-six
    >
      <SkeletonCategory />
    </SkeletonGrid>
    <SkeletonButton class="u-margin-bottom--lg" />
    <SkeletonMainHeading />
    <SkeletonHighlightStyle />
    <SkeletonMainHeading :sub="false" />
    <SkeletonGrid
      :items-number="12"
      grid-six
    >
      <SkeletonCategory />
    </SkeletonGrid>
  </div>
</template>
<script>
import SkeletonButton from '@/components/molecules/skeleton/SkeletonButton'
import SkeletonCategory from '@/components/molecules/skeleton/SkeletonCategory'
import SkeletonGrid from '@/components/molecules/skeleton/SkeletonGrid'
import SkeletonHighlight from '@/components/molecules/skeleton/SkeletonHighlight.vue'
import SkeletonHighlightStyle from '@/components/molecules/skeleton/SkeletonHighlightStyle.vue'
import SkeletonMainHeading from '@/components/molecules/skeleton/SkeletonMainHeading'
import SkeletonSlider from '@/components/molecules/skeleton/SkeletonSlider.vue'
export default {
  components: {
    SkeletonButton,
    SkeletonCategory,
    SkeletonGrid,
    SkeletonHighlight,
    SkeletonHighlightStyle,
    SkeletonMainHeading,
    SkeletonSlider
  }
}
</script>
