import DataStructure from './DataStructure'

export default class ProductAlert extends DataStructure {
  constructor ({
    id = null,
    productId = null,
    productSizeId = null,
    customerId = null,
    deviceTokenSalesforce = null,
    email = null,
    alertType = null
  }) {
    super()
    this.id = this.isset(id) ? this.convertToNumber(id) : null
    this.productId = this.isset(productId) ? this.convertToNumber(productId) : null
    this.productSizeId = this.isset(productSizeId) ? this.convertToNumber(productSizeId) : null
    this.customerId = this.isset(customerId) ? this.convertToNumber(customerId) : null
    this.deviceTokenSalesforce = this.isset(deviceTokenSalesforce) ? this.convertToString(deviceTokenSalesforce) : null
    this.email = this.isset(email) ? this.convertToString(email) : null
    this.alertType = this.isset(alertType) ? this.convertToString(alertType) : null
  }
}
