import { api } from './instance'

export const getProduct = (options = {}) => {
  const { productId, headers, instance } = options
  return (instance || api).get(`/products/${productId}`, {
    headers
  })
    .then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getProductMetadata = (options = {}) => {
  const { productId, headers, instance } = options
  return (instance || api).get(`/products/${productId}/metadata`, {
    headers
  })
    .then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getProductScores = (options = {}) => {
  const { productId, headers, instance } = options
  return (instance || api).get(`products/${productId}/score`, {
    headers
  })
    .then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getScores = (options = {}) => {
  const { headers, instance } = options
  return (instance || api).get('products/scores', {
    headers
  })
    .then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const createProductAlert = (options = {}) => {
  const { productAlert, headers, instance } = options
  return (instance || api).post(
    '/product-alerts',
    { ...productAlert },
    { headers })
    .then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const deleteProductAlert = (options = {}) => {
  const { alertId, headers, instance } = options
  return (instance || api).delete(
    `/product-alerts/${alertId}`,
    {},
    { headers })
    .then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getProductAlertList = (options = {}) => {
  const { headers, instance } = options
  return (instance || api).get(
    '/product-alerts',
    { headers })
    .then(res => res.data)
    .catch((e) => {
      throw e
    })
}

// getProductInformations = getNotCacheableProductDetail dans api front
export const getProductInformations = (options = {}) => {
  const { productId, headers, instance } = options
  return (instance || api).get(`/products/${productId}/information`, {
    headers
  })
    .then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getProductAssociated = (options = {}) => {
  const { productId, headers, instance } = options
  return (instance || api).get(`/products/${productId}/associated`, {
    headers
  })
    .then(res => res.data)
    .catch((e) => {
      throw e
    })
}
